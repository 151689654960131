<template>
  <div
    class="snooze"
    :class="{ 'has-dropdown-open' : isOpen }"
  >
    <base-button
      theme
      class="snooze-button"
      data-testid="snooze-button"
      @click.prevent.stop="toggleOpen"
    >
      <inline-svg
        aria-label="Snooze"
        class="snooze-svg snooze-clock"
        src="clock.svg"
      />
    </base-button>

    <dropdown-menu
      class="snooze-menu"
      :isOpen="isOpen"
      :triggerButtonSelector="'.snooze-button'"
      @focus-out="onFocusOut"
      @keydown.esc.native="closeMenu"
    >
      <template slot="header">
        Snooze until...
      </template>

      <dropdown-menu-item
        class="snooze-menu-item"
        data-testid="snooze-menu-item"
        @click="snooze('later')"
      >
        <inline-svg
          class="snooze-menu-item-svg snooze-later"
          role="presentation"
          src="snooze-later.svg"
        />
          Later today
        <span class="snooze-menu-item-caption">
          in 2 hours
        </span>
      </dropdown-menu-item>

      <dropdown-menu-item
        data-testid="snooze-tomorrow"
        @click="snooze('tomorrow')"
      >
        <inline-svg
          class="snooze-menu-item-svg snooze-tomorrow"
          role="presentation"
          src="snooze-tomorrow.svg"
        />
          Tomorrow
        <span class="snooze-menu-item-caption">
          {{ captionTomorrow }}
        </span>
      </dropdown-menu-item>

      <dropdown-menu-item
        data-testid="snooze-next-week"
        @click="snooze('next-week')"
      >
        <inline-svg
          class="snooze-menu-item-svg snooze-next-week"
          role="presentation"
          src="snooze-next-week.svg"
        />
          Next week
        <span class="snooze-menu-item-caption">
          {{ captionNextWeek }}
        </span>
      </dropdown-menu-item>
    </dropdown-menu>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'

  import { BaseButton } from '@/components/base'
  import DropdownMenu from '@/components/dropdown-menu/DropdownMenu'
  import DropdownMenuItem from '@/components/dropdown-menu/dropdown-menu-item/DropdownMenuItem'

  import forceAnnounce from '@/mixins/forceAnnounce'

  import eventBus from '@/tools/event-bus'

  export default {
    components: {
      DropdownMenu,
      DropdownMenuItem,
      BaseButton,
    },

    mixins: [
      forceAnnounce,
    ],

    props: {
      theme: {
        type: [Array, String],
        required: false,
        default: () => ['clean', 'round'],
      },
    },

    mounted() {
      eventBus.$on('dropdownMenuOpened', this.onDropdownMenuOpened)
    },

    destroyed() {
      eventBus.$off('dropdownMenuOpened', this.onDropdownMenuOpened)
    },

    data() {
      return {
        isOpen: false,
      }
    },

    methods: {
      onDropdownMenuOpened(ctx) {
        if (ctx !== this && this.isOpen) {
          this.isOpen = false
        }
      },
      toggleOpen() {
        this.isOpen = !this.isOpen

        if (this.isOpen) {
          eventBus.$emit('dropdownMenuOpened', this)
        }
      },
      closeMenu() {
        this.isOpen = false
      },
      snooze(until) {
        this.forceAnnounce(this.$t('Saved'))
        this.$emit('snooze', until)
        this.isOpen = false
      },
      onFocusOut() {
        this.closeMenu()
      },
    },

    computed: {
      ...mapGetters('user', [
        'getTimezoneOffset',
      ]),
      captionTomorrow() {
        return moment()
          .add(1, 'day')
          .utc()
          .hours(8)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .utcOffset(this.getTimezoneOffset)
          .format('ddd, hh:mm')
      },
      captionNextWeek() {
        return moment()
          .add(1, 'week')
          .startOf('isoWeek')
          .add(1, 'day')
          .utc()
          .hours(8)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .utcOffset(this.getTimezoneOffset)
          .format('ddd, hh:mm')
      },
    },
  }
</script>

<style lang="scss">
  .snooze {
    display: inline-block;
    position: relative;
    &-button {
      width: 2.2rem;
      height: 2.2rem;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.has-dropdown-open {
      .button {
        // @toto @aleksandra change this to variable color
        background: rgba(0, 131, 143, 0.2);
      }
    }
    &-menu {
      top: 2.4rem;
      right: 0;
      min-width: 23rem;
      z-index: z-index(dialog);
      &-item {
        display: flex;
        padding: 0;
        &-svg {
          display: inline-block;
          width: 3.2rem;
          text-align: left;
          svg {
            width: initial;
          }
          &.snooze-tomorrow,
          &.snooze-later {
            svg { margin-left: -.2rem; }
          }
          &.snooze-next-week {
            svg { margin-top: -.4rem; }
          }
        }
        &-caption {
          margin-left: auto;
          opacity: 0.4;
        }
      }
    }
    .dropdown {
      &-menu {
        &-header {
          padding: 1.8rem 2rem;

        }
        &-items {
          padding: 1.4rem 0;
        }
        &-item {
          &-container {
            padding: 0 2rem;
            height: 3.6rem;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
</style>
