<template>
  <base-main
    class="glucose-tracking-settings"
    data-testid="glucose-tracking-settings"
    :loading="$options.loading"
  >

      <container
        first
      >
        <stepper-custom-form
          ref="stepper"
          :buttonLabels="{ finishLabel: 'Finish setup'}"
          :isLoading="isSaving"
          @cancel="onCancel"
          @submit="onSubmit"
        >
          <template #default>
            <stepper-custom-step
              data-testid="glucose-tracking-settings-stepper-custom-step"
              :title="i18n('hba1c-title')"
            >
              <div class="glucose-tracking-settings__step">
                <p class="glucose-tracking-settings__label">
                  {{ i18n('hba1c-helper') }}
                </p>
                <form-fields-generator
                  :dataModel="form"
                  :formFields="hba1cFields"
                />
              </div>
            </stepper-custom-step>
            <stepper-custom-step
              data-testid="glucose-tracking-settings-stepper-custom-step"
              :title="i18n('glucose-title')"
            >
              <div class="glucose-tracking-settings__step">
                <p class="glucose-tracking-settings__label">
                  {{ i18n('glucose-helper') }}
                </p>
                <form-fields-generator
                  :dataModel="form"
                  :formFields="glucoseFields"
                />
              </div>
            </stepper-custom-step>
          </template>
        </stepper-custom-form>
      </container>

  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import FormFieldsGenerator from '@/components/forms/form-fields-generator/FormFieldsGenerator'
  import {
    StepperCustomForm,
    StepperCustomStep,
  } from '@/components/stepper-custom'

  import i18n from '@/mixins/i18n'
  import { trackingSettingsBase } from '@/mixins/tracking-settings'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.glucose-tracking.glucose-tracking-settings.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      FormFieldsGenerator,
      StepperCustomForm,
      StepperCustomStep,
    },

    mixins: [
      i18n,
      trackingSettingsBase,
    ],

    data() {
      return {
        form: {
          hba1c_units: null,
          glucose_units: null,
        },
      }
    },

    methods: {
      ...mapActions('glucoseTracking', [
        'saveSettings',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        this.saveSettings(this.form)
          .then(() => this.$router.push({ name: this.$static.mainComponent }))
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('glucoseTracking', [
        'settings',
      ]),
      isLoading() {
        return this.getLoadingStatesForActions([
          'glucoseTracking/fetchSettings',
        ])
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'glucoseTracking/saveSettings',
        ])
      },
      hba1cFields() {
        return [{
          type: 'RadioMultiGroup',
          name: 'hba1c_units',
          class: 'glucose-tracking-settings__radio',
          props: {
            legend: this.i18n('hba1c-label'),
            labelKey: 'label',
            options: [
              { value: 1, label: `<b>%</b> ${this.$t('Example')}: 7.5%` },
              { value: 2, label: `<b>mmol/mol</b> ${this.$t('Example')}: 50mmol/mol` },
            ],
            validation: { required: true },
            valueKey: 'value',
          },
        }]
      },
      glucoseFields() {
        return [{
          type: 'RadioMultiGroup',
          name: 'glucose_units',
          class: 'glucose-tracking-settings__radio',
          props: {
            legend: this.i18n('glucose-label'),
            labelKey: 'label',
            options: [
              { value: 1, label: `<b>mg/dl</b> ${this.$t('Example')}: 106mg/dl` },
              { value: 2, label: `<b>mmol/l</b> ${this.$t('Example')}: 6.0mmol/l` },
            ],
            validation: { required: true },
            valueKey: 'value',
          },
        }]
      },
    },

    static() {
      return {
        mainComponent: 'GlucoseTracking',
        module: 'glucoseTracking',
      }
    },

    loading: [
      'glucoseTracking/fetchSettings',
    ],

    slug: 'views.plugins.glucose-tracking.glucose-tracking-settings',
  }
</script>

<style lang="scss">
  .glucose-tracking-settings {
    @include max-sm {
      padding-right: 0;
      padding-left: 0;
    }
    &__radio {
      b {
        display: inline-block;
        font-weight: 700;
        padding: 0 0.4rem 0 0;
      }
    }
    &__label {
      margin: 0 0 3rem;
      font-weight: 400;
      line-height: 1.6;
    }
    .container {
      max-width: 46.5rem;
    }
    .stepper-custom-step__title {
      font-size: 1.8rem;
    }
  }
</style>
