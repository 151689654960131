export default {
  titles: {
    true: 'data.gcq.getting-confident-results.titles-true',
    false: 'data.gcq.getting-confident-results.titles-false',
  },
  answerSituations: {
    1: 'data.gcq.getting-confident-results.answer-situations-1',
    2: 'data.gcq.getting-confident-results.answer-situations-2',
    3: 'data.gcq.getting-confident-results.answer-situations-3',
    4: 'data.gcq.getting-confident-results.answer-situations-4',
    5: 'data.gcq.getting-confident-results.answer-situations-5',
  },
}
