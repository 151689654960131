<template>
  <div
    class="chip-checkbox-group"
    tabindex="0"
    :aria-checked="isChecked.toString()"
    @keydown.prevent.enter.space="toggleState">
      <label class="chip-checkbox-group-label">
        <chip
          :isSelected="currState"
          v-bind="{ hasBorder, isDark }">
            <input
              class="chip-checkbox-group-input"
              data-testid="chip-checkbox-group-input"
              type="checkbox"
              :name="name"
              v-model="currState"
              v-validate="validation"
              @change="onChange">
            <slot/>
            {{ label }}
        </chip>
      </label>
  </div>
</template>

<script>
  import Chip from '@/components/chips/chip/Chip'

  export default {
    components: {
      Chip,
    },

    props: {
      hasBorder: {
        type: Boolean,
        required: false,
        default: false,
      },
      isDark: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: [Array, Boolean, Number, String],
        required: false,
        default: () => [],
      },
      val: {
        type: [Boolean, String],
        required: true,
        default: false,
      },
    },

    inject: {
      $validator: '$validator',
    },

    created() {
      this.currState = this.isChecked
    },

    data() {
      return {
        currState: this.value,
      }
    },

    methods: {
      onChange() {
        if (Array.isArray(this.value)) {
          const state = [].concat(this.value)

          state.includes(this.val)
              ? state.splice(state.indexOf(this.val), 1)
              : state.push(this.val)

          this.$emit('input', state)
        } else {
          this.$emit('input', this.currState)
        }
      },
      toggleState() {
        this.state = !this.state
      },
    },

    computed: {
      isChecked() {
        return Array.isArray(this.value)
                ? this.value.includes(this.val)
                : this.currState
      },
    },
  }
</script>

<style lang="scss">
  .chip-checkbox-group {
    &-input {
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      overflow: hidden;
      z-index: z-index(under-base);
    }
  }
</style>
