var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-main',{staticClass:"reading-room",attrs:{"background":"","loading":_vm.$options.loading}},[_c('div',{staticClass:"reading-room__wrapper"},[_c('reading-room-single-category',{ref:"header",attrs:{"description":_vm.details.description,"title":_vm.details.title,"headerLevel":1}}),_c('div',{staticClass:"reading-simple-tile"},_vm._l((_vm.topicsArray),function(topic){return _c('router-link',{key:topic.display_name,staticClass:"reading-simple-tile-router",attrs:{"to":{
          name: 'ReadingRoomTopic',
          params: {
            topicId: topic.id,
          },
        },"data-testid":"reading-simple-tile-router-link"}},[_c('simple-tile',{staticClass:"reading-simple-tile-topic",attrs:{"data-testid":"reading-single-topic"}},[_c('div',{staticClass:"reading-simple-tile-topic-content"},[_c('h2',{staticClass:"reading-simple-tile-topic-content__name"},[_vm._v(" "+_vm._s(topic.display_name)+" ")]),_c('img',{staticClass:"reading-simple-tile-topic-content__img",attrs:{"alt":"","src":topic.hero
                  ? topic.hero.url
                  : _vm.image}})])])],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }