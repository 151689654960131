<template>
  <base-main
    data-testid="eq-5-d-health"
  >
    <container
      first
      flex-column
      plugin-article
    >
      <plugin-article>
        <template slot="title">
          {{ $t('views.plugins.eq-5d.eq-5d-health.title') }}
        </template>

        <template slot="content">
          <base-form
            ref="form"
            :hasSubmitButton="false"
            v-bind="{ isSaving }"
            @submit="onSubmit">
              <div
                data-testid="eq-5-d-health-description"
              >
                <p>
                  {{ $t('views.plugins.eq-5d.eq-5d-health.paragraph') }}
                </p>
                <p>
                  <span v-html="$t($options.statics.bestHealth)"/>
                  <span v-html="$t($options.statics.worstHealth)"/>
                </p>
                <p v-html="$t($options.statics.dragSlider)" />
              </div>

              <range-group
                ref="range"
                :contextLabels="[
                  'views.plugins.eq-5d.eq-5d-health.worst-health',
                  'views.plugins.eq-5d.eq-5d-health.best-health'
                ]"
                :max="100"
                :min="0"
                :name="'eq-vas'"
                v-bind="{ interval }"
                v-model="form.value"/>

              <div
                class="eq-health-value"
                data-testid="eq-5-d-health-current-value"
              >
                {{ form.value }}
              </div>
          </base-form>
        </template>
      </plugin-article>

      <base-button
        align-end
        data-testid="eq-5-d-health-submit"
        @click="onSubmit"
      >
        {{ $t('Next') }}
      </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseForm from '@/components/base/BaseForm'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import RangeGroup from '@/components/forms/range-group/RangeGroup'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.eq-5d.eq-5d-health.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseForm,
      BaseMain,
      Container,
      PluginArticle,
      RangeGroup,
    },

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    mounted() {
      this.refreshRange()
    },

    beforeDestroy() {
      clearTimeout(this.$options.timeout)
    },

    data() {
      return {
        form: {
          value: 50,
        },
      }
    },

    methods: {
      ...mapActions('eq5d', [
        'saveAnswer',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        const { form, token } = this

        this.saveAnswer({ form, token })
          .then(() => this.$router.replace({ name: 'Eq5dThankYou', params: { token } }))
          .catch(() => this.$router.replace({ name: 'Eq5d' }))
      },
      refreshRange() {
        this.$options.timeout = setTimeout(this.$refs.range.refresh, 300)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('layout', [
        'wW',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'eq5d/saveAnswer',
        ])
      },
      interval() {
        return this.wW < 768 ? 25 : 5
      },
    },

    statics: {
      bestHealth: 'views.plugins.eq-5d.eq-5d-health.best-health-imagine',
      worstHealth: 'views.plugins.eq-5d.eq-5d-health.worst-health-imagine',
      dragSlider: 'views.plugins.eq-5d.eq-5d-health.drag-slider',
    },

    beforeRouteEnter(to, from, next) {
      to.params.token
        ? next()
        : next({ name: 'Eq5d', replace: true })
    },
  }
</script>

<style lang="scss">
  .eq-health {
    &-value {
      padding: 1rem 2rem;
      margin: 0 auto;
      font-size: 2.5rem;
      font-weight: 600;
      color: color(dark-primary);
      background-color: color(_white);
    }
    .sliding-scale__context-label {
      @include max-sm {
        display: none;
      }
    }
  }
</style>
