<template>
  <plugin-article
    has-background
    :img="'weekly-review/activity.png'">
      <loading-indicator
        v-if="isLoading"/>

      <template
        v-else>
          <template slot="title">
            {{ $t(`${$options.slug}.title`) }}
          </template>
          <template slot="content">
            <p>
              {{ $t(`${$options.slug}.content-1`) }}
            </p>
            <p>
              {{ $t(`${$options.slug}.content-2`) }}
            </p>
            <p v-if="dialogPlans.length">
              <ul>
                <li
                  v-for="(dialogLink, i) in dialogPlans"
                  :key="i">
                    <weekly-review-dialog-link
                      v-bind="{ dialogLink }"/>
                </li>
              </ul>
            </p>

            <base-form
              ref="form"
              :hasSubmitButton="false"
              @submit="onSubmit">
                <select-group
                  :label="`${$options.slug}.label`"
                  :name="'active_plan'"
                  :options="availablePlans"
                  :validation="$options.validation.active_plan"
                  v-model="activePlan"
                >
                  <template #singleLabel="{ option }">
                    {{ option.value.label }}
                  </template>
                  <template #option="{ option }">
                    {{ option.value.label }}
                  </template>
                </select-group>
            </base-form>

          </template>
      </template>
  </plugin-article>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import BaseForm from '@/components/base/BaseForm'
  import SelectGroup from '@/components/forms/select-group/SelectGroup'
  import LoadingIndicator from '@/components/loading-indicator'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import WeeklyReviewDialogLink from '@/components/weekly-review/WeeklyReviewDialogLink'

  import { links } from '@/data/weekly-review/weeklyReviewDialogs'

  import weeklyReviewPlans from '@/mixins/weekly-review/weeklyReviewPlans'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review-activity-plan.meta.title'),
      }
    },

    components: {
      BaseForm,
      LoadingIndicator,
      PluginArticle,
      SelectGroup,
      WeeklyReviewDialogLink,
    },

    mixins: [
      weeklyReviewPlans,
    ],

    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    created() {
      this.fetchWeeklyReviewData()
        .then(() => { this.form.active_plan = this.plans.active_plan })
    },

    data() {
      return {
        form: {
          active_plan: null,
          step_type: 'activity-plan',
        },
      }
    },

    computed: {
      ...mapGetters('optionalProfileData', [
        'gender',
      ]),
      ...mapState('weeklyReview', {
        plans: 'activityPlans',
      }),
      dialogPlans() {
        return links.activity[this.gender] || []
      },
    },

    slug: 'views.plugins.weekly-review.weekly-review-activity-plan',
  }
</script>
