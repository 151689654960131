<template>
  <div
    class="reading-room-filters"
    role="group"
    :aria-label="$t('Reading room filter')"
  >

    <checkbox-multi-group
      class="reading-room-filters__filter"
      :name="'reading-room-filters'"
      :legend="''"
      :options="filters"
      v-model="filter"
    >
      <template #option="{ option }">
        <reading-room-icon
          class="reading-room-filters__icon"
          :type="option"
        />
        Show {{ $t(option) | capitalize }} articles
      </template>
    </checkbox-multi-group>
  </div>
</template>

<script>
  import { CheckboxMultiGroup } from '@/components/forms/checkbox-group'
  import ReadingRoomIcon from '@/components/reading-room/ReadingRoomIcon'
  import capitalize from '@/filters/capitalize'

  export default {
    filters: {
      capitalize,
    },

    components: {
      CheckboxMultiGroup,
      ReadingRoomIcon,
    },

    watch: {
      filter() {
        this.$emit('filterChange', {
          activeFilters: this.filter,
          changedFilter: true,
        })
      },
    },

    data() {
      return {
        filter: ['new', 'read', 'favourite'],
        filters: ['new', 'read', 'favourite'],
      }
    },
  }
</script>

<style lang="scss">
  $block: 'reading-room-filters';

  .#{$block} {
    display: flex;
    width: fit-content;
    height: 3rem;
    font-size: 1.2rem;
    height: 100%;
    @include min-sm {
      font-size: 1.4rem;
    }
    &__filter {
      display: flex;
      flex-direction: column;
      height: 100%;
      .base-checkbox {
        margin-right: 1rem;
      }
      .base-checkbox__container {
        display: flex;
        margin: 0;
      }
    }
    &__icon {
      width: 2rem !important;
      height: 2rem !important;
      margin-right: 0.5rem;
      svg {
        width: 1.3rem !important;
        height: 1.3rem !important;
      }
    }
  }
</style>
