<template>
  <div class="button-skip-to-main-content">
    <base-button
      light
      data-testid="button-skip-to-main-content"
      href="#maincontent"
      target="_self"
      :aria-label="$t('Skip to main content')"
      @click="skipToMainContent"
    >
      {{ $t('Skip to main content') }}
    </base-button>
  </div>
</template>

<script>
  import { BaseButton } from '@/components/base'

  export default {
    components: {
      BaseButton,
    },

    methods: {
      skipToMainContent() {
        document.querySelector('#main').focus()
      },
    },
  }
</script>

<style lang="scss">
  .button-skip-to-main-content {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    z-index: z-index(under-base);
    &:focus-within {
      z-index: z-index(over-all);
    }
  }
</style>
