export default [
  {
    id: 1,
    question: 'data.gcq.getting-confident-quiz-questions.question-1',
  },
  {
    id: 2,
    question: 'data.gcq.getting-confident-quiz-questions.question-2',
  },
  {
    id: 3,
    question: 'data.gcq.getting-confident-quiz-questions.question-3',
  },
  {
    id: 4,
    question: 'data.gcq.getting-confident-quiz-questions.question-4',
  },
  {
    id: 5,
    question: 'data.gcq.getting-confident-quiz-questions.question-5',
  },
]
