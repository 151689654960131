<template>
  <base-main
    data-testid="maintenance-plan"
    :loading="$options.loading"
  >
    <container
      v-if="!answers"
      first
      flex-column
      plugin-article
    >
      <plugin-article
        :img="'quiz/maintenance-plan.png'"
        :testid="'maintenance-plan'"
      >
        <template slot="title">
          {{ $t('Maintenance plan') }}
        </template>

        <template slot="content">
          <p>
            {{ $t('views.plugins.maintenance-plan.maintenance-plan.content-1') }}
          </p>
          <br>
          <p>
            {{ $t('views.plugins.maintenance-plan.maintenance-plan.content-2') }}
          </p>
        </template>
      </plugin-article>

      <base-button
        align-end
        data-testid="maintenance-plan-next"
        :link="{ name: 'MaintenancePlanQuiz' }"
      >
        {{ $t('Next') }}
      </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import shouldFetch from '@/mixins/shouldFetch'

  const pluginName = 'maintenancePlan'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.maintenance-plan.maintenance-plan.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      shouldFetch,
    ],

    created() {
      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(() => {
          if (!this.answers) return

          this.$router.replace({ name: 'MaintenancePlanResults' })
        })
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],
  }
</script>
