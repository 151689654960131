<template>
  <router-link
    class="reading-room-tile"
    :to="{
      name: 'ReadingRoomArticle',
      params: {
        categoryId: article.optional_content_id,
        id: article.id,
      },
    }"
    :data-testid="`${dataTestid}-link`"
  >
    <modular-tile
      :data-testid="dataTestid"
      :headerLevel="3"
      :headerText="article.title"
      :imagePosition="'top'"
      v-bind="{ image }"
    />
    <reading-room-icon
      v-if="showIcon"
      class="reading-room-tile__icon"
      :data-testid="`${dataTestid}-icon`"
      v-bind="{ type }"
    />
  </router-link>
</template>

<script>
  import ReadingRoomIcon from '@/components/reading-room/ReadingRoomIcon'
  import ModularTile from '@/components/tiles/modular-tile'

  import requireImage from '@/mixins/requireImage'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      ModularTile,
      ReadingRoomIcon,
    },

    mixins: [
      requireImage,
      testid('tile'),
    ],

    props: {
      article: {
        type: Object,
        required: true,
      },
      showIcon: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      image() {
        const { icon, icons_article: iconsArticle, type } = this.article

        if (type == 'article' && iconsArticle) {
          const subarticle = iconsArticle.subarticles.find(item => item.language == 'en_GB')
          return subarticle?.icon?.url || this.requireImage('birb.jpg')
        }

        return icon?.url || this.requireImage('birb.jpg')
      },
      type() {
        if (!this.article.content_user) return 'new'

        return this.article.content_user.favorite ? 'favourite' : 'read'
      },
    },
  }
</script>

<style lang="scss">
  .reading-room-tile {
    padding: 0;
    position: relative;
    text-align: left;
    .modular-tile {
      &__top-image-row {
        height: 10rem;
      }
      &__header-row {
        min-height: initial;
        margin-top: 2.2rem;
        word-break: break-word;
      }
    }
    &__icon {
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
    }
  }
</style>
