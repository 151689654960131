<template>
  <div
    ref="notifications"
    class="notifications"
    :class="{ 'notifications--is-open': areNotificationsOpen }"
  >
    <focus-lock :disabled="!areNotificationsOpen">
      <notification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </focus-lock>
  </div>
</template>

<script>
  import FocusLock from 'vue-focus-lock'
  import { mapActions, mapGetters } from 'vuex'

  import Notification from '@/components/notification/Notification'

  export default {
    components: {
      FocusLock,
      Notification,
    },

    mounted() {
      this.initObserver()

      if (this.notifications?.length) this.moveFocus()
    },

    beforeDestroy() {
      if (this.observer) this.observer.disconnect()
    },

    watch: {
      areNotificationsOpen: {
        handler(value) {
          this.set({
            value,
            state: 'isGlobalAriaHidden',
          })
        },
        immediate: true,
      },
    },

    data() {
      return {
        observer: null,
      }
    },

    methods: {
      ...mapActions('layout', [
        'set',
      ]),
      moveFocus() {
        this.$refs.notifications.querySelector('.snooze-button').focus()
      },
      initObserver() {
        this.observer = new MutationObserver(this.moveFocus)

        this.observer.observe(this.$refs.notifications, {
          childList: true,
          subtree: true,
        })
      },
    },

    computed: {
      ...mapGetters('notifications', [
        'getNotificationsByProgramId',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      notifications() {
        return this.getNotificationsByProgramId(this.currentProgramId, 'popup')
      },
      notificationsLimited() {
        return this.notifications.slice(0, 6)
      },
      areNotificationsOpen() {
        return !!this.notifications.length
      },
    },
  }
</script>

<style lang="scss">
  .notifications {
    display: none;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: z-index(dialog);
    opacity: 0;
    visibility: hidden;
    background: rgba(black, 0.4);
    &--is-open {
      display: flex;
      opacity: 1;
      visibility: visible;
      animation: appear 300ms forwards ease-out;
    }

    & > div {
      width: 100%;
    }
  }
</style>
