import { mapGetters } from 'vuex'

import eventBus from '@/tools/event-bus'

import weeklyReviewSubmit from './weeklyReviewSubmit'

export default {
  mixins: [weeklyReviewSubmit],

  mounted() {
    eventBus.$on('weeklyReview/onNext', this.onSubmit)
  },

  beforeDestroy() {
    eventBus.$off('weeklyReview/onNext')
  },

  computed: {
    ...mapGetters('loading', [
      'getLoadingStatesForActions',
    ]),
    availablePlans() {
      // eslint-disable-next-line camelcase
      return Object.entries(this.plans?.available_plans || {})
         .map(([value, label]) => ({ value, label }))
    },
    activePlan: {
      get() {
        return this.availablePlans.find(el => el.value == this.form.active_plan)
      },
      set({ value }) {
        this.form.active_plan = value
      },
    },
  },

  validation: {
    active_plan: {
      required: true,
    },
  },
}
