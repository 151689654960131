<template>
  <transition
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
    :css="false">
      <slot/>
  </transition>
</template>

<script>
  import { gsap } from 'gsap'

  import { SIBLINGS_LAYOUTS } from '@/constants'

  export default {
    props: {
      component: {
        type: String,
        required: true,
      },
    },

    watch: {
      $route(to, from) {
        this.saveRoutes(to, from)
      },
    },

    data() {
      return {
        from: '',
        to: '',
        back: {
          from: '100%',
          to: '-100%',
        },
        next: {
          from: '-100%',
          to: '100%',
        },
      }
    },

    methods: {
      beforeEnter(el) {
        if (!this.canAnimate) return

        gsap.set(el, { x: this[this.direction].to })
      },
      enter(el, done) {
        if (this.canAnimate) {
          gsap.to(el, 0.3, { x: '0%', clearProps: 'all', onComplete: this.setFabPosition })
        }

        done()
      },
      leave(el, done) {
        if (!this.canAnimate) {
          done()
          return
        }

        gsap.to(el, 0.3, { x: this[this.direction].from, onComplete: () => { done() } })
      },
      setFabPosition() {
        const fab = document.querySelector('.fab--main-tile')
        if (!fab) return

        const placeholder = document.querySelector('.main-tile-fab').getBoundingClientRect()
        gsap.set(fab, { x: placeholder.left, y: placeholder.top })
      },
      saveRoutes(to, from) {
        Object.entries({ to, from })
          .forEach(([key, value]) => {
            const { name, meta } = value

            this[key] = { layout: meta.layout, name }
          })
      },
    },

    computed: {
      canAnimate() {
        return [this.from, this.to].every(route => (
          Boolean(route.name) && SIBLINGS_LAYOUTS.includes(route.layout)
        ))
      },
      direction() {
        return this.$options.directions[this.component](this.from.name, this.to.name)
      },
    },

    directions: {
      home: (from, to) => (from == 'Home' || (from == 'Learn' && to == 'Tools')
                            ? 'next'
                            : 'back'
      ),
      'coaching-plugin': from => (from == 'CoachingPlugin' ? 'next' : 'back'),
      coaching: from => (from == 'CoachingPlugin' ? 'next' : 'back'),
      'face-age-test': from => (from == 'FaceAgeTest' ? 'next' : 'back'),
    },
  }
</script>
