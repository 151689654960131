const trackingSettingsBase = {
  data() {
    return {
      form: {},
    }
  },

  created() {
    this.fetchSettings()
      .then(() => {
        if (!this.settings) {
          this.$route.meta.parent = 'Tools'
          return
        }

        this.$route.meta.parent = this.$static.mainComponent
        this.fillForm()
      })
  },

  methods: {
    fetchSettings() {
      if (!this.$static.module) return Promise.reject()

      return this.$store.dispatch(`${this.$static.module}/fetchSettings`)
    },
    fillForm() {
      if (!this.settings) return

      Object.keys(this.form).forEach(key => {
        this.form[key] = this.settings[key]
      })
    },
    onCancel() {
      this.$router.push({ name: this.$route.meta.parent })
    },
  },

  static() {
    return {
      mainComponent: '',
      module: '',
    }
  },
}

export default trackingSettingsBase
