<script>
  import BaseButton from '@/components/base/BaseButton'

  export default {
    functional: true,

    render(h, { data, parent }) {
      return h(BaseButton, {
        ...data,
        attrs: {
          'data-testid': `button-add-another ${data.attrs['data-testid']}`,
        },
        staticClass: 'button-add-another',
        props: {
          theme: ['clean', 'align-start'],
        },
      }, [
        h('span', { staticClass: 'button-add-another-icon' }, [
          h('inline-svg', {
            props: {
              src: 'plus.svg',
            },
          }),
        ]),
        parent.$t('Add another'),
      ])
    },
  }
</script>

<style lang="scss">
  .button-add-another {
    margin: 2rem 0;
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      margin-right: 1.1rem;
      background-color: color(button-add-another);
      border-radius: 50%;
      span {
        display: flex;
      }
      svg {
        height: 1rem;
        width: 1rem;
        fill: none;
        stroke: color(_white);
        stroke-miterlimit: 10;
        stroke-width: 4px;
      }
    }
  }
</style>
