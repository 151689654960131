<template>
  <div
    class="card-ordered"
    :data-testid="getTestId()"
    v-merge-attr:data-testid
  >
    <div
      class="card-ordered-index"
      :data-testid="getTestId('index')"
    >
      {{ index + 1 }}
    </div>

    <div
      class="card-ordered-content"
      :data-testid="getTestId('content')"
    >
      {{ content }}
    </div>

    <button
      class="card-ordered-remove"
      :data-testid="getTestId('remove-button')"
      @click="remove"
    >
      <inline-svg
        :aria-label="`Remove answer ${index + 1}`"
        src="close.svg"
      />
    </button>
  </div>
</template>

<script>
  import mergeAttr from '@/directives/mergeAttr'

  export default {
    directives: {
      mergeAttr,
    },

    props: {
      content: {
        type: String,
        required: false,
        default: '',
      },
      index: {
        type: Number,
        required: false,
        default: 0,
      },
    },

    methods: {
      remove() {
        this.$emit('remove')
      },
      getTestId(slug) {
        return ['card-ordered', slug]
          .filter(Boolean)
          .join('-')
      },
    },
  }
</script>

<style lang="scss">
  .card-ordered {
    display: flex;
    align-items: center;
    background-color: color(_white);
    border-radius: 2px;
    box-shadow: box-shadow(default);
    padding: 1.25rem 1.5rem;
    margin-top: 1rem;
    &-index {
      display: flex;
      flex: none;
      justify-content: center;
      align-items: center;
      height: 3rem;
      width: 3rem;
      margin-right: 1.1rem;
      color: color(_white);
      background-color: color(primary);
      border-radius: 50%;
    }
    &-content {
      width: 100%;
    }
    &-remove {
      flex-shrink: 0;
      align-self: flex-start;
      padding: 0;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      svg {
        height: 100%;
      }
      path {
        stroke: color(grey);
      }
    }
  }
</style>
