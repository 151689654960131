<template>
  <simple-tile
    class="reading-single-category"
    direction="column"
    :data-testid="dataTestid"
  >
      <div class="reading-single-category__header-wrapper">
        <div class="reading-single-category__header">
            <Heading
              v-if="title"
              class="reading-single-category__title"
              :data-testid="`${dataTestid}-title`"
              :level="headerLevel"
            >
              {{ $t(title) }}
            </Heading>
          <p
            v-if="description"
            :data-testid="`${dataTestid}-description`"
          >
            {{ $t(description) }}
          </p>
        </div>
      </div>
      <base-button
        v-if="showButton"
        class="reading-single-category__see-all"
        :link="{ name: 'ReadingRoomCategory', params: { categoryId } }"
        :data-testid="`${dataTestid}-see-all`"
      >
        {{ buttonText }}
        <screen-readers-only>
          {{ $t(`${this.$options.slug}.related-category`, { categoryName: title }) }}
        </screen-readers-only>
      </base-button>
      <div
        v-if="hasFiltersSlot"
        class="reading-single-category__filters"
      >
        <slot />
      </div>

      <transition-group
        v-if="hasArticles"
        class="reading-single-category__articles"
        name="articles-list"
        tag="div"
      >
        <reading-room-tile
          v-for="(article, i) in articlesToDisplay"
          :key="article.id"
          class="articles-list-item"
          :style="`--reading-room-tile-row-start: ${getItemRowStart(i)}`"
          :article="article"
          :showIcon="showIcon"
        />
      </transition-group>
  </simple-tile>
</template>

<script>
  import BaseButton from '@/components/base/BaseButton'
  import { Heading } from '@/components/heading'
  import ReadingRoomTile from '@/components/reading-room/ReadingRoomTile'
  import ScreenReadersOnly from '@/components/screen-readers-only/ScreenReadersOnly'
  import SimpleTile from '@/components/tiles/simple-tile'

  import requireImage from '@/mixins/requireImage'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseButton,
      ReadingRoomTile,
      ScreenReadersOnly,
      SimpleTile,
      Heading,
    },

    mixins: [
      requireImage,
      testid('single-category'),
    ],

    props: {
      articles: {
        type: Array,
        default: null,
      },
      allItemsCount: {
        type: Number,
        default: null,
      },
      categoryId: {
        type: Number,
        default: null,
      },
      description: {
        type: String,
        default: '',
      },
      limitTiles: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      headerLevel: {
        type: Number,
        default: 2,
      },
    },

    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },

    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },

    data() {
      return {
        articlesLimit: 3,
        screenWidth: 0,
      }
    },

    methods: {
      handleResize() {
        this.screenWidth = window.innerWidth
      },
      getItemRowStart(index) {
        return parseInt(index / this.itemsPerRow, 10) + 1
      },
    },

    computed: {
      hasArticles() {
        return this.articles?.length > 0
      },
      showButton() {
        return this.limitTiles
      },
      buttonText() {
        const length = this.allItemsCount

        return this.$t(`${this.$options.slug}.see-all`, { length })
          + this.$tc('article', length)
      },
      showIcon() {
        return !this.limitTiles
      },
      hasFiltersSlot() {
        return !!this.$slots.default
      },
      articlesToDisplay() {
        if (this.limitTiles) {
          return this.articles.slice(0, this.articlesLimit)
        } else {
          return this.articles
        }
      },
      itemsPerRow() {
        if (this.screenWidth >= 1024) return 3
        else if (this.screenWidth >= 600) return 2
        else return 1
      },
    },

    slug: 'component.reading-room.reading-room-single-category',
  }
</script>

<style lang="scss">
  @mixin nth-child-column-start($nth, $column-start) {
    &:nth-child(#{$nth}) {
      grid-column-start: $column-start;
    }
  }

  .reading-single-category {
    &__header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include min-lg {
        flex-direction: row;
      }
    }
    &__header {
      margin-right: 2rem;
      word-break: break-word;
    }
    &__title {
      margin: 0 0 1rem;
    }
    &__see-all {
      margin-top: 1.5rem;
      margin-left: 0;
      @include min-lg {
        margin-left: auto;
      }
    }
    &__filters {
      margin: 2rem auto 0 0;
      @include min-lg {
        margin-top: initial;
      }
    }
    &__articles {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.6rem 1.8rem;
      align-items: start;
      margin: 2.4rem 0 1rem;
      position: relative;
      @include min-sm {
        grid-template-columns: repeat(2, 1fr);
      }
      @include min-md {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3.2rem 3.5rem;
      }
    }
    & > .simple-tile__main {
      padding: 1.6rem 1.8rem;
      @include min-lg {
        padding: 3.2rem 3.6rem;
      }
    }
    .simple-tile__content {
      margin-bottom: 0;
    }
    .articles-list {
      &-item {
        z-index: z-index(base);
        opacity: 1;
      }
      &-enter,
      &-leave-to {
        opacity: 0;
      }
      &-leave-active {
        grid-column-end: span 1;
        grid-row-start: var(--reading-room-tile-row-start);
        position: absolute;
        z-index: z-index(default);
        @include min-sm {
          @include nth-child-column-start('2n+1', 1);
          @include nth-child-column-start('2n', 2);
        }
        @include min-md {
          @include nth-child-column-start('3n+1', 1);
          @include nth-child-column-start('3n+2', 2);
          @include nth-child-column-start('3n', 3);
        }
      }
      &-move {
        transition: transform 0.4s;
      }
      &-enter-active,
      &-leave-active {
        transition: opacity 0.4s;
      }
    }
  }
</style>
