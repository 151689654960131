<template>
  <button
    class="weekly-review-dialog-link"
    data-testid="weekly-review-dialog-link"
    type="button"
    @click="openWeeklyReviewDialog"
    v-merge-attr:data-testid
  >
    {{ $t(dialogLink.text) }}
  </button>
</template>

<script>
  import { mapActions } from 'vuex'

  import mergeAttr from '@/directives/mergeAttr'

  export default {
    directives: {
      mergeAttr,
    },

    props: {
      dialogLink: {
        type: Object,
        required: true,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'openDialog',
      ]),
      openWeeklyReviewDialog() {
        const { dialogId } = this.dialogLink

        this.openDialog({ component: 'WeeklyReview', props: { dialogId } })
      },
    },
  }
</script>

<style lang="scss">
  .weekly-review-dialog-link {
    padding: 0;
    color: color(dark-primary);
    text-decoration: underline;
    cursor: pointer;
  }
</style>
