<template functional>
  <span
    class="chip"
    :class="{
      'chip--has-border': props.hasBorder,
      'chip--is-dark': props.isDark,
      'chip--is-selected': props.isSelected,
    }"
  >
      <slot/>
  </span>
</template>

<script>
  export default {
    props: {
      hasBorder: {
        type: Boolean,
        required: false,
        default: false,
      },
      isDark: {
        type: Boolean,
        required: false,
        default: false,
      },
      isSelected: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
  .chip {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem 1.2rem;
    color: color(secondary-text); // was grey
    line-height: 1;
    background-color: color(_white);
    border-radius: 1.6rem;
    transition: all 0.2s ease-in-out;
    &--has-border {
      box-shadow: inset 0 0 0 1px color(chip-border);
      &.chip--is-selected {
        box-shadow: none;
      }
    }
    &--is-dark {
      color: color(primary-text);
      background-color: color(chip-dark-background);
    }
    &--is-selected {
      color: color(_white);
      background-color: color(primary);
    }
  }
</style>
