<template>
  <base-main
    background
    class="reading-room-category"
    :loading="$options.loading"
  >
    <div class="reading-room-category__wrapper">
      <reading-room-single-category
        v-if="currentCategory"
        :description="currentCategory.description"
        :title="currentCategory.display_name"
        :headerLevel="1"
        v-bind="{ articles }"
      >
        <reading-room-filters
          @filterChange="onFilterChange"
        />
      </reading-room-single-category>
    </div>
  </base-main>
</template>

<script>
  import { capitalize } from 'lodash'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import ReadingRoomFilters from '@/components/reading-room/ReadingRoomFilters'
  import ReadingRoomSingleCategory from '@/components/reading-room/ReadingRoomSingleCategory'
  import shouldFetch from '@/mixins/shouldFetch'

  export default {
    metaInfo() {
      if (!this.currentCategory) return
      const { display_name: title } = this.currentCategory

      this.setBreadcrumbCategory(title)

      return {
        title,
      }
    },

    components: {
      BaseMain,
      ReadingRoomFilters,
      ReadingRoomSingleCategory,
    },

    mixins: [
      shouldFetch,
    ],

    props: {
      categoryId: {
        type: Number,
        required: true,
      },
    },

    created() {
      this.fetchCategory(this.categoryId)
      const triggerDataId = this.getTriggerDataIdByPluginName('reading-room')
      this.shouldFetch('topics', 'fetchTopics', { triggerDataId })
        .then(() => {
          const topic = this.getTopic
          if (!topic) return

          const { display_name } = topic
          this.setBreadcrumbTopic(display_name)
        })
    },

    destroyed() {
      this.resetBreadcrumbs()
    },

    data() {
      return {
        filters: null,
      }
    },

    methods: {
      ...mapActions('readingRoom', [
        'fetchCategory',
        'fetchTopics',
      ]),
      ...mapMutations('route', [
        'setBreadcrumbCategory',
        'setBreadcrumbTopic',
        'resetBreadcrumbs',
      ]),
      onFilterChange({ activeFilters, changedFilter }) {
        this.filters = activeFilters
        this.announceFilterChange(changedFilter)
      },
      announceFilterChange(changedFilter) {
        const filterTypeText = capitalize(this.$t(changedFilter.filterType))
        const filterStateText = this.$t(changedFilter.isActive ? 'Enabled' : 'Disabled')
          .toLowerCase()
        const articleText = this.articles.length === 1
          ? this.$t('Showing 1 article')
          : this.$t('Showing {amount} articles', { amount: this.articles.length })

        this.$announcer.set(`${filterTypeText} ${filterStateText}. ${articleText}`)
      },
    },

    computed: {
      ...mapGetters('readingRoom', [
        'getCategoryArticlesByStatus',
        'getTopicById',
      ]),
      ...mapState('readingRoom', [
        'category',
        'topics',
      ]),
      ...mapGetters('triggers', [
        'getTriggerDataIdByPluginName',
      ]),
      getTopic() {
        return this.getTopicById(this.topicId)
      },
      currentCategory() {
        return this.category?.[this.categoryId]
      },
      articles() {
        return this.getCategoryArticlesByStatus(this.categoryId, this.filters)
      },
      topicId() {
        return parseInt(this.$route.params.topicId, 10)
      },
    },

    loading: [
      'readingRoom/fetchCategory',
      'readingRoom/fetchTopics',
    ],
  }
</script>

<style lang="scss">
  .reading-room-category {
    &__wrapper {
      @include reading-room-wrapper;
    }
  }
</style>
