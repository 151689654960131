<template>
  <base-main
    data-testid="reflecting-quiz"
    :loading="$options.loading"
  >
    <container
      v-if="form"
      first
    >
      <stepper-form
        @submit="onSubmit"
      >

        <stepper-step
          v-for="(question, i) in questions"
          :key="i"
          :fieldsNames="`question_${question.id}`"
          :isSaving="isSaving && i == max"
          :testid="'reflecting-quiz'"
        >

          <template slot="label">
            {{ $t(question.question) }}
          </template>

          <template slot="content">
            <input-group
              :label="$t(question.label)"
              :name="`question_${question.id}`"
              :type="'text'"
              :validation="$options.validation.question"
              v-model="form[question.id]"
            />
          </template>

        </stepper-step>
      </stepper-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import StepperForm from '@/components/forms/stepper-form/StepperForm'
  import StepperStep from '@/components/stepper/stepper-step/StepperStep'

  import questions from '@/data/reflecting-quiz/reflectingQuizQuestions'

  import { saveAnswers } from '@/mixins/quizzes-answers'
  import shouldFetch from '@/mixins/shouldFetch'

  const pluginName = 'reflectingQuiz'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.reflecting-quiz.reflecting-quiz.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      StepperForm,
      StepperStep,
      InputGroup,
    },

    mixins: [
      saveAnswers(pluginName),
      shouldFetch,
    ],

    props: {
      isRerun: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    created() {
      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(this.redirectIfNeccessary)
        .then(isRedirect => {
          if (isRedirect) return

          this.createForm()
        })
    },

    data() {
      return {
        form: null,
        questions,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      createForm() {
        this.form = this.questions
                      .map(question => question.id)
                      .reduce((acc, curr) => Object.assign(acc, { [curr]: '' }), {})
      },
      onSubmit(isValid) {
        if (!isValid) return

        this.saveAnswers()
          .then(() => this.$router.push({ name: 'ReflectingResults' }))
      },
      redirectIfNeccessary() {
        return new Promise(resolve => {
          const condition = this.answers && !this.isRerun

          if (condition) {
            this.$router.replace({ name: 'ReflectingResults' })
          }

          resolve(condition)
        })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('quizzesAnswers', [
        'getAnswers',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          `${pluginName}/addAnswers`,
          `${pluginName}/updateAnswers`,
        ])
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],

    validation: {
      question: {
        required: true,
      },
    },
  }
</script>
