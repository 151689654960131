<template>
  <form-group
    :label="ariaLabel"
    v-bind="{
      name,
      testid,
      validation,
    }"
  >
    <div
      class="range-group"
      :data-testid="`${dataTestid} range-group`"
      v-merge-attr:data-testid
    >
      <sliding-scale
        ref="slider"
        v-bind="{ ariaLabel, contextLabels, interval, max, min }"
        v-model="rangeValue"
      />
    </div>
  </form-group>
</template>

<script>
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import SlidingScale from '@/components/forms/sliding-scale/SlidingScale'

  import mergeAttr from '@/directives/mergeAttr'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      FormGroup,
      SlidingScale,
    },

    directives: {
      mergeAttr,
    },

    mixins: [
      testid(),
    ],

    props: {
      ariaLabel: {
        type: String,
        required: false,
        default: '',
      },
      contextLabels: {
        type: Array,
        required: false,
        default: null,
      },
      from: {
        type: String,
        required: false,
        default: '',
      },
      interval: {
        type: Number,
        required: false,
        default: 1,
      },
      max: {
        type: Number,
        required: true,
      },
      min: {
        type: Number,
        required: false,
        default: 0,
      },
      name: {
        type: String,
        required: true,
      },
      to: {
        type: String,
        required: false,
        default: '',
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: Number,
        required: true,
      },
    },

    methods: {
      setIndex(i) {
        this.$refs.slider.setIndex(i)
      },
    },

    computed: {
      rangeValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
  }
</script>
