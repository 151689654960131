<template>
  <base-main
    data-testid="eq-5-d-thank-you"
  >
    <container
      first
      flex-column
      plugin-article
    >
      <plugin-article>
        <template #title>
          {{ $t('views.plugins.eq-5d.eq-5d-thank-you.title') }}
        </template>
        <template #content>
          <p>
            {{ $t('views.plugins.eq-5d.eq-5d-thank-you.content') }}
          </p>
        </template>
      </plugin-article>

      <base-button
        align-end
        data-testid="eq-5-d-thank-you-finish"
        :link="{ name: 'Tools' }">
          {{ $t('Finish') }}
      </base-button>
    </container>
  </base-main>
</template>

<script>
  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.eq-5d.eq-5d-thank-you.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      unblockPlugin,
    ],

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    created() {
      this.unblockPlugin()
    },

    beforeRouteEnter(to, from, next) {
      to.params.token
        ? next()
        : next({ name: 'Eq5d', replace: true })
    },
  }
</script>
