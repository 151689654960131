<template>
  <div
    class="plugin-item"
    :class="[
      { 'plugin-item--is-error': isError },
      { 'plugin-item--is-selected': isSelected },
    ]"
    data-testid="plugin-item"
    tabindex="0"
    :aria-checked="isSelected.toString()"
    :role="type"
    v-merge-attr:data-testid
    @click="onClick"
  >
    <label class="plugin-item__label">
      <input
        class="plugin-item__input"
        data-testid="plugin-item-input"
        :name="$t(item.name)"
        v-bind="{ type }"
      >
      <template
        v-if="item.icon"
      >
        <inline-svg
          v-if="isSvg"
          aria-hidden="true"
          data-testid="plugin-item-icon"
          :src="item.icon"
        />
        <img
          v-else
          alt=""
          data-testid="plugin-item-icon"
          :src="require(`@/assets/img/${item.icon}`)"
        >
      </template>
      <span
        class="plugin-item__title"
        data-testid="plugin-item-title"
      >
        {{ $t(item.name, { val: item.val }) }}
      </span>
    </label>
  </div>
</template>

<script>
  import mergeAttr from '@/directives/mergeAttr'

  export default {
    inheritAttrs: false,

    directives: {
      mergeAttr,
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
      isError: {
        type: Boolean,
        required: false,
        default: false,
      },
      isSelected: {
        type: Boolean,
        required: false,
        default: false,
      },
      isSvg: {
        type: Boolean,
        required: false,
        default: true,
      },
      type: {
        type: String,
        required: false,
        default: 'checkbox',
      },
    },

    methods: {
      onClick(e) {
        this.$emit('click', e)
      },
    },
  }
</script>

<style lang="scss">
  .plugin-item {
    width: auto;
    height: 6.5rem;
    padding: 0 2rem;
    position: relative;
    cursor: pointer;
    box-shadow: box-shadow(default);
    background-color: color(_white);
    border: 2px solid transparent;
    border-radius: 3.25rem;
    user-select: none;
    transition: border-color 0.2s, background-color 0.1s;
    svg,
    img {
      width: auto;
      max-width: 5rem;
      height: 5rem;
      margin-right: 1.5rem;
    }
    &--is-error {
      border-color: color(error);
      background-color: color(light-error);
      .plugin-item__title {
        color: color(primary-dark);
      }
    }
    &--is-selected {
      border-color: color(plugin-item-selected-border);
      background-color: color(plugin-item-selected-background);
      transition: border-color 0.1s, background-color 0.2s;
    }
    &__label {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      height: 100%;
      pointer-events: none;
    }
    &__input {
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      z-index: z-index(under-base);
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
      visibility: hidden;
    }
    &__title {
      margin-bottom: -3px;
      color: color(primary);
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 800;
    }
  }
</style>
