<template>
  <button
    class="language-button"
    :class="{ 'language-button--is-highlighted': isCurrent }"
    data-testid="menu-language-switcher-language"
    :disabled="isDisabled"
    @click.prevent.stop="onClick"
  >
    <inline-svg
      v-if="isCurrent"
      class="language-button__icon"
      src="icons/arrow.svg"
    />
    {{ language.display_name }}
  </button>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    inheritAttrs: false,

    props: {
      isCurrent: {
        type: Boolean,
        required: false,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      language: {
        type: Object,
        required: true,
      },
    },

    methods: {
      ...mapActions('languages', [
        'fetchLanguages',
      ]),
      onClick() {
        if (this.isCurrent) return

        this.$emit('click', this.language)
      },
    },
  }
</script>

<style lang="scss">
  .language-button {
    display: flex;
    align-items: center;
    margin: 0 0 0 2rem;
    padding: 0;
    color: color(primary-text);
    &__icon svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.8rem;
      fill: color(primary-text);
    }
    &--is-highlighted {
      margin: 0;
      font-weight: 700;
      cursor: auto;
    }
    &:disabled {
      cursor: auto;
    }
  }
</style>
