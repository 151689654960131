<script>
  export default {
    functional: true,

    render(h, { data, children }) {
      const attrsClasses = data.attrs
                        ? Object.keys(data.attrs).map(key => `grid--${key}`)
                        : []

      const { attrs, staticClass, ...newData } = data

      return h(
        'div', {
          staticClass: ['grid'].concat(attrsClasses, staticClass).join(' ').trim(),
          ...newData,
        }, children,
      )
    },
  }
</script>

<style lang="scss">
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    &--center {
      justify-content: center;
    }
    &--journey {
      margin: 0;
    }
    &--top {
      padding-top: 1.5rem;
    }
    &--overflow {
      overflow: hidden;
    }
  }
</style>
