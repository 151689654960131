<template>
  <base-main
    class="reflecting-results"
    data-testid="reflecting-results"
    :loading="$options.loading"
  >
    <container
      first
      flex-column
      plugin-article
    >
      <div>
        <plugin-article
          has-background
          class="reflecting-results__content"
          :img="'quiz/reflecting-quiz.png'"
          :testid="'reflecting-results'"
        >
          <template slot="content">
            <div
              v-for="(situation, i) in situations"
              :key="i"
            >
              <h2>
                {{ $t(`${$options.slug}.situation`) }}
                {{ situation.id }}
              </h2>

              <div class="reflecting-results-questions">
                <div
                  v-for="(question, j) in situation.questions"
                  :key="j"
                >
                  <h3 class="reflecting-results-header">
                    {{ $t(question.question) }}
                  </h3>
                  <p>
                    {{ question.answer }}
                  </p>
                </div>
              </div>
            </div>

            <div
              data-testid="reflecting-results-date"
              class="reflecting-results-updated"
            >
              {{ $t(`(Created on {date})`, { date: createdAt }) }}
            </div>

            <div class="reflecting-results__advice">
              <p>{{ $t(`${$options.slug}.p-1`) }}</p>

              <ul>
                <li>
                  {{ $t(`${$options.slug}.question-1`) }}
                  {{ $t(`${$options.slug}.question-2`) }}
                </li>
                <li>
                  {{ $t(`${$options.slug}.question-3`) }}
                </li>
                <li>
                  {{ $t(`${$options.slug}.question-4`) }}
                </li>
                <li>
                  {{ $t(`${$options.slug}.question-5`) }}
                </li>
                <li>
                  {{ $t(`${$options.slug}.question-6`) }}
                </li>
              </ul>

              <p>{{ $t(`${$options.slug}.p-2`) }}</p>
            </div>
          </template>
        </plugin-article>
      </div>

      <base-button
        align-end
        data-testid="reflecting-results-rerun-button"
        :link="{ name: capitalizedPluginName, params: { isRerun: true } }"
      >
        {{ $t('Rerun') }}
      </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import { DATE_FORMATS } from '@/constants'

  import questions from '@/data/reflecting-quiz/reflectingQuizQuestions'
  import results from '@/data/reflecting-quiz/reflectingQuizResults'

  import capitalize from '@/filters/capitalize'
  import formatDate from '@/filters/formatDate'

  import shouldFetch from '@/mixins/shouldFetch'
  import unblockPlugin from '@/mixins/unblockPlugin'

  const PLUGIN_NAME = 'reflectingQuiz'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.reflecting-quiz.reflecting-results.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      shouldFetch,
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()
      this.shouldFetch('answers', 'fetchAnswers', this.$static.pluginName)
        .then(() => {
          this.answers
            ? this.getResult()
            : this.$router.replace({ name: this.capitalizedPluginName })
        })
    },

    data() {
      return {
        situations: null,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      getResult() {
        const { situationsQuestions } = results

        this.situations = Object.entries(situationsQuestions)
                            .map(([key, values]) => ({
                              id: key,
                              questions: values
                                          .map(value => (
                                            questions.find(({ id }) => value == id)))
                                          .map(question => ({
                                            answer: this.answers[question.id],
                                            ...question,
                                          })),
                            }))
      },
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
        'getUpdatedAt',
      ]),
      createdAt() {
        const createdAt = this.getUpdatedAt(this.$static.pluginName)

        return formatDate(createdAt, DATE_FORMATS.dateShort)
      },
      answers() {
        return this.getAnswers(PLUGIN_NAME)
      },
      capitalizedPluginName() {
        return capitalize(PLUGIN_NAME)
      },
    },

    slug: 'views.plugins.reflecting-quiz.reflecting-results',

    static() {
      return {
        pluginName: PLUGIN_NAME,
      }
    },

    loading: [
      `${PLUGIN_NAME}/fetchAnswers`,
    ],
  }
</script>

<style lang="scss">
  .reflecting-results {
    &-questions {
      padding: 0 0 0 1rem;
    }
    &-header {
      padding: 0 0 0.5rem;
    }
    &-updated {
      padding: 2rem 0 0;
      color: color(secondary-text);
    }
    &__content {
      margin: 0 0 2rem;
    }
    &__advice {
      padding: 2rem 0 0;
    }
    ul {
      list-style-type: disc;
      padding: 0 0 0 1.8rem;
    }
  }
</style>
