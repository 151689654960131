<template>
  <div
    class="rounded-doughnut-chart"
    data-testid="rounded-doughnut-chart"
    v-merge-attr:data-testid
  >
    <doughnut-chart
      class="rounded-doughnut-chart-base-component"
      aria-hidden="true"
      :height="160"
      :options="$static.options"
      :width="160"
      v-bind="{ chartData, title }"
    />

    <ul
      class="rounded-doughnut-chart-legend"
      data-testid="rounded-doughnut-chart-legend"
    >
      <li
        v-for="(item, i) in chartData.datasets[0].data"
        :key="i"
        class="chart-legend-item"
        data-testid="rounded-doughnut-chart-legend-item"
      >
        <span
          class="chart-legend-item-color"
          :style="{
            'background-color': chartData.datasets[0].backgroundColor[i],
          }"
        />
        <span class="chart-legend-item-label">
          <slot
            v-bind="{ i, item }"
          >
            {{ $t(chartData.labels[i]) }} - {{ item }}
          </slot>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
  import { merge } from 'lodash'

  import DoughnutChart from '@/components/charts/doughnut-chart/DoughnutChart'

  import mergeAttr from '@/directives/mergeAttr'

  export default {
    components: {
      DoughnutChart,
    },

    directives: {
      mergeAttr,
    },

    props: {
      title: {
        type: String,
        required: false,
        default: '',
      },
      chartData: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },

    created() {
      this.mergeOptions()
    },

    methods: {
      mergeOptions() {
        this.$static.options = merge(this.$static.default, this.options)
      },
    },

    static() {
      return {
        default: {
          cutoutPercentage: 87,
          tooltips: {
            enabled: false,
          },
          responsive: true,
          maintainAspectRatio: true,
          elements: {
            arc: {
              roundedCorners: true,
              borderWidth: 0,
            },
          },
          legend: {
            display: false,
          },
        },
        options: {},
      }
    },
  }
</script>

<style lang="scss">
 .rounded-doughnut-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-legend {
      margin-top: 3rem;
      color: color(grey);
      font-weight: 600;
    }
    &-base-component {
      position: relative;
      &::after {
        content: attr(title);
        width: 100%;
        padding: 3rem;
        position: absolute;
        top: 50%;
        left: 50%;
        color: color(primary);
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
        transform: translate(-50%, -50%);
      }
    }
    .chart-legend-item {
      display: flex;
      margin-bottom: 1rem;
    }
    .chart-legend-item-color {
      display: inline-block;
      min-width: 1.6rem;
      height: 1.6rem;
      margin-top: 1px;
      margin-right: 1rem;
      border-radius: 1.6rem;
    }
  }
</style>
