<template>
  <base-main
    data-testid="eq-5-d"
  >
    <container
      first
      flex-column
      plugin-article
    >
      <plugin-article
        :img="'eq5d/intro.png'"
      >
        <template slot="title">
          {{ $t('EQ-5D Questionnaire') }}
        </template>
        <template slot="content">
          <p>
            {{ $t('views.plugins.eq-5d.eq-5d.content-1') }}
          </p>
          <p>
            {{ $t('views.plugins.eq-5d.eq-5d.content-2') }}
          </p>
        </template>
      </plugin-article>

      <base-button
        align-end
        data-testid="eq-5-d-next"
        :disabled="isFetching"
        :isLoading="isFetching"
        @click="onNext">
          {{ $t('Next') }}
      </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.eq-5d.eq-5d.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    methods: {
      ...mapActions('eq5d', [
        'fetchBeginning',
      ]),
      onNext() {
        this.fetchBeginning()
          .then(data => this.$router.push({ name: 'Eq5dQuestionnaire', params: { data } }))
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isFetching() {
        return this.getLoadingStatesForActions([
          'eq5d/fetchBeginning',
        ])
      },
    },
  }
</script>
