<template>
  <base-main
    class="habits-quiz"
    :loading="$options.loading"
    data-testid="habits-quiz"
  >

    <container class="habits-quiz__wrapper">
      <stepper-custom-form
        v-if="form"
        ref="form"
        class="habits-quiz__form"
        :buttonLabels="{ previousLabel }"
        :eventCallbacks="{ onNext, onPrevious }"
        :isLoading="isSaving"
        :testid="'habits-quiz'"
        @cancel="goToTools"
        @lastStepAccessed="unblockPlugin"
        @stepChanged="setStep"
        @submit="goToTools"
      >
        <stepper-custom-step
          class="quiz-intro"
          data-testid="habits-quiz-stepper-custom-step"
          :title="'Habits Quiz'"
          :useTitleNumeration="false"
          :testid="'habits-quiz'"
        >
          <p class="quiz-intro__title">
            {{ $t('views.plugins.habits-quiz.habits.title') }}
          </p>
          <ul class="quiz-list">
            <li class="quiz-list__element">
              {{ $t('views.plugins.habits-quiz.habits.intro-1') }}
            </li>
            <li class="quiz-list__element">
              {{ $t('views.plugins.habits-quiz.habits.intro-2') }}
            </li>
            <li class="quiz-list__element">
              {{ $t('views.plugins.habits-quiz.habits.intro-3') }}
            </li>
          </ul>
        </stepper-custom-step>

        <stepper-custom-step
          class="quiz-select"
          data-testid="habits-quiz-stepper-custom-step"
          :title="'Habits Quiz'"
          :useTitleNumeration="false"
        >
          <div
            class="quiz-select__description"
            data-testid="habits-quiz-select-description"
          >
            {{ $t('views.plugins.habits-quiz.habits.select-description') }}
          </div>

          <radio-multi-group
            :labelKey="'answer'"
            :legend="$t('views.plugins.habits-quiz.habits.legend-1')"
            :name="'habits_answer'"
            :options="$static.answers"
            :validation="$options.validation.field"
            :valueKey="'key'"
            :testid="'habits-quiz'"
            v-model="pickedAnswer"
          />

          <input-group
            v-if="isOtherChecked"
            :name="'habits_custom_answer'"
            :label="'views.plugins.habits-quiz.habits.other-label'"
            :type="'text'"
            :validation="$options.validation.field"
            :testid="'habits-quiz'"
            v-model="otherAnswer"
          />
        </stepper-custom-step>

        <stepper-custom-step
          disable-transition-effect
          data-testid="habits-quiz-stepper-custom-step"
          v-for="habit in $static.habits"
          :key="habit.id"
          :title="answer"
          :useTitleNumeration="false"
          :testid="'habits-quiz'"
        >
          {{ $t(habit.question) }}
          <range-group
            ref="range"
            :ariaLabel="`${answer} ${$t(habit.question)}`"
            :contextLabels="['Disagree', 'Agree']"
            :min="0"
            :max="7"
            :name="`habit_${habit.id}`"
            v-model="form[habit.id]"
          />
        </stepper-custom-step>

        <stepper-custom-step
          data-testid="habits-quiz-stepper-custom-step"
          :title="result.title"
          :useTitleNumeration="false"
          :testid="'habits-quiz'"
        >
          <div v-html="$t(result.introduction)"/>
          <ul class="results-list">
            <li
              v-for="(advice, i) in result.advices"
              :key="i"
              class="results-list__element"
              v-html="$t(advice)"
            />
          </ul>
          <p v-html="$t('views.plugins.habits-quiz.habits.finish-message')"/>
        </stepper-custom-step>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import { RadioMultiGroup } from '@/components/forms/radio'
  import RangeGroup from '@/components/forms/range-group/RangeGroup'
  import {
    StepperCustomForm,
    StepperCustomStep,
  } from '@/components/stepper-custom'

  import habits from '@/data/habits-quiz/habitsQuizQuestions'
  import results from '@/data/habits-quiz/habitsQuizResults'

  import shouldFetch from '@/mixins/shouldFetch'
  import unblockPlugin from '@/mixins/unblockPlugin'

  const PLUGIN_NAME = 'habitsQuiz'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.habits-quiz.habits.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      InputGroup,
      RadioMultiGroup,
      RangeGroup,
      StepperCustomForm,
      StepperCustomStep,
    },

    mixins: [
      shouldFetch,
      unblockPlugin,
    ],

    created() {
      this.shouldFetch('answers', 'fetchAnswers', PLUGIN_NAME)
        .then(this.prepareForm)
        .then(this.fillInForm)
    },

    data() {
      return {
        currentStep: null,
        form: null,
        otherAnswer: null,
        pickedAnswer: null,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'addAnswers',
        'fetchAnswers',
        'updateAnswers',
      ]),
      saveAnswers() {
        /* eslint-disable camelcase */
        const pluginName = PLUGIN_NAME
        const plugin_id = this.getTriggerByName('habits-quiz').plugin.id
        const program_id = this.currentProgramId
        const answers = { points: this.form, habitName: this.answer }

        return this.answers
          ? this.updateAnswers({ form: { answers }, pluginName })
          : this.addAnswers({
              form: { answers, plugin_id, program_id },
              pluginName,
            })
      },
      async onNext(next, step) {
        if (step == this.maxStep - 1) await this.saveAnswers()

        next()
      },
      onPrevious(previous, step) {
        step == this.maxStep
          ? this.goToWelcome()
          : previous()
      },
      goToResults() {
        this.$refs.form.changeStep(this.maxStep)
      },
      goToWelcome() {
        this.$refs.form.changeStep(0)
        this.prepareForm()
      },
      goToTools() {
        this.$router.push({ name: 'Tools' })
      },
      prepareForm() {
        this.form = habits.reduce((acc, { id }) => {
          acc[id] = 0
          return acc
        }, {})
        this.pickedAnswer = null
        this.otherAnswer = null
      },
      fillInForm() {
        if (!this.answers) return

        this.form = this.answers.points
        const { answers } = this.$static
        const { key } = answers.find(el => el.answer === this.answers.habitName) || {}

        if (key) {
          this.pickedAnswer = key
        } else {
          this.pickedAnswer = this.$static.otherIndex
          this.otherAnswer = this.answers.habitName
        }

        this.goToResults()
      },
      setStep(step) {
        this.currentStep = step
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      ...mapGetters('triggers', [
        'getTriggerByName',
      ]),
      answers() {
        return this.getAnswers(PLUGIN_NAME)
      },
      answer() {
        return this.isOtherChecked
          ? this.otherAnswer
          : this.readableAnswer
      },
      readableAnswer() {
        return this.$static.answers.find(el => el.key === this.pickedAnswer)?.answer
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          `${PLUGIN_NAME}/addAnswers`,
          `${PLUGIN_NAME}/updateAnswers`,
        ])
      },
      isOtherChecked() {
        return this.pickedAnswer == this.$static.otherIndex
      },
      points() {
        if (!this.form) return

        return Object.values(this.form).reduce((acc, curr) => acc + curr, 0)
      },
      choice() {
        return this.$static.rules.findIndex(el => this.points < el)
      },
      result() {
        return {
          advices: results.advices[this.choice],
          introduction: results.introductions[this.choice],
          title: results.titles[this.choice],
        }
      },
      previousLabel() {
        return this.currentStep == this.maxStep ? 'Rerun' : 'Previous'
      },
      maxStep() {
        const otherSlidesCount = 3

        return this.$static.habits.length + otherSlidesCount - 1
      },
    },

    loading: [
      `${PLUGIN_NAME}/fetchAnswers`,
    ],

    validation: {
      field: {
        required: true,
      },
    },
    static() {
      return {
        habits,
        results,
        answers: [
          { key: 1, answer: 'Eating healthy snacks' },
          { key: 2, answer: 'Cutting my portion sizes by a quarter or fifth' },
          { key: 3, answer: 'Drinking healthier drinks' },
          { key: 4, answer: 'Filling half my plate with salad or vegetables, before I add anything else' },
          { key: 5, answer: 'Doing physical activity at least 3 times a week' },
          { key: 6, answer: 'Doing physical activity at least 5 times a week' },
          { key: 7, answer: 'Doing some physical activity every day' },
          { key: 8, answer: 'Only eat foods from my eating plan’s Red Food list once a week' },
          { key: 9, answer: 'Eating low carb meals' },
          { key: 10, answer: 'Other' },
        ],
        otherIndex: 10,
        rules: [
          30,
          45,
          60,
          85,
        ],
      }
    },
  }
</script>

<style lang="scss">
  .habits-quiz {
    &__wrapper {
      width: 100%;
      max-width: 77.5rem;
      padding: 2rem;
      @include min-lg {
        padding: 4rem 0;
      }
    }
    &__form {
      width: 100%;
    }
    .quiz-intro {
      &__title {
        margin: 0 0 1rem;
      }
    }
    .quiz-select {
      &__description {
        margin: 0 0 1rem;
      }
    }
    .results-list {
      margin: 1rem 0 0;
      &__element {
        list-style-type: disc;
        margin:0 0 1.3rem 2rem;
      }
    }
    .quiz-list {
      &__element {
        list-style-type: disc;
        margin:0 0 1.3rem 2rem;
      }
    }
    .range-group {
      padding: 0 1rem;
    }
  }
</style>
