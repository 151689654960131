export default {
  methods: {
    numberizeProperties(...properties) {
      properties.forEach(property => {
        const splitted = property.split('.')
        const max = splitted.length - 1

        splitted
          .reduce((acc, curr, i) => {
            if (i == max && acc[curr] !== null) {
              acc[curr] = Number(acc[curr])
            }

            return acc[curr]
          }, this)
      })
    },
  },
}
