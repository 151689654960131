<template>
  <div
    class="plugin-article"
    :class="{ 'plugin-article--has-background': hasBackground }"
    :data-testid="dataTestid"
  >

    <div
      class="plugin-article-header"
      :class="{ 'plugin-article-header--reversed': reversedHeader }"
      :data-testid="`${dataTestid}-header`"
    >
      <div
        v-if="img"
        class="plugin-image"
      >
        <div class="plugin-img">
          <img
            alt=""
            role="presentation"
            :src="requireImage(img)"
            :data-testid="`${dataTestid}-header-image`"
          >
        </div>
      </div>

      <h2
        v-if="hasTitle"
        class="plugin-title"
        :data-testid="`${dataTestid}-header-title`"
      >
        <slot name="title"/>
      </h2>
    </div>

    <div
      class="plugin-content-container"
      :class="{ 'plugin-content-container--no-margin-bottom': noMarginBottom }"
    >
      <div
        class="plugin-content"
        :data-testid="`${dataTestid}-content`"
      >
        <slot name="content"/>
      </div>
    </div>

  </div>
</template>

<script>
  import requireImage from '@/mixins/requireImage'
  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      requireImage,
      testid('plugin-article'),
    ],

    props: {
      hasBackground: {
        type: Boolean,
        required: false,
        default: false,
      },
      img: {
        type: String,
        required: false,
        default: '',
      },
      reversedHeader: {
        type: Boolean,
        required: false,
        default: false,
      },
      noMarginBottom: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      hasTitle() {
        return this.$slots.title
      },
    },
  }
</script>

<style lang="scss">
  .plugin {
    &-article {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      align-items: center;
      &--has-background {
        .plugin-image {
          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: z-index(default);
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            background-color: color(_white);
          }
        }
        .plugin-content-container {
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
        .plugin-content {
          margin: 0 0 2.4rem;
        }
        .plugin-title,
        .plugin-content-container {
          padding: 0 3rem 3rem 3rem;
          background-color: color(_white);
        }
      }
    }
    &-article-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &--reversed {
        flex-direction: column-reverse;
        .plugin-image {
          margin-bottom: 2.3rem;
        }
        .plugin-title {
          margin-top: 0;
        }
      }
    }
    &-image {
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
    }
    &-img {
      margin: 3rem 0;
      z-index: z-index(base);
      img {
        width: 8.5rem;
        height: 8.5rem;
        object-fit: contain;
      }
    }
    &-title {
      width: 100%;
      margin: 0;
      padding: 2rem 0;
      text-align: center;
      @media all and (max-height: 650px) {
        padding: 1rem 0;
      }
    }
    &-content-container {
      width: 100%;
      p {
        &:first-child {
          margin-top: 0;
        }
      }
      &--no-margin-bottom {
        margin-bottom: 0;
      }
    }
    &-article-text {
      &--center {
        text-align: center;
      }
    }
  }
</style>
