<template>
  <base-main
    class="coaching-start"
    :loading="$options.loading"
    data-testid="coaching-plugin-start"
  >
    <container
      stepper
    >
      <stepper-custom-form
        ref="form"
        :buttonLabels="{ finishLabel: $t('Finish & Save') }"
        :isLoading="isSaving"
        :testid="'coaching-plugin-start'"
        @cancel="onCancel"
        @submit="onSubmit"
      >
        <template #default="{ currentStep }">
          <stepper-custom-step
            data-testid="coaching-plugin-start-stepper-custom-step"
            :appendValidator="false"
            :title="$t(`${$options.slug}.title-1`)"
            v-bind="{ currentStep }"
          >
            <img
              alt=""
              class="coaching-start__photo"
              src="@/assets/img/coaching-start.png"
            >
            <p>
              {{ $t(`${$options.slug}.start-1`) }}
            </p>
          </stepper-custom-step>

          <stepper-custom-step
            data-testid="coaching-plugin-start-stepper-custom-step"
            :title="$t(`${$options.slug}.title-2`)"
            :description="`This step will help us match you up with a coach who has availability on
            the day and time you choose, for the duration of your programme.`"
            v-bind="{ currentStep }"
          >
            <p id="preferred-contact-desc">
              {{ $t(`${$options.slug}.start-2`) }}
            </p>

            <checkbox-multi-group
              :describedBy="'preferred-contact-desc'"
              :legend="$t(`${$options.slug}.form-title-1`)"
              :name="'preferred_contact_days'"
              :options="$options.formOptions.days"
              :validation="$options.validation.preferred_contact_days"
              v-model="form.preferred_contact_days"
            >
              <template #option="{ option }">
                {{ $t(option) | capitalize }}
              </template>
            </checkbox-multi-group>

            <radio-multi-group
              :describedBy="'preferred-contact-desc'"
              :legend="$t(`${$options.slug}.form-title-2`)"
              :name="'preferred_contact_time'"
              :options="$options.formOptions.time"
              :validation="$options.validation.preferred_contact_time"
              v-model="form.preferred_contact_time"
            >
              <template #option="{ option }">
                {{ $t(option) | capitalize }}
              </template>
            </radio-multi-group>
          </stepper-custom-step>
        </template>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import {
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'
  import { CheckboxMultiGroup } from '@/components/forms/checkbox-group'
  import { RadioMultiGroup } from '@/components/forms/radio'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import capitalize from '@/filters/capitalize'

  import unblockPlugin from '@/mixins/unblockPlugin'

  import eventBus from '@/tools/event-bus'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.coaching-plugin.coaching-plugin-start.meta.title'),
      }
    },

    components: {
      BaseMain,
      CheckboxMultiGroup,
      Container,
      RadioMultiGroup,
      StepperCustomForm,
      StepperCustomStep,
    },

    filters: {
      capitalize,
    },

    mixins: [
      unblockPlugin,
    ],

    props: {
      changeTimes: {
        type: Boolean,
        required: false,
        default: false,
      },
      fromRedirect: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    created() {
      if (this.preferences
        || this.fromRedirect
        || this.changeTimes) return

      this.fetchPreferences(this.getCurrentUserId)
        .then(() => this.$router.replace({ name: 'CoachingPlugin' }))
        .catch(() => {})
    },

    mounted() {
      if (this.changeTimes) {
        eventBus.$emit('nextStep')
      }
    },

    data() {
      return {
        form: {
          preferred_contact_days: [],
          preferred_contact_time: false,
        },
        hasMultipleElements: true,
      }
    },

    methods: {
      ...mapActions('coaching', [
        'createPreferences',
        'fetchPreferences',
      ]),
      onSubmit(isValid) {
        if (!isValid || this.isSaving) return

        const form = {
          program_id: this.currentProgramId,
          ...this.form,
        }

        this.createPreferences({ form, id: this.getCurrentUserId })
          .then(coach => {
            if (!coach) {
              return this.$router.replace({
                name: 'CoachingPluginStartNoMatch',
                params: { fromStart: true },
              })
            }

            this.$mam.event('Coaching - coach assigned', {
              coach_id: coach.user.ppuid,
              coach_name: coach.name,
              assignment_method: 'Auto Assign',
            })

            this.$router.replace({
              name: 'CoachingPluginStartMatch',
              params: { coach, fromStart: true },
            })
          })
          .catch(() => {
            this.$router.replace({
              name: 'CoachingPluginStartNoMatch',
              params: { fromStart: true },
            })
          })
      },
      onCancel() {
        this.$router.replace({ name: 'Home' })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      ...mapState('coaching', [
        'preferences',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'coaching/createPreferences',
        ])
      },
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin-start',

    formOptions: {
      days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      time: ['morning', 'afternoon', 'evening'],
    },

    loading: [
      'coaching/fetchPreferences',
    ],

    validation: {
      preferred_contact_days: {
        required: true,
      },
      preferred_contact_time: {
        required_radio: true,
      },
    },

  }
</script>

<style lang="scss">
  .coaching-start {
    &__photo {
      margin: 0 0 3rem;
      border-radius: 1rem;
    }
    &__form-heading {
      display: block;
      margin: 2.5rem 0 1.6rem;
    }
    .stepper-custom {
      max-width: 63rem;
      margin: 0 auto;
    }
    .checkbox-group {
      margin: 0;
      &:not(:first-child) {
        margin: 1rem 0 0;
      }
    }
    .radio-group {
      .input-details {
        margin: 0;
      }
    }
    .input-details {
      margin: 1rem 0 0;
    }
  }
</style>
