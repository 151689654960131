<template>
  <plugin-article
    :img="'weekly-review/intro.png'">
      <template slot="title">
        {{ $t(`${$options.slug}.title`) }}
      </template>
      <template slot="content">
        <p>
          {{ $t(`${$options.slug}.content-1`) }}
        </p>
        <p>
          {{ $t(`${$options.slug}.content-2`) }}
        </p>
      </template>
  </plugin-article>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review-intro.meta.title'),
      }
    },

    components: {
      PluginArticle,
    },

    created() {
      this.fetchInitWeeklyReviewData()
          .then(this.fetchWeeklyReviewData)
    },

    methods: {
      ...mapActions('weeklyReview', [
        'fetchInitWeeklyReviewData',
        'fetchWeeklyReviewData',
      ]),
    },

    computed: {
      ...mapState('weeklyReview', [
        'hasSummary',
      ]),
    },

    slug: 'views.plugins.weekly-review.weekly-review-intro',
  }
</script>
