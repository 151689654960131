<template>
  <div>
    <component
      :is="`${type}-layout`">
        <router-view/>
    </component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import HomeLayout from '@/layouts/Home'
  import PlainLayout from '@/layouts/Plain'

  export default {
    components: {
      HomeLayout,
      PlainLayout,
    },
    created() {
      if (this.isLoggedIn) {
      // eslint-disable-next-line quote-props
      this.$gtag.set({
        // eslint-disable-next-line quote-props
        'program_id': this.currentProgramId,
      })
      // eslint-disable-next-line quote-props
      // this.$gtag.customMap({ 'current_program_id': 'program_id' })
      // this.$gtag.event('track_current_program_id', { program_id: this.currentProgramId })
      }
    },

    computed: {
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'isUserLoggedIn',
      ]),
      type() {
        return this.$route.meta.layout
          || this.$route.matched
              .reduce((acc, { meta }) => meta?.layout || acc, '')
      },
    },
  }
</script>
