<template>
  <fieldset>
    <base-legend v-bind="{ id, isHidden, legend }" >
      <slot name="legend" />
    </base-legend>

    <slot />
  </fieldset>
</template>

<script>
  import BaseLegend from '@/components/base/BaseLegend'

  export default {
    components: {
      BaseLegend,
    },

    props: {
      id: {
        type: String,
        default: '',
      },
      isHidden: {
        type: Boolean,
        default: false,
      },
      legend: {
        type: String,
        default: '',
      },
    },
  }
</script>
