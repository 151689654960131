<template>
  <base-main
    class="getting-support-results"
    data-testid="getting-support-results"
    :loading="$options.loading"
  >
    <container
      v-if="result"
      first
      flex-column
      plugin-article>
        <div>
          <plugin-article
            has-background
            :img="'quiz/getting-support-quiz.png'"
            :testid="`getting-support-results`"
          >
            <template slot="content">
              <ul>
                <li
                  v-for="(item, i) in result"
                  :key="i">
                    <h2>{{ $t(item.type) }}</h2>
                    <p>{{ $t(item.feedback) }}</p>
                </li>
              </ul>
            </template>
          </plugin-article>
        </div>

        <div class="getting-support-results-return-buttons">
          <base-button
            align-end
            data-testid="getting-support-results-finish-button"
            :link="{ name: 'Tools' }"
          >
            {{ $t('Finish') }}
          </base-button>
        </div>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import results from '@/data/getting-support-quiz/gettingSupportQuizResults'

  import capitalize from '@/filters/capitalize'

  import unblockPlugin from '@/mixins/unblockPlugin'

  const pluginName = 'gettingSupportQuiz'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.getting-support-quiz.getting-support-results.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()
      this.fetchAnswers(pluginName)
        .then(() => {
          this.answers
            ? this.getResult()
            : this.$router.replace({ name: capitalize(pluginName) })
        })
    },

    data() {
      return {
        result: null,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      getResult() {
        this.result = results
                      .reduce((acc, { feedback, rules, type }) => {
                        const id = Object.keys(rules).find(key => (
                                    rules[key].every(this.checkRule)
                                  ))

                        return [...acc, {
                          feedback: feedback[id],
                          type,
                        }]
                      }, [])
      },
      checkRule([ids, symbol, sum]) {
        const equations = {
          '>': x => x > sum,
          '<': x => x < sum,
        }

        return equations[symbol](ids.reduce((acc, curr) => acc + this.answers[curr], 0))
      },
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],
  }
</script>

<style lang="scss">
  .getting-support-results {
    &-return-buttons {
      margin-left: auto;
    }
  }
</style>
