<script>
  import SimpleTile from '@/components/tiles/simple-tile'

  import requireImage from '@/helpers/requireImage'

  export default {
    functional: true,

    props: {
      hasOtherPrograms: {
        type: Boolean,
        default: true,
      },
      isCurrent: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        required: true,
      },
      tag: {
        type: String,
        default: 'div',
      },
    },

    render(h, { data, parent, props: { isCurrent, item, hasOtherPrograms, tag } }) {
      const src = item.logo?.url || requireImage('nd-logo.png')
      const ariaLabel = `${isCurrent
        ? parent.i18n('current-programme')
        : parent.i18n('also-available')
      } - ${item.name} ${item.description}${hasOtherPrograms
        ? ''
        : `. ${parent.i18n('not-available')}`}`

      return (
        <SimpleTile
          tag={tag}
        >
          <div
            aria-label={ariaLabel}
            class="navigation-drawer-item"
            {...data}
          >
            <span class="navigation-drawer-item__image">
              <img
                class="navigation-drawer-item__img"
                alt=""
                src={src}
              />
            </span>

            <span class="navigation-drawer-item__details">
              <span
                class="navigation-drawer-item__name"
                style={{ color: item.color }}
              >
                {item.name}
              </span>

              <span class="navigation-drawer-item__description">
                {item.description}
              </span>
            </span>
          </div>
        </SimpleTile>
      )
    },
  }
</script>

<style lang="scss">
  .navigation-drawer-item {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    &__image {
      flex-shrink: 0;
      width: 3rem;
      height: 2.8rem;
      margin: 0 0.6rem 0 0;
      @include min-lg {
        width: 4rem;
        height: 3.5rem;
      }
    }
    &__img {
      height: 100%;
      object-fit: cover;
    }
    &__details {
      display: flex;
      flex-direction: column;
    }
    &__name {
      margin: 0;
      color: color(primary);
      font-size: 1.4rem;
      font-weight: 800;
      @include min-lg {
        font-size: 1.8rem;
      }
    }
    &__description {
      font-size: 0.9rem;
      @include min-lg {
        margin: 0.3rem 0 0 0;
        font-size: 1.3rem;
      }
    }
  }
</style>
