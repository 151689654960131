<script>
  import Tag from '@/components/layout/tag/Tag'

  export default {
    functional: true,

    props: {
      link: {
        type: Object,
        required: false,
        default: null,
      },
    },

    render(h, { children, data, props }) {
      return h('li', { staticClass: 'dropdown-menu-item', ...data }, [
        h(Tag, {
          staticClass: 'dropdown-menu-item-container',
          props: {
            tag: props.link ? 'router-link' : 'button',
          },
          attrs: {
            tabindex: 0,
            to: props.link ? props.link : null,
          },
        }, children),
      ])
    },
  }
</script>

<style lang="scss">
  $block: 'dropdown-menu';

  .dropdown-menu {
    &-item {
      padding: 0;
      visibility: hidden;
      transition: opacity 0.4s cubic-bezier(0.25,0.8,0.5,1);
      opacity: 0;
      &--is-visible {
        visibility: visible;
        opacity: 1;
      }
    }
    &-item-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 4.8rem;
      padding: 0 1.6rem;
      position: relative;
      color: color(primary-text);
      font-size: 1.5rem;
      font-weight: 400;
      text-align: left;
      cursor: pointer;
      background-color: color(_white);
      &:hover {
        @media all and (min-width: map-get($breakpoints, lg)) {
          background-color: color(light-primary);
        }
      }
      &:focus {
        z-index: z-index(over-all);
      }
    }
  }
  .is-tab {
    .dropdown-menu-item {
      a,
      button,
      [tabindex] {
        &:focus {
          box-shadow: inset 0 0 0 5px color(accent);
        }
      }
    }
  }
</style>
