<template>
  <base-main
    class="temptations-results"
    data-testid="temptations-results"
    :loading="$options.loading"
  >
      <container
        first
        flex-column
        plugin-article>

          <div>
            <plugin-article
              has-background
              :img="'quiz/winning-against-temptations.png'"
              :testid="'temptations-results'"
            >
                <template slot="title">
                  {{ $t('Winning Against Temptations') }}
                </template>

                <template slot="content">
                  <div class="temptations-results__answers">
                    <div class="temptations-results__title">
                      {{ $t(`${$options.slug}.title`) }}
                    </div>
                    <ul
                      data-testid="temptations-results-answers"
                    >
                      <li
                        v-for="(answer, i) in answers"
                        :key="i"
                        data-testid="temptations-results-answers-item"
                      >
                        {{ answer.value }}
                      </li>
                    </ul>
                    <p>{{ $t(`${$options.slug}.content`) }}</p>
                    <base-button
                      class="temptations-results__edit"
                      align-start
                      data-testid="temptations-results-edit-button"
                      :link="{ name: 'TemptationsQuiz', params: { isEdit: true } }"
                    >
                        {{ $t('Edit') }}
                    </base-button>
                  </div>
                </template>
            </plugin-article>
          </div>

          <div class="temptations-results__return-buttons">
            <base-button
              align-end
              data-testid="temptations-results-rerun-button"
              :link="{ name: 'TemptationsQuiz', params: { isRerun: true } }"
            >
                {{ $t('Rerun') }}
            </base-button>

            <base-button
              align-end
              data-testid="temptations-results-finish-button"
              :link="{ name: 'Tools' }"
            >
              {{ $t('Finish') }}
            </base-button>
          </div>
      </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import unblockPlugin from '@/mixins/unblockPlugin'

  const pluginName = 'winningAgainstTemptations'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.temptations-quiz.temptations-results.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()

      if (this.answers) return

      this.fetchAnswers(pluginName)
        .then(() => {
          if (this.answers) {
            this.$router.replace({ name: 'TemptationsQuiz' })
          }
        })
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
    },

    slug: 'views.plugins.temptations-quiz.temptations-results',

    loading: [
      `${pluginName}/fetchAnswers`,
    ],
  }
</script>

<style lang="scss">
  .temptations-results {
    &__answers {
      margin: 0.7rem 0 0;
    }
    &__title {
      color: color(dark-primary);
      font-weight: 600;
      text-decoration: underline;
    }
    &__return-buttons {
      margin-left: auto;
    }
    &__edit {
      margin: 2rem 0 0 0;
    }
    .plugin-content {
      display: flex;
    }
    ul {
      list-style-type: disc;
      padding: 0.5rem 0 1rem 2rem;
    }
  }
</style>
