<template>
  <base-main
    class="food-tracking"
    data-testid="food-tracking"
    :loading="$options.loading"
  >
    <container first>
      <div class="food-tracking__layout">
        <div
          class="food-tracking__goals"
          data-testid="food-tracking-goals"
        >
          <div
            v-for="(goal, i) in goals"
            :key="i"
            class="food-tracking__goal"
          >
            <modular-tile
              data-testid="food-tracking-goal"
              :headerText="goal.title"
              :image="requireImage('modular-tile/top-tracking-goal.jpg')"
              :imagePosition="'top'"
            >
              <p
                v-if="!goal.value && goal.smallExtraDescription"
                class="food-tracking__goal-description"
              >
                {{ goal.smallExtraDescription }}
              </p>

              <p
                v-if="goal.value"
                data-testid="food-tracking-goal-value"
              >
                {{ goal.value }}
              </p>

              <template #action>
                <base-button
                  class="anchor food-tracking-anchor"
                  data-testid="food-tracking-goal-button"
                  :link="goal.link"
                >
                      {{ renderText($t(goalButtonText(goal), { goalName: goal.title })) }}
                </base-button>
              </template>
            </modular-tile>
          </div>
        </div>

        <div
          class="food-tracking-dashboard"
          data-testid="food-tracking-dashboard"
        >
          <simple-tile>
            <div class="food-tracking-dashboard">
              <div
                class="food-tracking-dashboard__actions"
                data-testid="food-tracking-dashboard-actions"
              >

                <div class="food-tracking-dashboard__log-desktop">
                  <base-button
                  class="food-tracking-anchor"
                    no-margin
                    data-testid="food-tracking-open-dialog"
                    @click="foodLog(null)">
                      {{ $t(`${$static.rootSlug}.log-or`) }}
                  </base-button>
                </div>

                <div class="food-tracking-dashboard__datepicker">
                  <h1
                    class="food-tracking-dashboard__heading"
                    data-testid="food-tracking-dashboard-heading"
                  >
                    {{ $t(`${$static.rootSlug}.log-and`) }}
                  </h1>

                  <div class="food-tracking-dashboard__log-mobile">
                    <base-button
                      no-margin
                      data-testid="food-tracking-open-dialog"
                      @click="foodLog(null)">
                        {{ $t(`${$static.rootSlug}.log-or`) }}
                    </base-button>
                  </div>

                  <div
                    class="food-tracking-dashboard__datepicker-layout"
                    data-testid="food-tracking-dashboard-date"
                  >

                    <tracking-datepicker
                      data-testid="food-tracking-dashboard-date-picker"
                      :endDate="selectedDate"
                      :period="'day'"
                      v-bind="{ minDate, maxDate, format }"
                      v-model="selectedDate"
                    />

                  </div>
                </div>
                <div class="food-tracking-dashboard__today">
                  <base-button
                    bordered
                    no-margin
                    small
                    data-testid="food-tracking-dashboard-date-today"
                    @click="setToday">
                      {{ $t('Today') }}
                  </base-button>
                </div>
              </div>

              <div
                v-if="isFetchingFoods"
                class="food-tracking-dashboard__spinner">
                  <loading-indicator inline/>
              </div>

              <div
                v-else-if="foodsSorted && foodsSorted.length"
                class="food-tracking-dashboard__list"
                data-testid="food-tracking-food-list"
              >
                <button
                  v-for="(food, i) in foodsSorted"
                  :key="i"
                  class="food-tracking-item"
                  data-testid="food-tracking-food"
                  type="button"
                  :aria-label="`${$t('Logged food or drink')} ${food.description || ''}`"
                >
                  <div :class="photoElementClasses('food-tracking-item__photo', food)">
                    <!-- eslint-disable-next-line vuejs-accessibility/alt-text -->
                    <img
                      v-if="food.files"
                      class="food-tracking-item__img"
                      :alt="`${$t('Logged food or drink')} ${food.description || ''}`"
                      :aria-describedby="`food-description-${i}`"
                      :src="food.files.url">
                    <inline-svg
                      v-else
                      role="presentation"
                      src="icons/icon-image.svg"/>
                    <div class="food-tracking-item__photo-buttons">
                      <base-button
                        primary
                        small
                        data-testid="food-tracking-dashboard-edit"
                        @click="foodLog(food.id)">
                          {{ $t('Edit') }}
                      </base-button>
                      <base-button
                        danger
                        small
                        data-testid="food-tracking-dashboard-delete"
                        @click="deleteFood(food.id)">
                          {{ $t('Delete') }}
                      </base-button>
                    </div>
                  </div>

                  <p
                    :id="`food-description-${i}`"
                    class="food-tracking-item__description"
                    data-testid="food-tracking-food-description"
                  >
                    {{ food.description }}
                  </p>
                  <p
                    :id="`food-date-${i}`"
                    class="food-tracking-item__date"
                    data-testid="food-tracking-food-date"
                  >
                    {{ getTime(food) }}
                  </p>
                </button>
              </div>

              <div
                v-else
                class="food-tracking-dashboard__empty"
                data-testid="food-tracking-dashboard-empty"
              >
                <p>
                  {{ $t(`${$static.rootSlug}.empty`) }}
                </p>
              </div>
            </div>
          </simple-tile>
        </div>
      </div>
    </container>
  </base-main>
</template>

<script>
  import { capitalize } from 'lodash'
  import moment from 'moment'

  import { mapActions, mapGetters, mapState } from 'vuex'

  import {
    BaseButton,
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'
  import LoadingIndicator from '@/components/loading-indicator'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'
  import SimpleTile from '@/components/tiles/simple-tile'

  import {
    TrackingDatepicker,
  } from '@/components/tracking'

  import { DATE_FORMATS } from '@/constants'

  import requireImage from '@/mixins/requireImage'
  import shouldFetch from '@/mixins/shouldFetch'
  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      const { name: title } = this.currentPlugin || {}

      return {
        title,
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      LoadingIndicator,
      ModularTile,
      SimpleTile,
      TrackingDatepicker,
    },

    mixins: [
      requireImage,
      shouldFetch,
      unblockPlugin,
    ],

    created() {
      const programId = this.currentProgramId
      const triggerDataId = this.getTriggerDataIdByPluginName('goal')
      const userId = this.getCurrentUserId

      this.unblockPlugin()
      this.setToday()
      this.fetchFoods(this.selectedDate)
      this.fetchPlugin({
        triggerDataId,
        userId,
      })
      this.fetchGoals({
        programId,
        userId,
      })
    },

    beforeDestroy() {
      this.closeDialog()
    },

    watch: {
      '$route.name': {
        handler(newValue) {
          if (newValue == 'FoodTrackingNew') {
            this.foodLog(null)
          }
        },
        immediate: true,
      },
      selectedDate() {
        this.fetchFoods(this.selectedDate)
      },
    },

    data() {
      return {
        DATE_FORMATS,
        selectedDate: '',
        format: 'YYYY-MM-DDTHH:mm:ssZ',
      }
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
        'openDialog',
      ]),
      ...mapActions('foodTracking', [
        'fetchFoods',
      ]),
      ...mapActions('goals', [
        'fetchPlugin',
        'fetchGoals',
      ]),
      changeDate(direction) {
        if (direction == 'next' && this.isToday) return
        if (direction == 'back' && this.isTriggerUnlockDate) return

        const value = direction == 'next' ? 1 : -1

        this.selectedDate = moment
          .utc(this.selectedDate, this.format)
          .utcOffset(this.getTimezoneOffset)
          .add(value, 'days')
          .format(this.format)
      },
      getToday() {
        return moment.utc().utcOffset(this.getTimezoneOffset).format(this.format)
      },
      setToday() {
        this.selectedDate = this.getToday()
      },
      foodLog(foodId) {
        if (foodId) {
          this.$router.push({ name: 'FoodTrackingLog', params: { id: `${foodId}` } })
        } else {
          this.$router.push({ name: 'FoodTrackingLog', params: { id: null, date: this.selectedDate } })
        }
      },
      deleteFood(foodId) {
        this.$router.push({ name: 'FoodTrackingDelete', params: { id: `${foodId}` } })
      },
      photoElementClasses(baseClass, { files, label_foods }) {
        /* eslint-disable camelcase */
        return `${baseClass}
          ${baseClass}--${label_foods}
          ${files ? '' : `${baseClass}--empty`}`
        /* eslint-enable camelcase */
      },
      goalButtonText({ link: { params } }) {
        const isProfileGoal = !!params.profileId

        return isProfileGoal
          ? `${this.$static.rootSlug}.add-food`
          : `${this.$static.rootSlug}.edit-food`
      },
      renderText(text) {
        return capitalize(text)
      },
      getTime(food) {
        return moment(food.date).format(DATE_FORMATS.time)
      },
    },

    computed: {
      ...mapGetters('goals', [
        'getProfilesByGoalTypeId',
        'getGoalByProfileId',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
        'isPackageOrProgramOrUserLoading',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('triggers', [
        'getTriggerBySlug',
        'getTriggerDataIdByPluginName',
        'getTriggerUnlockDateByPluginName',
        'hasTriggers',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'getTimezoneOffset',
      ]),
      ...mapState('foodTracking', [
        'foods',
      ]),
      ...mapState('user', [
        'user',
      ]),
      foodsSorted() {
        return this.foods?.sort((a, b) => moment(a.date) - moment(b.date))
      },
      goals() {
        return this.getProfilesByGoalTypeId(3)
          .map(item => {
            const goal = this.getGoalByProfileId(item.id)
            const isGoalProfile = !goal
            const { id } = isGoalProfile ? item : goal
            const link = isGoalProfile
              ? { name: 'GoalsAddGoal', params: { profileId: id } }
              : { name: 'GoalsEditGoal', params: { id } }

            return {
              link,
              title: item.title,
              smallExtraDescription: item.smallExtraDescription,
              value: goal?.answers?.[0]?.content?.[0],
            }
        })
      },
      isFetchingFoods() {
        return this.getLoadingStatesForActions([
          'foodTracking/fetchFoods',
        ])
      },
      isToday() {
        return moment.parseZone(this.selectedDate).isSame(moment.parseZone(this.getToday()), 'day')
      },
      isTriggerUnlockDate() {
        return this.selectedDate == this.triggerUnlockDate
      },
      triggerUnlockDate() {
        if (!this.hasTriggers) return

        return moment.utc(this.getTriggerUnlockDateByPluginName('food')).utcOffset(this.getTimezoneOffset).format(this.format)
      },
      minDate() {
        return moment.parseZone(this.triggerUnlockDate).format(this.format)
      },
      maxDate() {
        return moment.utc().utcOffset(this.getTimezoneOffset).format(this.format)
      },
      currentTrigger() {
        return this.getTriggerBySlug('food-tracking')
      },
      currentPlugin() {
        return this.currentTrigger?.plugin
      },
    },

    static() {
      return {
        rootSlug: 'views.plugins.food-tracking.food-tracking',
      }
    },

    loading: [
      'foodTracking/fetchFoods',
      'goals/fetchGoals',
      'goals/fetchPlugin',
    ],
  }
</script>

<style lang="scss">
  .food-tracking {
    max-width: none;
    &__layout {
      @include min-lg {
        display: grid;
        grid-column-gap: 3rem;
        grid-template-columns: 27rem 1fr;
      }
    }
    &__goals {
      @include min-lg {
        width: 27rem;
        margin: 0 3rem 0 0;
      }
    }
    &__goal {
      margin: 0 0 2rem;
      .modular-tile {
        &__main {
          @include max-lg {
            padding: 2rem;
          }
        }
        &__header-row {
          @include max-lg {
            align-items: flex-start;
            min-height: 3rem;
          }
        }
        &__top-image-row {
          @include max-lg {
            display: none;
          }
        }
      }
      &-description {
        margin-top: -0.75rem;
      }
      .button {
        margin: 1rem 0 0;
        @include min-lg {
          margin: 0;
          padding: 2px 1.8rem 0;
        }
      }
    }
    .container {
      @include min-lg {
        width: 120rem;
      }
    }
  }
  .food-tracking-dashboard {
    width: 100%;
    margin: 0 auto;
    @include min-lg {
      flex-grow: 1;
    }
    &__actions {
      @include min-lg {
        display: grid;
        grid-template-columns: 0.3fr 0.4fr 0.3fr;
        padding: 0 0 2rem;
        border-bottom: 1px solid color(light-grey);
      }
    }
    &__log-desktop {
      display: none;
      @include min-lg {
        display: block;
      }
    }
    &__log-mobile {
      display: block;
      margin: 1rem 0 0;
      @include min-lg {
        display: none;
      }
    }
    &__arrow-button {
      width: 2.4rem;
      height: 2.4rem;
    }
    &__arrow {
      width: 1.6rem;
      height: auto;
      &--right {
        transform: rotate(180deg);
      }
    }
    &__datepicker-layout {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem 0 0;
    }
    &__datepicker {
      justify-self: center;
      width: 100%;
      max-width: 32rem;
      margin: 0 auto;
      text-align: center;
      @include min-lg {
        margin: 0;
      }
    }
    &__heading {
      margin: 0.5rem 0 0;
      font-size: 1.8rem;
      font-weight: 800;
      @include min-lg {
        font-size: 2.2rem;
      }
    }
    &__today {
      display: none;
      align-self: end;
      justify-self: end;
      @include min-lg {
        display: block;
      }
      .button {
        min-width: 8.8rem;
      }
    }
    &__list {
      display: grid;
      grid-column-gap: 3rem;
      grid-row-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 3rem;
      @include min-sm {
        grid-template-columns: repeat(3, 1fr);
      }
      @include min-lg {
        grid-template-columns: repeat(4, 1fr);
      }
      @include min-xl {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 16rem;
      text-align: center;
      p {
        display: block;
        margin: 0;
      }
    }
    &__spinner {
      min-height: 16rem;
      position: relative;
    }
    .simple-tile__main {
      @include min-lg {
        padding: 3.5rem;
      }
    }
  }
  .food-tracking-item {
    text-align: center;
    &__photo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 10rem;
      margin: 0 auto;
      overflow: hidden;
      border-style: solid;
      border-radius: 50%;
      @include min-sm {
        width: 13.5rem;
        height: 13.5rem;
      }
      svg {
        width: 5rem;
        height: 5rem;
        fill: color(grey);
      }
      &--empty {
        border-width: 1px;
        border-color: color(primary);
      }
      &--red {
        border-width: 2px;
        border-color: color(food-red);
      }
      &--green {
        border-width: 2px;
        border-color: color(food-green);
      }
      &-buttons {
        display: none;
      }
      &:hover {
        .food-tracking-item__photo-buttons {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            min-width: 8rem;
          }
        }
      }
    }
    &__img {
      height: 100%;
      object-fit: cover;
    }
    &__description {
      margin: 1rem 0 0;
    }
    &__date {
      color: color(dark-grey);
    }
  }

  .food-tracking-anchor:focus-visible{
    box-shadow: 0 -2px #ffeb3b, 0 4px #212b32 !important;
  }
</style>
