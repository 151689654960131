var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({staticClass:"checkbox-multi-group",attrs:{"data-testid":"checkbox-multi-group","label":_vm.legend},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.hasLegend)?_c('base-legend',{attrs:{"data-testid":"checkbox-multi-group-legend","isHidden":_vm.isLegendHidden,"legend":_vm.labelText}},[_vm._t("legend")],2):_vm._e(),_vm._l((_vm.options),function(option,i){return _c('base-checkbox',_vm._b({key:i,attrs:{"is-multi":"","ariaInvalid":!!errors.length,"inputValue":_vm.extractValue(option, _vm.valueKey),"label":_vm.extractValue(option, _vm.labelKey),"descriptionId":_vm.describedBy},scopedSlots:_vm._u([{key:"option",fn:function(){return [_vm._t("option",null,null,{ option: option })]},proxy:true},{key:"default",fn:function(){return [_vm._t("default",null,null,{ option: option })]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'base-checkbox',{ name: _vm.name },false))})]}}],null,true)},'form-group',{
    hideErrorMessage: _vm.hideErrorMessage,
    name: _vm.name,
    tag: _vm.tag,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }