export default [
  {
    type: 'data.getting-support-quiz.getting-support-quiz-results.type-1',
    feedback: {
      1: 'data.getting-support-quiz.getting-support-quiz-results.feedback-1',
      2: 'data.getting-support-quiz.getting-support-quiz-results.feedback-2',
      3: 'data.getting-support-quiz.getting-support-quiz-results.feedback-3',
    },
    rules: {
      1: [[[1, 2, 3, 6], '<', 10]],
      2: [[[1, 2, 3, 6], '<', 14], [[1, 2, 3, 6], '>', 9]],
      3: [[[1, 2, 3, 6], '>', 13]],
    },
  },
  {
    type: 'data.getting-support-quiz.getting-support-quiz-results.type-2',
    feedback: {
      1: 'data.getting-support-quiz.getting-support-quiz-results.feedback-4',
      2: 'data.getting-support-quiz.getting-support-quiz-results.feedback-5',
    },
    rules: {
      1: [[[4, 5], '<', 5]],
      2: [[[4, 5], '>', 4]],
    },
  },
  {
    type: 'data.getting-support-quiz.getting-support-quiz-results.type-3',
    feedback: {
      1: 'data.getting-support-quiz.getting-support-quiz-results.feedback-6',
      2: 'data.getting-support-quiz.getting-support-quiz-results.feedback-7',
      3: 'data.getting-support-quiz.getting-support-quiz-results.feedback-8',
    },
    rules: {
      1: [[[7, 8, 9, 13], '>', 13]],
      2: [[[7, 8, 9, 13], '<', 14], [[7, 8, 9, 13], '>', 9]],
      3: [[[7, 8, 9, 13], '<', 10]],
    },
  },
  {
    type: 'data.getting-support-quiz.getting-support-quiz-results.type-4',
    feedback: {
      1: 'data.getting-support-quiz.getting-support-quiz-results.feedback-9',
      2: 'data.getting-support-quiz.getting-support-quiz-results.feedback-10',
    },
    rules: {
      1: [[[10, 11, 12], '<', 7]],
      2: [[[10, 11, 12], '>', 6]],
    },
  },
]
