<template>
  <base-main
    data-testid="getting-support"
  >
    <container
      first
      flex-column
      plugin-article>

        <plugin-article
          :img="'quiz/getting-support-quiz.png'">
            <template slot="title">
              {{ $t('The family support quiz') }}
            </template>
            <template slot="content">
              <p>{{ $t('views.plugins.getting-support-quiz.getting-support.paragraph-1') }}</p>
              <p>{{ $t('views.plugins.getting-support-quiz.getting-support.paragraph-2') }}</p>
            </template>
        </plugin-article>

      <base-button
        align-end
        data-testid="getting-support-next-button"
        :link="{ name: 'GettingSupportQuiz' }"
      >
          {{ $t('Next') }}
      </base-button>

    </container>
  </base-main>
</template>

<script>
  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.getting-support-quiz.getting-support.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },
  }
</script>
