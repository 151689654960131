<script>
  import { BaseHeader } from '@/components/base'
  import NavigationDrawer from '@/components/layout/navigation-drawer/NavigationDrawer'
  import SubTabs from '@/components/layout/sub-tabs/SubTabs'

  import TransitionSiblings from '@/transitions/transition-siblings/TransitionSiblings'

  export default {
    functional: true,

    render(h, { children, parent }) {
      const subTabs = {
        'coaching-plugin': [
          {
            icon: 'icons/icon-user',
            name: parent.$t('Appointments'),
            link: 'CoachingPlugin',
          },
          {
            icon: 'icons/icon-file',
            name: parent.$t('From your coach'),
            link: 'CoachingPluginContent',
          },
        ],
        'face-age-test': [
          {
            icon: 'camera1',
            name: 'Camera',
            link: 'FaceAgeTest',
          },
          {
            icon: 'results',
            name: 'Results',
            link: 'FaceAgeTestResults',
          },
        ],
      }

      const plugin = Array.isArray(parent.$route.meta.trigger)
        ? parent.$route.meta.trigger[0]
        : parent.$route.meta.trigger

      const renderSubTabs = parent.$route.meta.hasTabs
        ? h(SubTabs, { props: { items: subTabs[plugin], hasPortal: true } })
        : false

      return [
        h(BaseHeader),
        h(NavigationDrawer),
        renderSubTabs,
        h(TransitionSiblings, { props: { component: 'home' } }, children),
      ]
    },
  }
</script>
