 <template>
  <div
    class="initial-assessment"
    data-testid="initial-assessment-step"
  >
    <p
      v-if="data.description"
      class="initial-assessment__description"
      data-testid="initial-assessment-step-description"
    >
      {{ $t(data.description) }}
    </p>

    <component
      :is="wrapperType"
    >
      <legend
        v-if="data.legend"
        v-html="$t(data.legend)"
        data-testid="initial-assessment-step-legend"
      />

      <div
        v-for="(question, i) in data.questions"
        :key="i"
        class="initial-assessment__question"
        :class="{ 'initial-assessment__question--with-radio': isCheckboxOrRadio(question.type) }"
        data-testid="initial-assessment-step-question"
      >
        <base-fieldset
          v-if="isCheckboxOrRadio(question.type)"
          data-testid="initial-assessment-step-question-multi-fieldset"
          :id="getId(i)"
        >
          <template #legend>
            <span
              v-if="question.legend"
              class="initial-assessment__legend"
              :class="{
                'initial-assessmen__legend--is-thin': question.legendIsThin,
              }"
              data-testid="initial-assessment-step-question-multi-legend"
              v-html="getQuestionLegendText($t(question.legend), getValidation(question))"
            />
            <span
              v-if="question.helper"
              class="initial-assessment__helper"
              data-testid="initial-assessment-step-question-multi-helper"
              v-html="$t(question.helper)"
            />
          </template>

          <component
            v-for="(field, j) in question.fields"
            :key="j"
            isLegendHidden
            data-testid="initial-assessment-step-question-multi-field"
            :is="question.type"
            :describedBy="getId(i)"
            :hideErrorMessage="!isLast(question, j)"
            :legend="$t(field.legend || question.legend)"
            :name="getName(question, j)"
            :options="field.options"
            :validation="getValidation(question)"
            v-model="form[question.name]"
          >
            <template
              #default="{ option }"
            >
              <initial-assessment-child-input
                v-for="(child, k) in option.children"
                :key="k"
                data-testid="initial-assessment-step-question-multi-child-input"
                :parentValue="option.value"
                :questionValue="form[question.name]"
                v-bind="child"
                v-model="form[child.name]"
              />
            </template>
          </component>
        </base-fieldset>

        <component
          v-else
          class="initial-assessment__input"
          data-testid="initial-assessment-step-question-input"
          :is="question.legend ? 'fieldset' : 'div'"
        >
          <legend
            v-if="question.legend"
            class="initial-assessment__legend initial-assessment__legend--input"
            data-testid="initial-assessment-step-question-input-legend"
            v-html="$t(question.legend)"
          />

          <input-group
            data-testid="initial-assessment-step-question-input-field"
            :helper="question.helper"
            :label="$t(question.label)"
            :name="question.name"
            :tag="data.legend || question.legend ? 'div' : 'fieldset'"
            :type="'text'"
            :validation="getValidation(question)"
            v-model="form[question.name]"
          />
        </component>
      </div>
    </component>
  </div>
</template>

<script>
  import BaseFieldset from '@/components/base/BaseFieldset'
  import { CheckboxMultiGroup } from '@/components/forms/checkbox-group'
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import { RadioMultiGroup } from '@/components/forms/radio'

  import InitialAssessmentChildInput from './InitialAssessmentChildInput'

  export default {
    components: {
      CheckboxMultiGroup,
      BaseFieldset,
      FormGroup,
      InitialAssessmentChildInput,
      InputGroup,
      RadioMultiGroup,
    },

    props: {
      data: {
        type: Object,
        required: true,
      },
      form: {
        type: Object,
        required: true,
      },
    },

    methods: {
      isCheckboxOrRadio(type) {
        return ['checkbox-multi-group', 'radio-multi-group'].includes(type)
      },
      getCustomValue(type, { customValue, hasInput, name, option, text }) {
        if (type == 'radio-multi-group') {
          return customValue || option
        }

        return name && !hasInput ? name : text
      },
      getFieldTag(length) {
        return length > 1 ? 'fieldset' : 'div'
      },
      getValidation({ validation }) {
        return { required: true, ...validation }
      },
      getId(i) {
        return `initial_assessment_legend_${this.uuid}_${i}`
      },
      getName(question, i) {
        const { fields = [], name } = question

        return fields.length > 1 ? `${name}_${i}` : name
      },
      getQuestionLegendText(legend, { required }) {
        return `${legend}${required ? ` (${this.$t('required')})` : ''}`
      },
      isLast({ fields = [] }, i) {
        return fields.length === (i + 1)
      },
    },

    computed: {
      wrapperType() {
        return this.data.legend ? 'fieldset' : 'div'
      },
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
    },
  }
</script>

<style lang="scss">
  .initial-assessment {
    &__description {
      padding: 0 0 1.5rem;
      color: color(dark-primary);
    }
    &__legend {
      display: block;
      font-weight: 600;
      &--input {
        margin: 0;
      }
    }
    &__helper {
      display: block;
    }
    &__option {
      margin: 1rem 0;
      &:first-of-type {
        margin: 0 0 1rem;
      }
      &-text {
        font-weight: 600;
      }
      &-input {
        padding-left: 3.2rem;
      }
    }
    &__input {
      margin: 0 0 1.5rem;
    }
    &__question {
      .radio-multi-group:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
    &__question--with-radio {
      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
    .radio-multi-group,
    .checkbox-multi-group {
      margin-top: 0;
    }
    .base-checkbox,
    .base-radio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .base-checkbox__container {
      width: 100%;
    }
    .initial-assessment-child-input {
      .input-details--has-error {
        margin-top: -1px;
      }
    }
  }
</style>
