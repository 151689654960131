<template>
  <base-main
    class="carb-calculator"
    data-testid="carb-calculator"
  >
      <container
        stepper
      >
          <stepper-custom
            ref="form"
            v-bind="{ isNextDisabled }"
            :buttonLabels="{ finishLabel: 'Done' }"
            :testid="'carb-calculator'"
            @cancel="redirectToTools"
            @finish="redirectToTools"
            @next="onChange"
            @previous="onChange"
            @last-step-accessed="onLastStepAccessed"
          >
            <stepper-custom-step
              ref="form"
              data-testid="carb-calculator-stepper-custom-step"
              :title="$static.intro.title"
              :useTitleNumeration="false"
            >
              <div class="carb-calculator__intro">
                <img
                  alt=""
                  class="carb-calculator__image"
                  src="@/assets/img/birb.jpg"
                >
                <div
                  data-testid="carb-calculator-content"
                >
                  <p
                    v-for="(paragraph, i) in $static.intro.content"
                    :key="i"
                  >
                    {{ $t(paragraph) }}
                  </p>
                  <p>
                    <b>{{ $t($static.intro.callToAction) }}</b>
                  </p>
                </div>
              </div>
            </stepper-custom-step>
            <stepper-custom-step
              v-for="(category, i) in $static.items.categoriesWithItems"
              :key="i"
              data-testid="carb-calculator-stepper-custom-step"
              :thisStep="i + 1"
              :title="category.title"
              :useTitleNumeration="false"
              :disableTransitionEffect="true"
              v-bind="{ currentStep, maxStep }"
            >
              <div
                role="radiogroup"
                data-testid="carb-calculator-answers"
              >
                <grid>
                  <grid-column
                    v-for="item in category.items"
                    :key="item.id"
                    class="carb-calculator__column"
                    xs-12
                    sm-6
                  >
                    <plugin-item
                      :data-testid="'carb-calculator-answers'"
                      :isSelected="isSelected(category.name, item.id)"
                      :isSvg="false"
                      :type="'radio'"
                      v-bind="{ item }"
                      @click="setSelected(category.name, item)"
                      @keydown.prevent.enter.space="setSelected(category.name, item)"
                    />
                  </grid-column>
                </grid>
              </div>
            </stepper-custom-step>
            <stepper-custom-step
              data-testid="carb-calculator-stepper-custom-step"
              :title="$static.results.title"
              :useTitleNumeration="false"
            >
              <div class="carb-calculator__results">
                <simple-tile>
                  <rounded-doughnut-chart
                    v-if="currentStep == maxStep"
                    class="carb-calculator__chart"
                    data-testid="carb-calculator-rounded-doughnut-chart"
                    :chartData="chart.data"
                    :options="chart.options"
                    :title="$t('views.plugins.carb-calculator.chart-title')"
                  >
                    <template v-slot="{ i, item }">
                      {{ `${$t(chart.data.labels[i])} - ${getPercentage(item)} (${item}g)` }}
                    </template>
                  </rounded-doughnut-chart>
                </simple-tile>

                <div
                  data-testid="carb-calculator-results"
                >
                  <h2
                    class="carb-calculator__results-subtitle"
                    data-testid="carb-calculator-results-heading"
                  >
                    {{ $t($static.results.subtitle) }}
                  </h2>
                  <div
                    data-testid="carb-calculator-results-content"
                  >
                    <p>{{ $t($static.results.text[0]) }}</p>
                    <strong>{{ $t($static.results.example) }}</strong>
                    <p>{{ $t($static.results.text[1], {carbsToCut}) }}</p>
                    <p>{{ $t($static.results.text[2]) }}</p>
                  </div>
                </div>
              </div>
            </stepper-custom-step>
          </stepper-custom>
      </container>
  </base-main>
</template>

<script>
  import BaseMain from '@/components/base/BaseMain'
  import RoundedDoughnutChart from '@/components/charts/rounded-doughnut-chart/RoundedDoughnutChart'
  import Container from '@/components/container/Container'
  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import PluginItem from '@/components/plugin/plugin-item/PluginItem'
  import {
    StepperCustom,
    StepperCustomStep,
  } from '@/components/stepper-custom'
  import SimpleTile from '@/components/tiles/simple-tile'

  import data from '@/data/carb-calculator'

  import requireImage from '@/mixins/requireImage'
  import scrollToTop from '@/mixins/scrollToTop'
  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.carb-calculator.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      Grid,
      GridColumn,
      PluginItem,
      RoundedDoughnutChart,
      SimpleTile,
      StepperCustom,
      StepperCustomStep,
    },

    mixins: [
      requireImage,
      scrollToTop,
      unblockPlugin,
    ],

    created() {
      this.createForm()
    },

    watch: {
      carbs(value) {
        this.chart.data.datasets[0].data = value
      },
    },

    data() {
      return {
        chart: {
          data: {
            labels: ['Breakfast', 'Lunch', 'Dinner', 'Snack'],
            datasets: [
              {
                backgroundColor: ['#ffc72c', '#da1884', '#ed8b00', '#13bfd6'],
                hoverBackgroundColor: ['#ffc72c', '#da1884', '#ed8b00', '#13bfd6'],
              },
            ],
          },
        },
        currentStep: 0,
        form: {},
      }
    },

    methods: {
      createForm() {
        this.form = data.items.categories
                      .reduce((acc, { name }) => ({ ...acc, [name]: null }), {})
      },
      getPercentage(item) {
        return `${((item / this.carbsTotal) * 100).toFixed(2)}%`
      },
      isSelected(category, id) {
        return this.form[category]?.id == id
      },
      onChange(currentStep) {
        this.currentStep = currentStep
      },
      redirectToTools() {
        this.$router.push({ name: 'Tools' })
      },
      setSelected(category, item) {
        this.form[category] = item
      },
      onLastStepAccessed() {
        this.scrollToTop()
        this.unblockPlugin()
      },
    },

    computed: {
      carbs() {
        return Object.values(this.form)
                .filter(item => !!item)
                .map(item => item.carbs)
      },
      carbsTotal() {
        return Number(this.carbs.reduce((acc, curr) => acc + curr, 0).toFixed(2))
      },
      carbsToCut() {
        return (this.carbsTotal - 50).toFixed(2)
      },
      categoriesCarbs() {
        return Object.values(this.form)
                .filter(item => !!item)
                .map(item => ({
                  name: item.category,
                  carbs: item.carbs,
                  carbsPercentage: ((item.carbs / this.carbsTotal) * 100).toFixed(2),
                  color: data.items.categories.find(({ name }) => name == item.category).color,
                }
              ))
      },
      isNextDisabled() {
        const { currentStep } = this

        return currentStep != 0
          && currentStep != this.maxStep
          && !this.form[this.chart.data.labels[currentStep - 1].toLowerCase()]
      },
      maxStep() {
        return data.items.categories.length + 1
      },
    },

    static() {
      return {
        ...data,
      }
    },
  }
</script>

<style lang="scss">
  .carb-calculator {
    &__intro {
      display: grid;
      grid-template-columns: 23.2rem 1fr;
      grid-gap: 3rem;
      @include max-sm {
        grid-template-columns: repeat(auto-fit, minmax(23.2rem, 1fr));
      }
    }
    &__image {
      height: 20.4rem;
      border-radius: 1rem;
      object-fit: cover;
    }
    &__grid {
      @include max-sm {
        flex-direction: column;
      }
    }
    &__column {
      display: flex;
      @include max-sm {
        max-width: 100%;
      }
      .plugin-item {
        margin: 0 1rem;
        width: 100%;
        &__label img {
          object-fit: contain;
        }
      }
    }
    &__item {
      flex: 1;
    }
    &__results {
      display: grid;
      grid-template-columns: 26.2rem 1fr;
      grid-gap: 3rem;
      @include max-sm {
        display: flex;
        flex-direction: column;
      }
    }
    &__chart {
      margin: 0 auto;
    }
    &__results-subtitle {
      margin-top: 0;
    }
    p {
      margin: 0.9rem 0;
      line-height: 1.5;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
