const slug = 'data.setting-the-strongest-plan'

export default {
  contents: {
    intro: {
      firstParagraph: `${slug}.first-paragraph`,
      secondParagraph: `${slug}.second-paragraph`,
      situations: [
        `${slug}.situations-1`,
        `${slug}.situations-2`,
        `${slug}.situations-3`,
        `${slug}.situations-4`,
        `${slug}.situations-5`,
        `${slug}.situations-6`,
        `${slug}.situations-7`,
      ],
      thirdParagrah: `${slug}.third-paragraph`,
    },
    planningStep: {
      header: `${slug}.planning-step-header`,
      firstParagraph: `${slug}.planning-step-third-paragraph`,
    },
  },
  coachText: `${slug}.coach-text`,
  steps: [
    {
      name: 'intro',
      showContent: true,
      showCoachText: false,
      showInput: false,
      showAnswer: false,
    },
    {
      name: 'planning',
      showContent: true,
      showCoachText: true,
      showInput: true,
      showAnswer: false,
    },
    {
      name: 'end',
      showContent: false,
      showCoachText: true,
      showInput: false,
      showAnswer: true,
    },
  ],
}
