<template>
  <li
    data-testid="menu-language-switcher"
    v-show="isVisible"
  >
    <div
      class="languages-container"
      :class="{
        'languages-container--is-disabled': isLoading
      }">
        {{ $t(`${this.$options.slug}.change-language`) }}
        <ul
          data-testid="menu-language-switcher-languages"
        >
          <li
            v-for="(language, key) in languages"
            class="language"
            :key="key">
              <language-button
                data-testid="menu-language-switcher-language-button"
                :isDisabled="isLoading"
                :isCurrent="isLanguageCurrent(language)"
                v-bind="{ language }"
                @click="onSwitchLanguage"
              />
          </li>
        </ul>
    </div>
  </li>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import LanguageButton from './LanguageButton'

  export default {
    components: {
      LanguageButton,
    },

    watch: {
      isPackageOrProgramLoading: {
        handler() {
          if (this.isPackageOrProgramLoading) return

          this.fetchLanguages()
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('languages', [
        'fetchLanguages',
        'setUserLanguageInProgram',
      ]),
      ...mapActions('bootstrap', [
        'bootstrap',
      ]),
      onSwitchLanguage({ locale }) {
        this.$emit('languageChanged')

        this.setUserLanguageInProgram({ language: locale })
          .then(() => {
            this.bootstrap()
          })
      },
      isLanguageCurrent(lang) {
        return lang?.locale == this.currentUserLanguage
      },
    },

    computed: {
      ...mapState('languages', [
        'languages',
        'currentUserLanguage',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
        'isPackageOrProgramLoading',
      ]),
      isLoading() {
        return this.getLoadingStatesForActions([
          'languages/setUserLanguageInProgram',
        ])
      },
      isVisible() {
        return this.languages?.length > 1
      },
    },

    slug: 'component.layout.language-switcher',
  }
</script>

<style lang="scss">
  .languages-container {
    display: flex;
    flex-direction: column;
    position: static;
    width: 100%;
    padding: 0 1.6rem;
    font-size: 1.5rem;
    background-color: color(_white);
    &--is-disabled {
      opacity: 0.7,
    }
  }

  .language {
    &:first-child {
      padding-top: 1rem;
    }
    padding: 0.6rem 0;
  }
</style>
