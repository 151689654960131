const data = {
  mobility: {
    name: 'mobility',
    image: '',
    options: [
      'data.eq-5d.mobility-options-1',
      'data.eq-5d.mobility-options-2',
      'data.eq-5d.mobility-options-3',
      'data.eq-5d.mobility-options-4',
      'data.eq-5d.mobility-options-5',
    ],
    title: 'data.eq-5d.mobility-title',
  },
  'self-care': {
    name: 'self-care',
    image: '',
    options: [
      'data.eq-5d.self-care-options-1',
      'data.eq-5d.self-care-options-2',
      'data.eq-5d.self-care-options-3',
      'data.eq-5d.self-care-options-4',
      'data.eq-5d.self-care-options-5',
    ],
    title: 'data.eq-5d.self-care-title',
  },
  'usual-activities': {
    description: 'data.eq-5d.usual-activities-description',
    name: 'usual-activities',
    image: '',
    options: [
      'data.eq-5d.usual-activities-1',
      'data.eq-5d.usual-activities-2',
      'data.eq-5d.usual-activities-3',
      'data.eq-5d.usual-activities-4',
      'data.eq-5d.usual-activities-5',
    ],
    title: 'data.eq-5d.usual-activities-title',
  },
  'pain-discomfort': {
    name: 'pain-discomfort',
    image: '',
    options: [
      'data.eq-5d.pain-discomfort-1',
      'data.eq-5d.pain-discomfort-2',
      'data.eq-5d.pain-discomfort-3',
      'data.eq-5d.pain-discomfort-4',
      'data.eq-5d.pain-discomfort-5',
    ],
    title: 'data.eq-5d.pain-discomfort-title',
  },
  'anxiety-depression': {
    name: 'anxiety-depression',
    image: '',
    options: [
      'data.eq-5d.anxiety-depression-1',
      'data.eq-5d.anxiety-depression-2',
      'data.eq-5d.anxiety-depression-3',
      'data.eq-5d.anxiety-depression-4',
      'data.eq-5d.anxiety-depression-5',
    ],
    title: 'data.eq-5d.anxiety-depression-title',
  },
}

export default Object.entries(data).reduce((acc, [key, value]) => {
  acc[key] = {
    ...value,
    options: value.options.map((option, i) => ({
      label: option,
      value: i,
    })),
  }

  return acc
}, {})
