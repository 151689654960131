<template>
  <plugin-article
    has-background
    :img="'weekly-review/eating.png'">
      <loading-indicator
        v-if="isLoading"/>

      <template
        v-else>
          <template slot="title">
            {{ $t(`${$options.slug}.title`) }}
          </template>
          <template slot="content">
            <p>
              {{ $t(`${$options.slug}.content-1`) }}
            </p>
            <p>
              {{ $t(`${$options.slug}.content-2`) }}
            </p>

            <div
              class="weekly-review-plan"
              v-for="(plan, i) in $options.dialogPlans"
              :key="i">
                <b>{{ $t(plan.category) }}</b>
                <ul>
                  <li
                    v-for="(dialogLink, k) in plan.children"
                    :key="k">
                      <weekly-review-dialog-link
                        v-bind="{ dialogLink }"/>
                  </li>
                </ul>
            </div>

            <base-form
              ref="form"
              :hasSubmitButton="false"
              @submit="onSubmit">
                <select-group
                  :label="`${$options.slug}.label`"
                  :name="'active_plan'"
                  :options="availablePlans"
                  :validation="$options.validation.active_plan"
                  v-model="activePlan"
                >
                  <template #singleLabel="{ option }">
                    {{ option.value.label }}
                  </template>
                  <template #option="{ option }">
                    {{ option.value.label }}
                  </template>
                </select-group>
            </base-form>

          </template>
      </template>
  </plugin-article>
</template>

<script>
  import { mapState } from 'vuex'

  import BaseForm from '@/components/base/BaseForm'
  import SelectGroup from '@/components/forms/select-group/SelectGroup'
  import LoadingIndicator from '@/components/loading-indicator'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import WeeklyReviewDialogLink from '@/components/weekly-review/WeeklyReviewDialogLink'

  import { links } from '@/data/weekly-review/weeklyReviewDialogs'

  import weeklyReviewPlans from '@/mixins/weekly-review/weeklyReviewPlans'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review-eating.weekly-review-eating-plan.meta.title'),
      }
    },

    components: {
      BaseForm,
      LoadingIndicator,
      PluginArticle,
      SelectGroup,
      WeeklyReviewDialogLink,
    },

    mixins: [
      weeklyReviewPlans,
    ],

    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    created() {
      this.fetchWeeklyReviewData()
        .then(() => { this.form.active_plan = this.plans.active_plan })
    },

    data() {
      return {
        form: {
          active_plan: null,
          step_type: 'eating-plan',
        },
      }
    },

    computed: {
      ...mapState('weeklyReview', {
        plans: 'eatingPlans',
      }),
    },

    slug: 'views.plugins.weekly-review.weekly-review-eating.weekly-review-eating-plan',

    dialogPlans: links.eating.plans,
  }
</script>

<style lang="scss">
  .weekly-review {
    &-plan {
      margin: 2rem 0;
    }
  }
</style>
