<template>
  <portal
    slim
    :disabled="!hasPortal"
    :to="'sub-tabs'"
  >
    <nav
      class="sub-tabs"
      :class="{ 'sub-tabs--has-portal': hasPortal }"
      :data-testid="`${dataTestid} sub-tabs`"
    >
      <div class="sub-tabs__layout">
        <router-link
          v-for="(item, i) in items"
          :key="i"
          exact
          class="sub-tab"
          :to="{ name: item.link }"
          v-slot="{ href, route, navigate, isExactActive }"
        >
          <a
            :class="{ 'sub-tab--active': isExactActive }"
            :aria-disabled="isExactActive"
            :data-testid="`sub-tabs-tab sub-tabs-tab-${isExactActive ? 'active' : 'inactive'}`"
            v-bind="{ href, ...getLinkProps(isExactActive) }"
            @click="navigate"
          >
            <inline-svg
              v-if="item.icon"
              class="sub-tab__icon"
              :src="`${item.icon}.svg`"
            />
            {{ item.name }}
          </a>
        </router-link>
      </div>
    </nav>
  </portal>
</template>

<script>
  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      testid('sub-tabs'),
    ],

    props: {
      items: {
        type: Array,
        required: true,
      },
      hasPortal: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      getLinkProps(isActive) {
        return isActive
          ? { 'aria-current': 'page' }
          : {}
      },
    },
  }
</script>

<style lang="scss">
  $subTabsBlock: 'sub-tabs';
  $subTabBlock: 'sub-tab';

  .#{$subTabsBlock} {
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    top: $header_height;
    z-index: z-index(over-content);
    @include min-md {
      height: $sub-tabs-height;
    }
    @include min-lg {
      top: $header_height--lg;
    }
    &__layout {
      display: grid;
      grid-row-gap: 2rem;
      width: 100%;
      max-width: 77rem;
      margin: 0 auto;
      padding: 2rem 0;
      @include min-md {
        grid-column-gap: 3rem;
        grid-row-gap: 0;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
      }
    }
    &--has-portal {
      position: static;
      top: 0;
    }
  }
  .#{$subTabBlock} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 6rem;
    color: color(primary);
    font-size: 1.4rem;
    font-weight: 800;
    text-transform: uppercase;
    border: 2px solid color(primary);
    border-radius: 3rem;
    background: color(_white);
    &__icon {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 1rem 0 0;
      svg {
        fill: color(primary);
      }
    }
    &--active {
      color: color(_white);
      background-color: color(dark-primary);
      .#{$subTabBlock}__icon svg {
        fill: color(_white);
      }
    }
  }
</style>
