<template>
  <inline-svg
    class="reading-room-icon"
    :class="iconClass"
    role="img"
    :aria-label="ariaLabel"
    :src="icon"
  />
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
    },

    computed: {
      icon() {
        return this.$static.iconsNames?.[this.type] || 'heart-outline.svg'
      },
      iconClass() {
        return `reading-room-icon__${this.type}`
      },
      ariaLabel() {
        return this.$static.iconsLables[this.type]
      },
    },

    static() {
      return {
        iconsLables: {
          read: 'This content was read by you',
          new: 'New content',
          favourite: 'Your favourite content',
        },
        iconsNames: {
          read: 'tick.svg',
          new: 'star.svg',
          favourite: 'heart-outline.svg',
        },
      }
    },
  }
</script>

<style lang="scss">
  $blockName: reading-room-icon;

  .#{$blockName} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    color: color(_white);
    border-radius: 1.6rem;
    box-shadow: box-shadow(default);
    svg {
      width: 2rem;
      height: 2rem;
      fill: currentColor;
    }
    &.#{$blockName}__new {
      background-color: color(primary);
    }
    &.#{$blockName}__read {
      background-color: color(dark-primary);
    }
    &.#{$blockName}__favourite {
      background-color: color(favourite-article);
    }
    &.#{$blockName}__favourite-unchecked {
      color: initial;
      background-color: color(_white);
    }
  }
</style>
