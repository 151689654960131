export default {
  subtitle: 'data.carb-calculator.results.subtitle',
  title: 'data.carb-calculator.results.title',
  text: [
    'data.carb-calculator.results.text-1',
    'data.carb-calculator.results.text-2',
    'data.carb-calculator.results.text-3',
    'data.carb-calculator.results.text-4',
  ],
  example: 'data.carb-calculator.results.example',
}
