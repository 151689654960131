<template>
  <base-main
    appbar
    class="setting-plan"
    data-testid="setting-the-strongest-plan"
    :loading="$options.loading"
  >
    <container
      stepper
      class="setting-plan__wrapper"
    >
      <stepper-custom-form
        ref="form"
        :buttonLabels="{ previousLabel }"
        :eventCallbacks="{ onPrevious, onNext }"
        :isLoading="isSaving"
        @cancel="goToTools"
        @last-step-accessed="unblockPlugin"
        @step-changed="setStep"
        @submit="goToTools"
      >
        <stepper-custom-step
          v-for="(step, k) in $static.steps"
          :key="k"
          class="setting-plan-content"
          :data-testid="`setting-the-strongest-plan-step-${step.name}`"
          :title="'Trigger Situations'"
          :useTitleNumeration="false"
        >
          <div
            v-if="step.showContent && isIntro(k)"
            class="setting-plan-content__intro"
            data-testid="setting-the-strongest-plan-intro-text"
          >
            <p class="intro-element">
              {{ $t($static.contents.intro.firstParagraph) }}
            </p>
            <p class="intro-element">
              {{ $t($static.contents.intro.secondParagraph) }}
            </p>
            <ul
              class="intro-element intro-element--indented"
              data-testid="setting-the-strongest-plan-intro-situations"
            >
              <li
                v-for="(item, i) in $static.contents.intro.situations"
                :key="i"
                data-testid="setting-the-strongest-plan-intro-situation"
              >
                {{ $t(item) }}
              </li>
            </ul>
            <p class="intro-element">
              {{ $t($static.contents.intro.thirdParagrah) }}
            </p>
          </div>
          <div
            v-if="step.showContent && isPlanningStep(k)"
            class="setting-plan-content__intro"
            data-testid="setting-the-strongest-plan-planning-text"
          >
            <h3
              data-testid="setting-the-strongest-plan-planning-heading"
            >
              {{ $t($static.contents.planningStep.header) }}
            </h3>
            <p>{{ $t($static.contents.planningStep.firstParagraph) }}</p>
          </div>
          <input-group
            v-if="step.showInput"
            :label="i18n('plan')"
            :name="'plan'"
            :type="'text'"
            :validation="$options.validation.plan"
            :testid="'settings-the-strongest-plan-input'"
            v-model="activeForm.plan"
          />
          <span
            v-if="step.showAnswer"
            data-testid="settings-the-strongest-plan-answer"
          >
            {{ answer }}
          </span>
          <speech-bubble
            v-if="step.showCoachText"
            :imageLink="coachImage"
          >
            {{ $t($static.coachText) }}
          </speech-bubble>
        </stepper-custom-step>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { isPlainObject } from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'
  import {
    StepperCustomForm,
    StepperCustomStep,
  } from '@/components/stepper-custom'

  import settingTheStrongestPlanTexts from '@/data/setting-the-strongest-plan'

  import i18n from '@/mixins/i18n'
  import requireImage from '@/mixins/requireImage'
  import shouldFetch from '@/mixins/shouldFetch'
  import unblockPlugin from '@/mixins/unblockPlugin'

  const pluginName = 'settingTheStrongestPlan'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.setting-the-strongest-plan.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      InputGroup,
      SpeechBubble,
      StepperCustomForm,
      StepperCustomStep,
    },

    mixins: [
      i18n,
      shouldFetch,
      requireImage,
      unblockPlugin,
    ],

    created() {
      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(() => {
          if (!this.answers) return

          this.fillInForm()
          this.redirectToResults()
        })
    },

    data() {
      return {
        form: {
          plan: null,
        },
        activeForm: {
          plan: null,
        },
        currentStep: null,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'addAnswers',
        'fetchAnswers',
        'updateAnswers',
      ]),
      fillInForm() {
        isPlainObject(this.answers)
          ? this.form = this.answers
          : this.form.plan = this.answers

        this.activeForm = { ...this.form }
      },
      getMethod(step) {
        return step == this.maxStep - 1
          ? this.saveAnswers()
          : Promise.resolve()
      },
      onPrevious(prev, step) {
        step == this.maxStep ? this.rerun() : prev()
      },
      onNext(next, step) {
        this.getMethod(step).then(next)
      },
      setStep(step) {
        this.currentStep = step
      },
      goToTools() {
        this.$router.push({ name: 'Tools' })
      },
      rerun() {
        this.$refs.form.changeStep(0)
      },
      redirectToResults() {
        this.$refs.form.changeStep(2)
      },
      saveAnswers() {
        this.form = { ...this.activeForm }
        const { form, currentProgramId, getTriggerByName } = this

        return this.answers
          ? this.updateAnswers({ form: { answers: form }, pluginName })
          : this.addAnswers({
            form: {
              answers: form,
              plugin_id: getTriggerByName('setting-the-strongest-plan').plugin.id,
              program_id: currentProgramId,
            },
            pluginName,
          })
      },
      isIntro(step) {
        return step == 0
      },
      isPlanningStep(step) {
        return step == 1
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      ...mapGetters('triggers', [
        'getTriggerByName',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      answer() {
        return isPlainObject(this.answers)
                ? this.answers.plan
                : this.answers
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          `${pluginName}/addAnswers`,
          `${pluginName}/updateAnswers`,
        ])
      },
      previousLabel() {
        return this.currentStep == this.maxStep ? 'Rerun' : 'Previous'
      },
      maxStep() {
        return this.$static.steps.length - 1
      },
    },

    slug: 'views.plugins.setting-the-strongest-plan',

    loading: [
      `${pluginName}/fetchAnswers`,
    ],

    validation: {
      plan: {
        required: true,
      },
    },

    static() {
      return {
        nextButtons: [
          'Next',
          'Save',
          'Finish',
        ],
        prevButtons: [
          'Cancel',
          'Cancel',
          'Rerun',
        ],
        ...settingTheStrongestPlanTexts,
      }
    },
  }
</script>
<style lang="scss">
  .setting-plan {
    &__wrapper {
      width: 100%;
      max-width: 77.5rem;
      padding: 2rem;
      @include min-lg {
        padding: 4rem 0;
      }
    }
    ul {
      list-style-type: initial;
      padding-left: 3rem;
    }
    @include max-sm {
      word-break: break-word;
    }
    .setting-plan-content {
      padding-bottom: 2rem;
      &__intro > .intro-element {
        padding: 1rem 0;
        &--indented { padding-left: 3rem }
      }
    }
  }
</style>
