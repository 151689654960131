<template>
  <base-main
    class="risk-form"
    data-testid="risk-calculator-form"
  >
    <container
      stepper>
        <stepper-form
          @submit="onSubmit">

            <stepper-step
              :fieldsNames="'gender'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-1`) }}
              </template>
              <template slot="content">
                <form-group
                  :name="'gender'"
                  :validation="$options.validation.gender">
                    <chips>
                      <chip-radio-group
                        v-for="(option, i) in genderOptions"
                        :key="i"
                        :name="'gender'"
                        :label="$t(option.label)"
                        :inputValue="option.value"
                        :testid="'risk-calculator-form-input'"
                        v-model="form.gender"
                      />
                    </chips>
                </form-group>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="'waist_circumference'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-2`) }}
              </template>
              <template slot="content">
                <form-group
                  :name="'waist_circumference'"
                  :validation="$options.validation.waist_circumference">
                    <chips>
                      <chip-radio-group
                        v-for="(option, i) in waistOptions"
                        :key="i"
                        :name="'waist_circumference'"
                        :label="$t(option)"
                        :inputValue="option"
                        :testid="'risk-calculator-form-input'"
                        v-model="form.waist_circumference"
                      />
                    </chips>
                </form-group>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="['height', 'weight']"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-3`) }}
              </template>
              <template slot="content">
                <grid>
                  <grid-column
                    xs-4
                    sm-3
                    xl-2
                    class="risk-form-col"
                  >
                    <input-group
                      :label="$t('Height')"
                      :name="'height'"
                      :type="'number'"
                      :min="50"
                      :max="250"
                      :validation="$options.validation.height"
                      :testid="'risk-calculator-form-input'"
                      v-model="form.height"
                    />
                    cm
                  </grid-column>
                  <grid-column xs-1/>
                  <grid-column
                    xs-4
                    sm-3
                    xl-2
                    class="risk-form-col"
                  >
                    <input-group
                      :label="$t('Weight')"
                      :name="'weight'"
                      :type="'number'"
                      :min="20"
                      :max="400"
                      :step="0.1"
                      :validation="$options.validation.weight"
                      :testid="'risk-calculator-form-input'"
                      v-model="form.weight"
                    />
                    kg
                  </grid-column>
                </grid>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="'contactTime'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-4`) }}
              </template>
              <template slot="content">
                <p class="stepper-additional stepper-additional--dark">
                  {{ $t(`${$options.slug}.content-1`) }}
                </p>
                <p class="risk-form-bmi">
                  <span
                    class="risk-form-bmi-number"
                    data-testid="risk-calculator-form-bmi-result"
                  >
                    {{ bmiResult }}
                  </span>
                  ({{ $t(bmiDescription) }})
                </p>
                <a
                  class="risk-form-link"
                  data-testid="risk-calculator-form-bmi-learn-button"
                  href="https://www.nhs.uk/Tools/Pages/Healthyweightcalculator.aspx"
                  target="_blank"
                >
                  {{ $t(`${$options.slug}.learn-bmi`) }}
                </a>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="'blood_pressure_medication'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-5`) }}
              </template>
              <template slot="content">
                <form-group
                  :name="'blood_pressure_medication'"
                  :validation="$options.validation.blood_pressure_medication">
                    <chips>
                      <chip-radio-group
                        v-for="(option, i) in booleanOptions"
                        :key="i"
                        :name="'blood_pressure_medication'"
                        :label="$t(option.label)"
                        :inputValue="option.value"
                        :testid="'risk-calculator-form-input'"
                        v-model="form.blood_pressure_medication"
                      />
                    </chips>
                </form-group>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="'family_history_glucose'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-6`) }}
              </template>
              <template slot="content">
                <form-group
                  :name="'family_history_glucose'"
                  :validation="$options.validation.family_history_glucose">
                    <chips>
                      <chip-radio-group
                        v-for="(option, i) in booleanOptions"
                        :key="i"
                        :name="'family_history_glucose'"
                        :label="$t(option.label)"
                        :inputValue="option.value"
                        :testid="'risk-calculator-form-input'"
                        v-model="form.family_history_glucose"
                      />
                    </chips>
                </form-group>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="'exercise'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-7`) }}
              </template>
              <template slot="content">
                <form-group
                  :name="'exercise'"
                  :validation="$options.validation.exercise">
                    <chips>
                      <chip-radio-group
                        v-for="(option, i) in exerciseOptions"
                        :key="i"
                        :name="'exercise'"
                        :label="$t(option)"
                        :inputValue="option"
                        :testid="'risk-calculator-form-input'"
                        v-model="form.exercise"
                      />
                    </chips>
                </form-group>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="'consumption'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-8`) }}
              </template>
              <template slot="content">
                <form-group
                  :name="'consumption'"
                  :validation="$options.validation.consumption">
                    <chips>
                      <chip-radio-group
                        v-for="(option, i) in booleanOptions"
                        :key="i"
                        :name="'consumption'"
                        :label="$t(option.label)"
                        :inputValue="option.value"
                        :testid="'risk-calculator-form-input'"
                        v-model="form.consumption"
                      />
                    </chips>
                </form-group>
              </template>
            </stepper-step>

            <stepper-step
              :fieldsNames="'family_history_diabetes'"
              :testid="'risk-calculator-form-step'"
            >
              <template slot="label">
                {{ $t(`${$options.slug}.step-label-9`) }}
              </template>
              <template slot="content">
                <form-group
                  :name="'family_history_diabetes'"
                  :validation="$options.validation.family_history_diabetes">
                    <chips>
                      <chip-radio-group
                        v-for="(option, i) in diabetesOptions"
                        :key="i"
                        :name="'family_history_diabetes'"
                        :label="$t(option)"
                        :inputValue="option"
                        :testid="'risk-calculator-form-input'"
                        v-model="form.family_history_diabetes"
                      />
                    </chips>
                </form-group>
              </template>
            </stepper-step>
        </stepper-form>
    </container>
  </base-main>
</template>

<script>
  import { mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Chips from '@/components/chips/Chips'
  import Container from '@/components/container/Container'
  import ChipRadioGroup from '@/components/forms/chip-radio-group/ChipRadioGroup'
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import StepperForm from '@/components/forms/stepper-form/StepperForm'
  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import StepperStep from '@/components/stepper/stepper-step/StepperStep'

  import numberizeProperties from '@/mixins/numberizeProperties'

  const slug = 'views.plugins.risk-calculator.risk-calculator-form'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.risk-calculator.risk-calculator-form.meta.title'),
      }
    },

    components: {
      BaseMain,
      Chips,
      ChipRadioGroup,
      Container,
      FormGroup,
      Grid,
      GridColumn,
      InputGroup,
      StepperForm,
      StepperStep,
    },

    mixins: [
      numberizeProperties,
    ],

    data() {
      return {
        form: {
          gender: '',
          waist_circumference: '',
          height: '',
          weight: '',
          blood_pressure_medication: null,
          family_history_glucose: null,
          exercise: '',
          consumption: null,
          family_history_diabetes: '',
        },
        booleanOptions: [{ label: 'No', value: false }, { label: 'Yes', value: true }],
        diabetesOptions: ['No', 'Yes: sibling, parents, own child', 'Yes: grandparent, aunt, uncle, first cousin'],
        exerciseOptions: ['More than 4hr/week', 'Less than 4hr/week'],
        genderOptions: [
          { label: `${slug}.gender-1`, value: 'Male' },
          { label: `${slug}.gender-2`, value: 'Female' },
        ],
      }
    },

    methods: {
      onSubmit(isValid) {
        if (!isValid) return

        this.numberizeProperties('form.height', 'form.weight')
        const points = this.calculateFINDRISC()

        this.$router.push({ name: 'RiskCalculatorResults', params: { gender: this.form.gender, points } })
      },
      calculateFINDRISC() {
        const age = this.getCurrentUserAge
        const agePoints = age < 45
                            ? 0
                            : age > 64
                              ? 4
                              : age >= 45 && age <= 54
                               ? 2
                               : 3

        const bmiPoints = this.bmiResult < 25
                            ? 0
                            : this.bmiResult > 30
                              ? 3
                              : 1

        const otherPoints = Object.keys(this.form).reduce((acc, curr) => {
          if (acc == 'gender' || acc == 'height' || acc == 'weight') return

          let points = 0

          if (curr == 'waist_circumference') {
            points = this.form[curr].includes('to')
                        ? 3
                        : this.form[curr].includes('≥')
                          ? 4
                          : 0
          } else if (curr == 'blood_pressure_medication') {
            points = this.form[curr] ? 2 : 0
          } else if (curr == 'family_history_glucose') {
            points = this.form[curr] ? 5 : 0
          } else if (curr == 'exercise') {
            points = this.form[curr] == 'More than 4hr/week' ? 0 : 2
          } else if (curr == 'consumption') {
            points = this.form[curr] ? 0 : 1
          } else if (curr == 'family_history_diabetes') {
            points = this.form[curr] == 'No'
                      ? 0
                      : this.form[curr].includes('sibling')
                        ? 5
                        : 3
          }
          return points + acc
        }, 0)

        return agePoints + bmiPoints + otherPoints
      },
    },

    computed: {
      ...mapGetters('user', [
        'getCurrentUserAge',
      ]),
      bmiResult() {
        const height = this.form.height / 100
        return Math.floor(this.form.weight / (height * height)) || 0
      },
      bmiDescription() {
        const bmi = this.bmiResult

        return bmi <= 18.5
                ? 'Underweight'
                : bmi > 18.5 && bmi <= 24
                  ? 'Healthy'
                  : bmi > 24 && bmi <= 29
                    ? 'Overweight'
                    : bmi > 29 && bmi <= 39
                      ? 'Obese'
                      : 'Extremely obese'
      },
      waistOptions() {
        return this.form.gender == 'Male'
                ? ['<94 cm (37 in)', '94 to <102 cm (37 to <40 in)', '≥102 cm (≥40 in)']
                : ['<80 cm (31 in)', '80 to <88 cm (31 to <35 in)', '≥88 cm (≥35 in)']
      },
    },

    slug,

    validation: {
      gender: {
        required: true,
      },
      waist_circumference: {
        required: true,
      },
      height: {
        required: true,
        min_value: 50,
        max_value: 250,
      },
      weight: {
        required: true,
        min_value: 20,
        max_value: 400,
      },
      blood_pressure_medication: {
        required: true,
      },
      family_history_glucose: {
        required: true,
      },
      exercise: {
        required: true,
      },
      consumption: {
        required: true,
      },
      family_history_diabetes: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .risk-form {
    &-col {
      display: flex;
      align-items: baseline;
    }
    &-bmi {
      color: color(dark-primary);
    }
    &-bmi-number {
      font-size: 2rem;
    }
    &-link {
      margin: 0 0 1rem 0;
      color: color(primary);
      text-decoration: underline;
    }
  }
</style>
