<template>
  <modular-tile
    ref="dialog"
    class="dialog"
    aria-modal="true"
    data-testid="dialog"
    role="dialog"
    :closeAction="close"
    :hasCloseButton="closeOnClickOutside"
    @keydown.esc="close"
    v-click-outside="close"
    v-merge-attr:data-testid
    v-bind="{
      disableHeaderStyling,
      headerLevel,
      headerText,
      image,
      imagePosition,
    }"
  >
    <template
      v-if="hasIconSlot"
      #icon
    >
      <slot name="icon" />
    </template>

    <template #error>
      <slot name="error"/>
    </template>
    <div
      class="dialog__container"
      :class="{ 'dialog__container--is-visible': isOverflowVisible }"
      data-testid="dialog-container"
    >
      <div class="dialog__content">
        <slot/>
      </div>
      <div
        v-if="$slots.action"
        class="dialog__actions"
        data-testid="dialog-actions"
      >
        <slot name="action"/>
      </div>
    </div>

    <template #action>
      <slot name="content-action"/>
    </template>
  </modular-tile>
</template>

<script>
  import { mapActions } from 'vuex'

  import ModularTile from '@/components/tiles/modular-tile'

  import clickOutside from '@/directives/clickOutside'
  import mergeAttr from '@/directives/mergeAttr'

  import { getAllFocusables } from '@/helpers'

  import launchIntoFullscreen from '@/mixins/fullscreen/launchIntoFullscreen'

  export default {
    inheritAttrs: false,

    components: {
      ModularTile,
    },

    directives: {
      clickOutside,
      mergeAttr,
    },

    mixins: [
      launchIntoFullscreen,
    ],

    props: {
      closeOnClickOutside: {
        type: Boolean,
        required: false,
        default: true,
      },
      disableHeaderStyling: {
        type: Boolean,
        default: true,
      },
      fullscreenOnClickOutside: {
        type: Boolean,
        required: false,
        default: false,
      },
      headerText: {
        type: String,
        default: '',
      },
      headerLevel: {
        type: Number,
        default: 2,
      },
      image: {
        type: String,
        default: '',
      },
      imagePosition: {
        type: String,
        default: 'top',
      },
      isOverflowVisible: {
        type: Boolean,
        default: false,
      },
    },

    mounted() {
      this.moveFocus()
    },

    methods: {
      ...mapActions('dialog', [
        'closeDialog',
      ]),
      close() {
        if (!this.closeOnClickOutside) return

        this.closeDialog()

        if (this.fullscreenOnClickOutside) {
          this.launchIntoFullscreen()
        }
      },
      moveFocus() {
        const focusables = Array.from(getAllFocusables(this.$refs.dialog))
        const firstNonCloseFocusable = focusables.find(focusable => !focusable.classList.contains('modular-tile__close'))
        const toFocus = firstNonCloseFocusable || focusables[0]

        toFocus.focus()
      },
    },

    computed: {
      hasIconSlot() {
        return !!this.$scopedSlots.icon
      },
    },
  }
</script>

<style lang="scss">
  @mixin add-focus-space {
    margin: -0.5rem 0;
    padding: 0 0.5rem;
    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.5rem;
    }
  }
  .dialog {
    max-width: 32rem;
    max-height: 90%;
    border-radius: 1rem;
    box-shadow: box-shadow(dialog);
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;
      @include add-focus-space;
      &--is-visible {
        overflow-y: visible;
      }
    }
    &__title {
      margin-top: 0;
    }
    &__content {
      line-height: 1.4;
    }
    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 3rem 0 0;
      @include min-lg {
        flex-direction: row;
      }
    }
    & > .simple-tile__main {
      background-color: color(_white);
    }
  }
</style>
