<template>
  <div class="stacked-bar-chart">
    <div
      class="stacked-bar-chart-annotation"
      :style="annotationStyle">
        <div class="stacked-bar-chart-pin">
          {{ position }}
        </div>
    </div>
    <div class="stacked-bar-chart-lines">
      <div
        v-for="(range, i) in ranges"
        class="stacked-bar-chart-line"
        :key="i"
        :style="lineStyle(range, i)"/>
    </div>
    <div class="stacked-bar-chart-labels">
      <pre
        v-for="(range, i) in ranges"
        class="stacked-bar-chart-label"
        :key="labels[i]"
        :style="labelStyle(range, i)">
          {{ $t(labels[i]) }}
          {{ rangeDisplay(range[0], range[1]) }}
      </pre>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      colors: {
        type: Array,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
      position: {
        type: Number,
        required: true,
      },
      ranges: {
        type: Array,
        required: true,
      },
    },

    methods: {
      percentage(number) {
        return `${number * 100}%`
      },
      percentageOfMaxValue(value) {
        return this.percentage(value / this.maxValue)
      },
      rangeDisplay(min, max) {
        return `${min}-${max}`
      },
      labelStyle(range, i) {
        const { ranges } = this

        return {
          width: this.percentageOfMaxValue(
            i < ranges.length - 1
              ? ranges[i + 1][0] - range[0]
              : range[1] - range[0],
          ),
        }
      },
      lineStyle(range, i) {
        const { ranges } = this

        return {
          width: this.percentageOfMaxValue(
            i < ranges.length - 1
              ? ranges[i + 1][0] - range[0]
              : range[1] - range[0],
          ),
          backgroundColor: this.colors[i],
          zIndex: ranges.length - i,
        }
      },
    },

    computed: {
      maxValue() {
        return this.ranges.reduce((acc, cur) => Math.max(acc[1] || 0, cur[1]))
      },
      barGradientStyle() {
        const stops = this.ranges.reduce((acc, cur, i, arr) => {
          const colorBeginPercentage = i > 0
            ? this.percentageOfMaxValue(cur[0])
            : '0%'
          const colorEndPercentage = i < arr.length - 1
            ? this.percentageOfMaxValue(arr[i + 1][0])
            : '100%'
          return `${acc}
            ${this.colors[i]} ${colorBeginPercentage},
            ${this.colors[i]} ${colorEndPercentage}
            ${i < arr.length - 1 ? ',' : ''}`
        }, '')
        return {
          background: `linear-gradient(to right,${stops})`,
        }
      },
      annotationStyle() {
        return {
          width: `calc(${this.percentageOfMaxValue(this.position)} - 1.2rem)`,
        }
      },
    },
  }
</script>
<style lang="scss">
  .stacked-bar-chart {
    position: relative;
    &-risk {
      margin-bottom: 0;
      font-size: 1.7rem;
    }
    &-points {
      margin-top: 0;
      margin-bottom: 1em;
      font-size: 1.7rem;
    }
    &-number {
      color: color(dark-primary);
      font-weight: 600;
    }
    &-lines {
      display: flex;
      margin: 5.4rem 0 0;
    }
    &-line {
      height: 1.4rem;
      position: relative;
      border-radius: 2rem;
      &:not(:first-child)::before {
        content: '';
        width: 1.4rem;
        height: 1.4rem;
        position: absolute;
        left: -0.7rem;
        background-color: inherit;
      }
    }
    &-annotation {
      display: flex;
      width: 0;
      margin-left: 0.6rem;
      position: absolute;
      top: -2rem;
      color: color(risk-chart-low);
      font-family: inherit;
      transition: width 0.4s ;
    }
    &-pin {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      position: absolute;
      right: 0;
      bottom: -0.6rem;
      color: color(_white);
      font-size: 1.8rem;
      font-weight: 600;
      transform: translateX(50%);
      border-radius: 2rem;
      background-color: color(dark-primary);
      &::after {
        content: '';
        position: absolute;
        bottom: -1.6rem;
        left: 50%;
        transform: translateX(-50%);
        border: 0.6rem solid transparent;
        border-top: 1.2rem solid color(dark-primary);
      }
    }
    &-labels {
      display: flex;
    }
    &-label {
      margin: 1rem 0;
      position: relative;
      color: color(grey);
      font-family: inherit;
      font-size: 1.6rem;
      font-weight: 600;
      white-space: pre-line;
      hyphens: auto;
      overflow-wrap: break-word;
    }
    &-chances {
      color: color(dark-primary);
      font-family: inherit;
      font-size: 1.7rem;
      font-weight: 600;
    }
    .plugin {
      &-title {
        padding: 2rem 0 1rem 0;
      }
      &-content {
        overflow-x: hidden;
      }
    }
  }
</style>
