<template>
  <base-main
    background
    class="reading-room"
    :loading="$options.loading"
  >
    <div class="reading-room__wrapper">
      <reading-room-single-category
        v-if="topics && hasTopics"
        ref="header"
        :title="getTopic.display_name"
        :headerLevel="1"
      />
      <reading-room-single-category
        v-else
        ref="header"
        :description="details.description"
        :title="details.title"
        :headerLevel="1"
      />

      <reading-room-single-category
        limit-tiles
        v-for="(category, i) in categoriesArray"
        :key="i"
        v-bind="{ ...category }"
      />
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import ReadingRoomSingleCategory from '@/components/reading-room/ReadingRoomSingleCategory'

  import shouldFetch from '@/mixins/shouldFetch'
  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      if (!this.getTopic) return

      const { display_name } = this.getTopic

      if (this.hasTopics) {
        setTimeout(() => {
          this.setBreadcrumbTopic(display_name)
        }, 0)
      }

      return {
        title: display_name,
      }
    },

    components: {
      BaseMain,
      ReadingRoomSingleCategory,
    },

    mixins: [
      unblockPlugin,
      shouldFetch,
    ],

    props: {
      topicId: {
        type: Number,
        required: true,
      },
    },

    async created() {
      await this.shouldFetch('topics', 'fetchTopics', { triggerDataId: this.triggerDataId })
      const topic = this.hasTopics ? { topicId: this.topicId } : {}
      this.fetchCategories({ triggerDataId: this.triggerDataId, ...topic })
      this.fetchOptionalContentDetails(this.triggerDataId)
      this.unblockPlugin()
    },

    destroyed() {
      this.resetBreadcrumbs()
    },

    methods: {
      ...mapActions('readingRoom', [
        'fetchCategories',
        'fetchTopics',
        'fetchOptionalContentDetails',
      ]),
      ...mapMutations('route', [
        'setBreadcrumbTitle',
        'setBreadcrumbTopic',
        'resetBreadcrumbs',
      ]),
      convertCategory(category) {
        const { description, display_name: title, items, id: categoryId, allItemsCount } = category

        return {
          categoryId,
          description,
          title,
          allItemsCount,
          articles: Object.entries(items).map(([, article]) => article),
        }
      },
    },

    computed: {
      ...mapGetters('triggers', [
        'getTriggerBySlug',
        'getTriggerDataIdByPluginName',
      ]),
      ...mapGetters('readingRoom', [
        'getDetailsByCategoryId',
        'getTopicById',
      ]),
      ...mapState('readingRoom', [
        'categories',
        'topics',
      ]),
      getTopic() {
        return this.getTopicById(this.topicId)
      },
      categoriesArray() {
        return this.categories
          ? Object.entries(this.categories).map(([, category]) => this.convertCategory(category))
          : null
      },
      triggerDataId() {
        return this.getTriggerDataIdByPluginName('reading-room')
      },
      details() {
        return this.getDetailsByCategoryId(this.triggerDataId) || {}
      },
      currentTrigger() {
        return this.getTriggerBySlug('reading-room')
      },
      currentPlugin() {
        return this.currentTrigger?.plugin
      },
      hasTopics() {
        return this.topics.length > 1
      },
    },

    loading: [
      'readingRoom/fetchTopics',
      'readingRoom/fetchCategories',
      'readingRoom/fetchOptionalContentDetails',
    ],

    slug: 'views.plugins.reading-room.reading-room',

    static() {
      return {
        headerDescription: `${this.$options.slug}.header-description`,
        headerTitle: `${this.$options.slug}.header-title`,
      }
    },
  }
</script>

<style lang="scss">
  .reading-room {
    &__wrapper {
      @include reading-room-wrapper;
    }
  }
</style>
