<template>
  <form-group
    class="checkbox-multi-group"
    data-testid="checkbox-multi-group"
    :label="legend"
    v-bind="{
      hideErrorMessage,
      name,
      tag,
      testid,
      validation,
    }"
    v-slot="{ errors }"
  >
    <base-legend
      v-if="hasLegend"
      data-testid="checkbox-multi-group-legend"
      :isHidden="isLegendHidden"
      :legend="labelText"
    >
      <slot name="legend" />
    </base-legend>

    <base-checkbox
      v-for="(option, i) in options"
      :key="i"
      is-multi
      :ariaInvalid="!!errors.length"
      :inputValue="extractValue(option, valueKey)"
      :label="extractValue(option, labelKey)"
      :descriptionId="describedBy"
      v-bind="{ name }"
      v-model="model"
    >
      <template
        #option
        slote-scope="{ option }"
      >
        <slot
          name="option"
          v-bind="{ option }"
        />
      </template>

      <template
        #default
        slote-scope="{ option }"
      >
        <slot
          v-bind="{ option }"
        />
      </template>
    </base-checkbox>
  </form-group>
</template>

<script>
  import { BaseCheckbox, BaseLegend } from '@/components/base'
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import addVModel from '@/mixins/addVModel'
  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseCheckbox,
      BaseLegend,
      FormGroup,
    },

    mixins: [
      addVModel('model'),
      labelText,
      testid(),
    ],

    props: {
      describedBy: {
        type: String,
        default: '',
      },
      hideErrorMessage: {
        type: Boolean,
        default: false,
      },
      isLegendHidden: {
        type: Boolean,
        default: false,
      },
      labelKey: {
        type: String,
        default: 'label',
      },
      legend: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      tag: {
        type: String,
        default: 'fieldset',
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      valueKey: {
        type: String,
        default: 'value',
      },
    },

    methods: {
      extractValue(item, key) {
        return item?.[key] ?? item
      },
    },

    computed: {
      hasLegend() {
        return this.legend || 'legend' in this.$scopedSlots
      },
    },
  }
</script>

<style lang="scss">
  .checkbox-multi-group {
    margin: 1.8rem 0 0;
    .input-details--has-error {
      margin-top: 1.8rem;
    }
  }
</style>
