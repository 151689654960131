<template>
  <input-group
    class="initial-assessment-child-input"
    :disabled="!isRequired"
    :label="$t(label)"
    :required="isRequired"
    :stopPropagation="true"
    :type="'text'"
    :validation="validationRules"
    v-bind="{ name }"
    v-model="model"
  />
</template>

<script>
  import InputGroup from '@/components/forms/input-group/InputGroup'

  export default {
    components: {
      InputGroup,
    },

    props: {
      label: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      parentValue: {
        type: String,
        default: null,
      },
      questionValue: {
        type: [String, Number, Array],
        default: null,
      },
      validation: {
        type: Object,
        default: () => ({}),
      },
      value: {
        type: [String, Number],
        default: '',
      },
    },

    computed: {
      isRequired() {
        const { parentValue, questionValue } = this

        return Array.isArray(questionValue)
                ? questionValue.includes(parentValue)
                : questionValue == parentValue
      },
      model: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      validationRules() {
        return {
          required: this.isRequired,
          ...this.validation,
        }
      },
    },
  }
</script>

<style lang="scss">
  .initial-assessment-child-input {
    width: 100%;
    padding-left: 3.2rem;
  }
</style>
