import { mapActions } from 'vuex'

import eventBus from '@/tools/event-bus'

export default {
  methods: {
    ...mapActions('weeklyReview', [
      'fetchWeeklyReviewData',
      'saveWeeklyReviewData',
    ]),

    async onSubmit(skipFeedbackMessage = false) {
      const isValid = this.$refs.form
        ? await this.$refs.form.$refs.observer.validate()
        : true

      if (!isValid) return

      this.saveWeeklyReviewData({ form: this.form })
        .then(() => { eventBus.$emit('weeklyReview/goToNextView', skipFeedbackMessage) })
    },
  },
}
