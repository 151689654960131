export default {
  categories: [
    {
      name: 'breakfast',
      color: '#005662',
      title: 'data.carb-calculator.items.breakfast-title',
    },
    {
      name: 'lunch',
      color: '#F08515',
      title: 'data.carb-calculator.items.lunch-title',
    },
    {
      name: 'dinner',
      color: '#FECA05',
      title: 'data.carb-calculator.items.dinner-title',
    },
    {
      name: 'snack',
      color: '#64609B',
      title: 'data.carb-calculator.items.snack-title',
    },
  ],
  items: [
    {
      id: 1,
      icon: 'food/toast_and_jam.png',
      name: 'data.carb-calculator.items.1',
      carbs: 47,
      category: 'breakfast',
    },
    {
      id: 2,
      icon: 'food/yoghurt_and_fruits.png',
      name: 'data.carb-calculator.items.2',
      carbs: 27.1,
      category: 'breakfast',
    },
    {
      id: 3,
      icon: 'food/cornflakes.png',
      name: 'data.carb-calculator.items.3',
      carbs: 70,
      category: 'breakfast',
    },
    {
      id: 4,
      icon: 'food/breakfast_bar.png',
      name: 'data.carb-calculator.items.4',
      carbs: 29,
      category: 'breakfast',
    },
    {
      id: 5,
      icon: 'food/english_breakfast.png',
      name: 'data.carb-calculator.items.5',
      carbs: 71.75,
      category: 'breakfast',
    },
    {
      id: 6,
      icon: 'food/coffee.png',
      name: 'data.carb-calculator.items.6',
      carbs: 0,
      category: 'breakfast',
    },
    {
      id: 7,
      icon: 'food/sandwich.png',
      name: 'data.carb-calculator.items.7',
      carbs: 45,
      category: 'lunch',
    },
    {
      id: 8,
      icon: 'food/baked_potato_and_beans.png',
      name: 'data.carb-calculator.items.8',
      carbs: 47.7,
      category: 'lunch',
    },
    {
      id: 9,
      icon: 'food/fish_and_chips.png',
      name: 'data.carb-calculator.items.9',
      carbs: 166.8,
      category: 'lunch',
    },
    {
      id: 10,
      icon: 'food/pasta_salad.png',
      name: 'data.carb-calculator.items.10',
      carbs: 57.2,
      category: 'lunch',
    },
    {
      id: 11,
      icon: 'food/soup_and_bread_roll.png',
      name: 'data.carb-calculator.items.11',
      carbs: 57.9,
      category: 'lunch',
    },
    {
      id: 12,
      icon: 'food/curry.png',
      name: 'data.carb-calculator.items.12',
      carbs: 88.3,
      category: 'lunch',
    },
    {
      id: 13,
      icon: 'food/spaghetti.png',
      name: 'data.carb-calculator.items.13',
      carbs: 100,
      category: 'dinner',
    },
    {
      id: 14,
      icon: 'food/chicken_stir_fry.png',
      name: 'data.carb-calculator.items.14',
      carbs: 51.3,
      category: 'dinner',
    },
    {
      id: 15,
      icon: 'food/fish_and_steamed_veg.png',
      name: 'data.carb-calculator.items.15',
      carbs: 4.4,
      category: 'dinner',
    },
    {
      id: 16,
      icon: 'food/pizza.png',
      name: 'data.carb-calculator.items.16',
      carbs: 136,
      category: 'dinner',
    },
    {
      id: 17,
      icon: 'food/curry.png',
      name: 'data.carb-calculator.items.17',
      carbs: 88.3,
      category: 'dinner',
    },
    {
      id: 18,
      icon: 'food/crisps.png',
      name: 'data.carb-calculator.items.18',
      carbs: 15.6,
      category: 'snack',
    },
    {
      id: 19,
      icon: 'food/fruits.png',
      name: 'data.carb-calculator.items.19',
      carbs: 15.3,
      category: 'snack',
    },
    {
      id: 21,
      icon: 'food/cake.png',
      name: 'data.carb-calculator.items.21',
      carbs: 20.8,
      category: 'snack',
    },
    {
      id: 22,
      icon: 'food/sweets.png',
      name: 'data.carb-calculator.items.22',
      carbs: 25,
      category: 'snack',
    },
    {
      id: 23,
      icon: 'food/nuts.png',
      name: 'data.carb-calculator.items.23',
      carbs: 6.7,
      category: 'snack',
    },
  ],
  getItemsByCategory: (category, items) => (
    items.filter(item => item.category === category)
  ),
  get categoriesWithItems() {
    return this.categories
      .map(category => ({
      // [category.name]: {
        ...category,
        items: this.getItemsByCategory(category.name, this.items),
      // },
      }))
      // .reduce((acc, curr) => Object.assign(acc, curr), {})
  },
}
