export default [
  {
    id: 1,
    question: 'data.habits-quiz.habits-quiz-questions.question-1',
  },
  {
    id: 2,
    question: 'data.habits-quiz.habits-quiz-questions.question-2',
  },
  {
    id: 3,
    question: 'data.habits-quiz.habits-quiz-questions.question-3',
  },
  {
    id: 4,
    question: 'data.habits-quiz.habits-quiz-questions.question-4',
  },
  {
    id: 5,
    question: 'data.habits-quiz.habits-quiz-questions.question-5',
  },
  {
    id: 6,
    question: 'data.habits-quiz.habits-quiz-questions.question-6',
  },
  {
    id: 7,
    question: 'data.habits-quiz.habits-quiz-questions.question-7',
  },
  {
    id: 8,
    question: 'data.habits-quiz.habits-quiz-questions.question-8',
  },
  {
    id: 9,
    question: 'data.habits-quiz.habits-quiz-questions.question-9',
  },
  {
    id: 10,
    question: 'data.habits-quiz.habits-quiz-questions.question-10',
  },
  {
    id: 11,
    question: 'data.habits-quiz.habits-quiz-questions.question-11',
  },
  {
    id: 12,
    question: 'data.habits-quiz.habits-quiz-questions.question-12',
  },
]
