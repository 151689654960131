<template>
  <base-main
    class="getting-support-quiz"
    data-testid="getting-support-quiz"
    :loading="$options.loading"
  >
    <container
      v-if="form"
      first>
        <h2
          class="getting-support-quiz-heading"
          data-testid="getting-support-quiz-heading"
        >
          {{ $t('views.plugins.getting-support-quiz.getting-support-quiz.header') }}
        </h2>

        <stepper-form
          @submit="onSubmit">
            <stepper-step
              v-for="(situation, i) in situations"
              :key="i"
              :fieldsNames="`situation_${situation.id}`"
              :isSaving="isSaving && i == max">

                <template slot="label">
                  {{ $t(situation.question) }}
                </template>
                <template slot="content">

                  <range-group
                    :contextLabels="['Never', 'Rarely', 'Sometimes', 'Often']"
                    :min="1"
                    :max="4"
                    :name="`situation_${situation.id}`"
                    :validation="$options.validation.situation"
                    v-model="form[situation.id]"/>

                </template>
            </stepper-step>
          </stepper-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import RangeGroup from '@/components/forms/range-group/RangeGroup'
  import StepperForm from '@/components/forms/stepper-form/StepperForm'
  import StepperStep from '@/components/stepper/stepper-step/StepperStep'

  import situations from '@/data/getting-support-quiz/gettingSupportQuizQuestions'

  import { saveAnswers } from '@/mixins/quizzes-answers'

  const pluginName = 'gettingSupportQuiz'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.getting-support-quiz.getting-support-quiz.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      RangeGroup,
      StepperForm,
      StepperStep,
    },

    mixins: [
      saveAnswers(pluginName),
    ],

    created() {
      this.answers
        ? this.fillInForm()
        : this.initializeQuiz()

      this.createForm()
    },

    data() {
      return {
        situations,
        form: null,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
        'addAnswers',
        'updateAnswers',
      ]),
      createForm() {
        this.form = this.situations
                      .map(situation => situation.id)
                      .reduce((acc, curr) => Object.assign(acc, { [curr]: 1 }), {})
      },
      fillInForm() {
        this.form = this.answers
      },
      initializeQuiz() {
        this.fetchAnswers(pluginName)
            .then(() => {
              this.answers
                ? this.fillInForm()
                : this.createForm()
          })
      },
      onSubmit(isValid) {
        if (!isValid) return

        this.saveAnswers()
          .then(() => this.$router.push({ name: 'GettingSupportResults' }))
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          `${pluginName}/addAnswers`,
          `${pluginName}/updateAnswers`,
        ])
      },
      max() {
        return Object.keys(this.situations).length - 1
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],

    validation: {
      situation: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .getting-support-quiz {
    &-heading {
      margin: 0 0 2.5rem 0;
    }
  }
</style>
