<template>
  <base-main
    class="food-show"
    :loading="$options.loading">
      <container
        first>
          <grid center>
            <image-list-item
              :columnSizes="['xs-12', 'sm-8']"
              :dotColor="food.label_foods"
              :hasDot="true"
              :image="food.files ? food.files.url : ''"/>
          </grid>

          <plugin-answer>
            <template slot="label">
              {{ $t('Description') }}
            </template>
            <template slot="answer">
              {{ food.description }}
            </template>
          </plugin-answer>

          <plugin-answer
            v-if="food.notes">
              <template slot="label">
                {{ $t('Notes') }}
              </template>
              <template slot="answer">
                {{ food.notes }}
              </template>
          </plugin-answer>

          <div class="food-show-actions">
            <base-button
              align-end
              :link="{ name: 'FoodTrackingEdit', params: { id } }">
                {{ $t('Edit') }}
            </base-button>
          </div>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import Grid from '@/components/grid/Grid'
  import ImageListItem from '@/components/image-list/image-list-item/ImageListItem'
  import PluginAnswer from '@/components/plugin/plugin-answer/PluginAnswer'

  export default {
    metaInfo() {
      const { name: title } = this.currentPlugin || {}

      return {
        title,
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      Grid,
      ImageListItem,
      PluginAnswer,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    watch: {
      id: {
        handler() {
          this.fetchFood(this.id)
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('foodTracking', [
        'fetchFood',
      ]),
    },

    computed: {
      ...mapState('foodTracking', [
        'food',
      ]),
      ...mapGetters('triggers', [
        'getTriggerBySlug',
      ]),
      currentTrigger() {
        return this.getTriggerBySlug('food-tracking')
      },
      currentPlugin() {
        return this.currentTrigger?.plugin
      },
    },

    loading: [
      'foodTracking/fetchFood',
    ],
  }
</script>

<style lang="scss">
  .food-show {
    &-actions {
      display: flex;
      margin-top: 2rem;
    }
  }
</style>
