export default {
  titles: [
    'data.habits-quiz.habits-quiz-results.titles-1',
    'data.habits-quiz.habits-quiz-results.titles-2',
    'data.habits-quiz.habits-quiz-results.titles-3',
    'data.habits-quiz.habits-quiz-results.titles-4',
  ],
  introductions: [
    'data.habits-quiz.habits-quiz-results.introductions-1',
    'data.habits-quiz.habits-quiz-results.introductions-2',
    'data.habits-quiz.habits-quiz-results.introductions-3',
    'data.habits-quiz.habits-quiz-results.introductions-4',
  ],
  advices: [
    [
      'data.habits-quiz.habits-quiz-results.advices-1',
      'data.habits-quiz.habits-quiz-results.advices-2',
      'data.habits-quiz.habits-quiz-results.advices-3',
    ],
    [
      'data.habits-quiz.habits-quiz-results.advices-4',
      'data.habits-quiz.habits-quiz-results.advices-5',
      'data.habits-quiz.habits-quiz-results.advices-6',
    ],
    [
      'data.habits-quiz.habits-quiz-results.advices-7',
      'data.habits-quiz.habits-quiz-results.advices-8',
    ],
    [
      'data.habits-quiz.habits-quiz-results.advices-9',
      'data.habits-quiz.habits-quiz-results.advices-10',
      'data.habits-quiz.habits-quiz-results.advices-11',
    ],
  ],
}
