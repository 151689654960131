<template>
  <transition-scale>
    <div
      class="notification"
      modal="true"
      role="dialog"
      :aria-describedby="`notification-title-${titleKebab} notification-content-${titleKebab}`"
      v-show="!isLoading"
    >

      <div class="notification-container">

        <notification-header
          v-bind="{ time }"
          @close="dismiss"
          @snooze="onSnooze"
        />

        <h2
          :id="`notification-title-${titleKebab}`"
          class="notification-title"
        >
          {{ notification.data.title }}
        </h2>

        <p
          :id="`notification-content-${titleKebab}`"
          class="notification-content"
        >
          {{ notification.data.description }}
        </p>
      </div>

      <div class="notification-actions">
        <base-button
          v-if="notification.data.action"
          class="notification__action-button"
          data-testid="notification-action"
          @click="doAction"
        >
          {{ notification.data.action_label }}
        </base-button>
      </div>
    </div>
  </transition-scale>
</template>

<script>
  import { kebabCase } from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import { BaseButton } from '@/components/base'
  import NotificationHeader from '@/components/notification/notification-header/NotificationHeader'

  import TransitionScale from '@/transitions/transition-scale/TransitionScale'

  export default {
    components: {
      BaseButton,
      NotificationHeader,
      TransitionScale,
    },

    props: {
      notification: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    methods: {
      ...mapActions('notifications', [
        'markAsRead',
        'snooze',
      ]),
      doAction() {
        if (this.notification.data.action) {
          this.$router.push({
            path: `/${this.notification.data.action}`,
          })
        }

        this.dismiss()
      },
      onSnooze(until) {
        this.snooze({ id: this.notification.id, until })
      },
      dismiss() {
        this.markAsRead(this.notification.id)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isLoading() {
        return this.getLoadingStatesForActions([
          `notifications/markAsRead/${this.notification.id}`,
        ])
      },
      time() {
        if (this.notification.created_at && this.notification.created_at.date) {
          return this.notification.created_at.date
        } else {
          return this.notification.created_at
        }
      },
      titleKebab() {
        return kebabCase(this.notification.data.title)
      },
    },
  }
</script>

<style lang="scss">
  .notification {
    width: calc(100% - 1rem);
    max-width: 50rem;
    margin: 0.5rem auto;
    background-color: color(_white);
    border-radius: 1rem;
    box-shadow: box-shadow(dialog);
    @include min-lg {
      max-width: initial;
      width: 50rem;
    }
    &-container {
      padding: 1.5rem 2rem 2rem;
    }
    &-title {
      font-size: 1.6rem;
    }
    &-content {
      margin-bottom: 0.5rem;
    }
    &-actions {
      margin: 0 -0.8rem;
    }
  }
</style>
