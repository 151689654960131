<template>
  <div
    ref="menu"
    class="dropdown-menu"
    :class="{ 'dropdown-menu--is-open': isOpen }"
    v-click-outside="onClickOutside"
    @focusout="onFocusOut"
  >
    <div
      v-if="$slots.header"
      class="dropdown-menu-header"
    >
      <slot name="header"/>
    </div>
    <nav :aria-label="ariaLabel">
      <ul
        ref="items"
        class="dropdown-menu-items"
      >
        <slot/>
      </ul>
    </nav>
    <div
      v-if="$slots.footer"
      class="dropdown-menu-footer"
    >
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
  import { gsap } from 'gsap'

  import clickOutside from '@/directives/clickOutside'

  export default {
    directives: {
      clickOutside,
    },

    props: {
      ariaLabel: {
        type: String,
        default: '',
      },
      isOpen: {
        type: Boolean,
        required: true,
      },
      triggerButtonSelector: {
        type: String,
        default: '',
      },
    },

    mounted() {
      this.createMenuTl()
    },

    watch: {
      isOpen: 'toggleMenuTl',
    },

    data() {
      return {
        menuTl: null,
      }
    },

    methods: {
      createMenuTl() {
        const { menu } = this.$refs

        this.menuTl = gsap.timeline({
          paused: true,
          onComplete: this.moveFocus,
        })
          .to(menu, 0.35, { maxHeight: '40rem' })
          .staggerTo(
            this.$el.querySelectorAll('.dropdown-menu-item'),
            0.3,
            { className: '+=dropdown-menu-item--is-visible' },
            0.1,
            '-=0.2',
          )
      },
      moveFocus() {
        /* eslint-disable-next-line no-unused-expressions */
        this.$refs.items.querySelector('a, button')?.focus()
      },
      returnFocus() {
        if (this.triggerButtonSelector) {
          /* eslint-disable-next-line no-unused-expressions */
          document.querySelector(this.triggerButtonSelector)?.focus()
        }
      },
      restartMenuTl(speed = 1) {
        this.createMenuTl()
        this.menuTl.play().timeScale(speed)
      },
      toggleMenuTl() {
        this.isOpen
          ? this.menuTl.play().timeScale(1)
          : this.menuTl.reverse().timeScale(10)
      },
      onClickOutside(e) {
        this.$emit('clickOutside', e)
      },
      onFocusOut(e) {
        const { target } = e
        // debugger

        if (this.shouldEmitFocusOut(target)) {
          if (!target) this.returnFocus()

          this.$emit('focus-out', e)
        }
      },
      shouldEmitFocusOut(target) {
        return (
          !this.$el.contains(target)
          && (
            !target?.matches(this.triggerButtonSelector) || document.body.classList.contains('is-tab')
          )
        )
      },
    },
  }

</script>

<style lang="scss">
  .dropdown-menu {
    min-width: 5.6rem;
    max-height: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: z-index(dropdown);
    visibility: hidden;
    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) 0.2s, max-height 0.35s ease-out;
    opacity: 0;
    border-radius: 1rem;
    background-color: color(_white);
    box-shadow: box-shadow(default);
    &--is-open {
      visibility: visible;
      transition: opacity 0.3s cubic-bezier(0.25,0.8,0.5,1), max-height 0.35s ease-out;
      opacity: 1;
    }
    &-items {
      padding: 1rem 0;
    }
    &-header,
    &-footer {
      width: 100%;
      padding: 1.8rem 1.6rem;
      font-size: 1.5rem;
      background-color: color(_white);

      &:empty {
        display: none;
      }
    }
    &-header {
      border-bottom: 1px solid rgba(black, 0.1);
    }
    &-footer {
      border-top: 1px solid rgba(black, 0.1);
    }
  }
</style>
