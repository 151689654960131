export default {
  titles: {
    1: 'data.tricky-situations.tricky-situations-results.title-1',
    2: 'data.tricky-situations.tricky-situations-results.title-2',
    3: 'data.tricky-situations.tricky-situations-results.title-3',
    4: 'data.tricky-situations.tricky-situations-results.title-4',
    5: 'data.tricky-situations.tricky-situations-results.title-5',
  },
  titlesPoints: {
    1: [16],
    2: [0, 1, 2, 3, 4],
    3: [5, 6, 7, 8],
    4: [9, 10, 11, 12],
    5: [13, 14, 15],
  },
  sessions: {
    1: 'data.tricky-situations.tricky-situations-results.session-1',
    2: 'data.tricky-situations.tricky-situations-results.session-2',
    3: 'data.tricky-situations.tricky-situations-results.session-3',
    4: 'data.tricky-situations.tricky-situations-results.session-4',
  },
  sessionsQuestions: {
    1: [1, 2, 3, 4],
    2: [4],
    3: [5, 6, 7, 8, 9, 10, 11, 12],
    4: [13, 14],
  },
  lowScoringSituations: {
    1: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-1',
    2: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-2',
    3: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-3',
    4: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-4',
    5: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-5',
    6: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-6',
    7: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-7',
    8: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-8',
    9: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-9',
    10: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-10',
    11: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-11',
    12: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-12',
    13: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-13',
    14: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-14',
    15: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-15',
    16: 'data.tricky-situations.tricky-situations-results.low-scoring-situation-16',
  },
}
