<template>
  <base-main
    class="maintenance-results"
    data-testid="maintenance-results"
    :loading="$options.loading"
  >
    <container
      first
      flex-column
      plugin-article
    >
      <div>
        <plugin-article
          :testid="'maintenance-results'"
          :img="'quiz/maintenance-plan.png'"
        >
          <template slot="title">
            {{ $t('Maintenance plan') }}
          </template>

          <template slot="content">
            <ul class="maintenance-results-answers">
              <li
                v-for="(answer, i) in answers"
                :key="i"
              >
                {{ $t(answer) }}
              </li>
            </ul>
          </template>
        </plugin-article>

        <speech-bubble
          :testid="'maintenance-results'"
          :imageLink="coachImage"
        >
          {{ $t('views.plugins.maintenance-plan.maintenance-plan-results.coach-speach') }}
        </speech-bubble>
      </div>

      <div class="maintenance-results-buttons">
        <base-button
          align-end
          data-testid="maintenance-results-rerun"
          :link="{ name: 'MaintenancePlanQuiz', params: { isRerun: true } }"
        >
          {{ $t('Rerun') }}
        </base-button>

        <base-button
          align-end
          data-testid="maintenance-results-finish"
          :link="{ name: 'Tools' }"
        >
          {{ $t('Finish') }}
        </base-button>
      </div>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'

  import requireImage from '@/mixins/requireImage'
  import unblockPlugin from '@/mixins/unblockPlugin'

  const pluginName = 'maintenancePlan'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.maintenance-plan.maintenance-plan-results.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
      SpeechBubble,
    },

    mixins: [
      unblockPlugin,
      requireImage,
    ],

    created() {
      this.unblockPlugin()
      this.fetchAnswers(pluginName)
        .then(() => {
          this.answers
            ? this.getCoach()
            : this.$router.replace({ name: 'MaintenancePlanQuiz' })
        })
    },

    data() {
      return {
        result: null,
      }
    },

    methods: {
      ...mapActions('coaching', [
        'fetchCoach',
      ]),
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      getCoach() {
        if (this.coach) return

        this.fetchCoach({
          programId: this.currentProgramId,
          userId: this.getCurrentUserId,
        })
          .catch(() => {})
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      ...mapState('coaching', [
        'coach',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
      'coaching/fetchCoach',
    ],
  }
</script>

<style lang="scss">
  .maintenance-results {
    &-answers {
      padding: 1.5rem 3rem;
      list-style-type: disc;
      background-color: color(_white);
    }
    &-buttons {
      margin-left: auto;
    }
  }
</style>
