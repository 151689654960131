<template>
  <plugin-article
    has-background
    :img="'weekly-review/intro.png'">
      <template slot="title">
        {{ $t(`${$options.slug}.title`) }}
      </template>
      <template slot="content">
        <p>
          {{ $t(`${$options.slug}.content-1`) }}
        </p>
        <p class="goals-section-intro">
          {{ $t(`${$options.slug}.content-2`) }}
        </p>
        <grid>
          <grid-column
            v-for="(goal, i) in golsSummary"
            :key="i"
            xs-12>
              <goal-tile
                v-bind="goal"
              />
          </grid-column>
        </grid>
      </template>
  </plugin-article>
</template>

<script>
  import { mapState } from 'vuex'

  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import GoalTile from '@/components/tiles/goal-tile/GoalTile'

  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review-completed.meta.title'),
      }
    },

    components: {
      GoalTile,
      Grid,
      GridColumn,
      PluginArticle,
    },

    mixins: [
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()
    },

    computed: {
      ...mapState('weeklyReview', [
        'summary',
      ]),
      golsSummary() {
        return this.summary.map(goal => {
          const {
            title,
            first_question: firstQuestion,
            first_answer: firstAnswer,
            category: {
              name: plugin,
            },
          } = goal.profile

          return {
            answer: `${firstQuestion}: ${firstAnswer}`,
            title,
            plugin,
          }
        })
      },
    },

    slug: 'views.plugins.weekly-review.weekly-review-completed',
  }
</script>
