<template>
  <base-main
    background
    class="reading-room"
    :loading="$options.loading"
  >
    <div class="reading-room__wrapper">
      <reading-room-single-category
        ref="header"
        :description="details.description"
        :title="details.title"
        :headerLevel="1"
      />

      <div class="reading-simple-tile">
        <router-link
          v-for="(topic) in topicsArray"
          :key="topic.display_name"
          class="reading-simple-tile-router"
          :to="{
            name: 'ReadingRoomTopic',
            params: {
              topicId: topic.id,
            },
          }"
          data-testid="reading-simple-tile-router-link"
          >
            <simple-tile
              class="reading-simple-tile-topic"
              data-testid="reading-single-topic"
              >
              <div class="reading-simple-tile-topic-content">
                <h2 class="reading-simple-tile-topic-content__name">
                  {{ topic.display_name }}
                </h2>
                <img
                  class="reading-simple-tile-topic-content__img"
                  alt=""
                  :src="topic.hero
                    ? topic.hero.url
                    : image" >
              </div>
            </simple-tile>
        </router-link>
      </div>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import imagePlaceholder from '@/assets/img/image-placeholder-topic.png'
  import BaseMain from '@/components/base/BaseMain'
  import ReadingRoomSingleCategory from '@/components/reading-room/ReadingRoomSingleCategory'
  import SimpleTile from '@/components/tiles/simple-tile'

  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      const { name: title } = this.currentPlugin || {}

      return {
        title,
      }
    },

    components: {
      BaseMain,
      ReadingRoomSingleCategory,
      SimpleTile,
    },

    mixins: [
      unblockPlugin,
    ],

    created() {
      this.fetchTopics({ triggerDataId: this.triggerDataId })
        .then(() => {
          if (this.topics.length <= 1) {
            this.$router.push({ name: 'ReadingRoomTopic', params: { topicId: 'default' } })
          }
        })
      this.fetchOptionalContentDetails(this.triggerDataId)
      this.unblockPlugin()
    },

    methods: {
      ...mapActions('readingRoom', [
        'fetchTopics',
        'fetchOptionalContentDetails',
      ]),
    },

    computed: {
      ...mapGetters('triggers', [
        'getTriggerBySlug',
        'getTriggerDataIdByPluginName',
      ]),
      ...mapGetters('readingRoom', [
        'getDetailsByCategoryId',
        'getTopics',
      ]),
      ...mapState('readingRoom', [
        'topics',
      ]),
      topicsArray() {
        return this.topics || []
      },
      triggerDataId() {
        return this.getTriggerDataIdByPluginName('reading-room')
      },
      details() {
        return this.getDetailsByCategoryId(this.triggerDataId) || {}
      },
      currentTrigger() {
        return this.getTriggerBySlug('reading-room')
      },
      currentPlugin() {
        return this.currentTrigger?.plugin
      },
      image() {
        return imagePlaceholder
      },
    },

    loading: [
      'readingRoom/fetchOptionalContentDetails',
      'readingRoom/fetchTopics',
    ],

    slug: 'views.plugins.reading-room.reading-room',

    static() {
      return {
        headerDescription: `${this.$options.slug}.header-description`,
        headerTitle: `${this.$options.slug}.header-title`,
      }
    },
  }
</script>

<style lang="scss">
  .reading-room {
    .reading-simple-tile {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 30px;

      &-topic {
        min-height: 200px;
        height: 100%;
        margin-bottom: 0 !important;
        &-content {
          flex-direction: column;
          width: 100%;
          display: flex;
          justify-content: space-between;

          &__name {
            text-decoration: underline;
          }

          &__img {
            max-width: 100%;
            max-height: auto;
            min-height: auto;
            min-width: 100;
          }
        }
      }
    }
    &__wrapper {
      @include reading-room-wrapper;
    }
  }
</style>
