<template>
  <base-main
    class="step-tracking-settings"
    data-testid="step-tracking-settings"
    :loading="$options.loading"
  >
    <container
      first
    >
      <stepper-custom-form
        ref="stepper"
        :finishButtonText="$t('Finish')"
        :isLoading="isSaving"
        :isNextDisabled="isWaitingForSource"
        @cancel="onCancel"
        @submit="onSubmit"
        @step-changed="onStepChanged"
      >
        <template #default>

          <stepper-custom-step
            data-testid="step-tracking-settings-stepper-custom-step"
            :title="`${$options.slug}.title`"
          >
            <div class="step-tracking-settings__step">
              <div class="step-tracking-settings__description">
                <p>
                  <b>Please note:</b> If you switch from Automatic to Manual Tracking,
                  you will not be able to reconnect to your previous Automatic tracking method.
                </p>
                <p>
                  If you have any questions about your tracking data,
                  contact
                  <button
                    class="footer__wcag step-tracking-settings__support"
                    data-testid="footer-support-button"
                    @click="openSupport"
                  >{{ $t('Support').toLowerCase() }}
                  </button>
                  .
                </p>
                <!--
                <p>
                  <b>{{ $t(`${$options.slug}.description-1`) }}</b>
                </p>
                <p>
                  {{ $t(`${$options.slug}.description-2`) }}
                </p>
                <p>
                  {{ $t(`${$options.slug}.description-3`) }}
                </p>-->
              </div>

              <radio-multi-group
                class="step-tracking-settings__radios"
                data-testid="step-tracking-settings-methods-radios"
                :legend="$t(`${$options.slug}.method`)"
                :name="'method_step_tracking'"
                :options="$static.trackingMethods"
                :validation="$options.validation.method_step_tracking"
                v-model="form.method_step_tracking"
              />
            </div>
          </stepper-custom-step>

          <stepper-custom-step
            data-testid="step-tracking-settings-stepper-custom-step"
            :title="`${$options.slug}.data-source`"
          >
            <div class="step-tracking-settings__step">

              <template v-if="isAutomat">
                <div class="step-tracking-settings__description">
                  <p>
                    {{ $t(`${$options.slug}.description-4`) }}
                  </p>
                </div>
                <radio-multi-group
                  class="step-tracking-settings__radios"
                  data-testid="step-tracking-settings-systems-radios"
                  :legend="$t(`${$options.slug}.data-source-description`)"
                  :name="'tracking_system'"
                  :options="$static.trackingSystems"
                  :validation="$options.validation.tracking_system"
                  v-model="form.tracking_system"
                />
              </template>

              <template v-else>
                <div class="step-tracking-settings__description">
                  <p>
                    {{ $t(`${$options.slug}.description-5`) }}
                  </p>
                </div>
              </template>
            </div>
          </stepper-custom-step>

          <stepper-custom-step
            v-if="isAutomat"
            data-testid="step-tracking-settings-stepper-custom-step"
            :title="`${$options.slug}.connect-source`"
          >
            <div class="step-tracking-settings__step">
              <template
                v-if="isGoogle"
              >

                <template
                  v-if="isAccountConnected"
                >
                  <p v-html="$t(`${$options.slug}.google-connected`)"/>
                  <div class="step-tracking-settings__profile">
                    <tracking-source-profile
                      :email="form.account"
                      :imageUrl="form.imageUrl"
                      :name="form.name"
                    />
                  </div>
                  <div class="step-tracking-settings__change">
                    <base-button
                      no-margin
                      data-testid="step-tracking-settings-connect"
                      v-bind="{ isSaving }"
                      @click="connect"
                    >
                      {{ $t(`${$options.slug}.change-account`) }}
                    </base-button>
                  </div>
                </template>
                <template
                  v-else
                >
                  <div class="step-tracking-settings__description">
                    <p v-html="$t(`${$options.slug}.google-not-connected`)"/>
                  </div>
                  <div class="step-tracking-settings__actions">
                    <base-button
                      no-margin
                      data-testid="step-tracking-settings-connect"
                      v-bind="{ isSaving }"
                      @click="connect"
                    >
                      {{ connectText }}
                    </base-button>
                  </div>
                </template>
              </template>

              <template
                v-else-if="isApple"
              >
                <template
                  v-if="isAccountConnected"
                >
                  <p v-html="$t(`${$options.slug}.apple-connected`)"/>
                  <div class="step-tracking-settings__profile">
                    <tracking-source-profile
                      :name="form.account"
                    />
                  </div>
                </template>

                <template
                  v-else-if="isCordova"
                >
                  <div class="step-tracking-settings__description">
                    <p v-html="$t(`${$options.slug}.apple-not-connected`)"/>
                  </div>
                  <div class="step-tracking-settings__actions">
                    <base-button
                      no-margin
                      data-testid="step-tracking-settings-connect"
                      v-bind="{ isSaving }"
                      @click="connect"
                    >
                      {{ connectText }}
                    </base-button>
                  </div>
                </template>

                <template
                  v-else
                >
                  <div class="step-tracking-settings__description">
                    <p>
                      {{ $t(`${$options.slug}.description-6`) }}
                    </p>
                  </div>
                </template>
              </template>

              <template
                v-else
              >
                <div class="step-tracking-settings__description">
                  <p>
                    {{ $t(`${$options.slug}.description-7`) }}
                  </p>
                </div>
              </template>

            </div>
          </stepper-custom-step>

        </template>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import { RadioMultiGroup } from '@/components/forms/radio'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'
  import { TrackingSourceProfile } from '@/components/tracking'

  import { trackingSettingsBase } from '@/mixins/tracking-settings'

  import eventBus from '@/tools/event-bus'
  import healthKitManager from '@/tools/healthKitManager'

  const SLUG = 'views.plugins.step-tracking.step-tracking-settings'

  export default {
    metaInfo() {
      return {
        title: this.$t(`${SLUG}.meta.title`),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      RadioMultiGroup,
      StepperCustomForm,
      StepperCustomStep,
      TrackingSourceProfile,
    },

    mixins: [
      trackingSettingsBase,
    ],

    mounted() {
      this.initializeGapi()
    },

    beforeDestroy() {
      this.removeGapi()
    },

    data() {
      return {
        emailSystemType: null,
        form: {
          account: null,
          daily_steps_goal: null,
          device_name: null,
          device_uuid: null,
          google_code: false,
          method_step_tracking: '',
          steps_goal: '0',
          tracking_system: '',
        },
        gapiScript: null,
        googleAuth: null,
        isAccountConnected: false,
        isHealthkitPermitted: false,
        isIosAppReleased: false,
        step: 0,
      }
    },

    methods: {
      ...mapActions('stepTracking', [
        'saveSettings',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      // override
      fillForm() {
        Object.keys(this.form).forEach(key => {
          this.form[key] = this.settings[key]
        })
        if (this.form.account && this.form.tracking_system) {
          this.emailSystemType = this.form.tracking_system
          this.isAccountConnected = true
        }
      },
      initializeGapi() {
        this.gapiScript = document.createElement('script')
        this.gapiScript.src = 'https://apis.google.com/js/api:client.js?onload=onGapiLoad'
        document.body.appendChild(this.gapiScript)

        window.onGapiLoad = this.initGapi
      },
      initGapi() {
        /* eslint-disable no-undef */
        gapi.auth2.init({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          cookiepolicy: 'single_host_origin',
          scope: 'https://www.googleapis.com/auth/fitness.activity.read https://www.googleapis.com/auth/plus.login',
          access_type: 'offline',
          prompt: 'consent',
          fetch_basic_profile: true,
        }).then(res => {
          this.googleAuth = res

          this.googleAuth.currentUser.listen(currentUser => {
            const basicProfile = currentUser.getBasicProfile()

            this.form.account = basicProfile.getEmail()
            this.form.name = basicProfile.getName()
            this.form.imageUrl = basicProfile.getImageUrl()
            this.emailSystemType = 'google'
            this.isAccountConnected = true
          })
        })
      },
      removeGapi() {
        document.body.removeChild(this.gapiScript)
        this.gapiScript = null
      },
      async getGapiOfflineAccess() {
        try {
          const { code } = await this.googleAuth.grantOfflineAccess()

          this.form.google_code = code
        } catch (err) {
          if (err.error == 'popup_closed_by_user') return

          this.showSnackbar()
        }
      },
      initializeHealthKit() {
        if (this.isCordova) {
          window.plugins.healthkit.checkAuthStatus({
            type: 'HKQuantityTypeIdentifierStepCount',
          },
          () => {
            // has permission
            this.isHealthkitPermitted = true
          },
          () => {
            // doesn't have permission
            this.isHealthkitPermitted = false
          })
        }
      },
      connect() {
        if (this.isGoogle) {
          this.getGapiOfflineAccess()
        } else if (this.isApple) {
          healthKitManager.grantPermissions()
            .then(() => {
              this.form.device_uuid = window.device.uuid
              this.form.device_name = window.cordova.plugins.deviceName.name
              this.form.account = this.form.device_name
              this.emailSystemType = 'apple'
              this.isAccountConnected = true
              eventBus.$emit('healthKitEnabled', true)
            })
            .catch(() => {
              this.form.method_step_tracking = 'manual'

              this.showSnackbar()
            })
        }
      },
      onSubmit(isValid) {
        if (!isValid) return

        this.saveSettings(this.form)
          .then(() => this.$router.push({
            name: this.$static.mainComponent,
            params: { fromSettings: true },
          }))
          .catch(() => this.showSnackbar())
      },
      showSnackbar() {
        this.addSnackbar({ message: this.$t(`${this.$options.slug}.authorization-error`) })
      },
      onStepChanged(step) {
        this.step = step
      },
      openSupport() {
        this.$router.push({ name: 'Support' })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('layout', [
        'isCordova',
      ]),
      ...mapState('stepTracking', [
        'settings',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'stepTracking/saveSettings',
        ])
      },
      isApple() {
        return this.form.tracking_system == 'apple'
      },
      isAutomat() {
        return this.form.method_step_tracking == 'automat'
      },
      isGoogle() {
        return this.form.tracking_system == 'google'
      },
      trackingAppStatus() {
        return this.isAccountConnected
          && this.isEmailSystemEqualTrackingSystem
            ? 'isConnected'
            : 'notConnected'
      },
      isEmailVisible() {
        return this.emailSystemType && this.isEmailSystemEqualTrackingSystem
      },
      isEmailSystemEqualTrackingSystem() {
        return this.emailSystemType == this.form.tracking_system
      },
      connectText() {
        return this.isGoogle
                ? this.form.account
                  ? this.$t(`${this.$options.slug}.change-account`)
                  : this.$t(`${this.$options.slug}.connect-google`)
                : this.$t(`${this.$options.slug}.access`)
      },
      isOnLastStep() {
        const lastStep = this.$refs.stepper
          ?.$el?.querySelector('.stepper-custom__steps')?.childNodes?.length - 1

        return this.step === lastStep
      },
      isWaitingForSource() {
        if (!this.isOnLastStep || !this.isAutomat) return false

        return !this.isAccountConnected
      },
    },

    beforeRouteLeave({ name, params }, from, next) {
      name == this.$static.mainComponent && !params.fromSettings
        ? next({ name: 'Tools' })
        : next()
    },

    slug: SLUG,

    loading: [
      'stepTracking/fetchSettings',
    ],

    static() {
      return {
        mainComponent: 'StepTracking',
        module: 'stepTracking',
        trackingMethods: [
          /**
          {
            label: `${SLUG}.auto`,
            value: 'automat',
          }, */
          {
            label: `${SLUG}.everyday`,
            value: 'manual',
          },
        ],
        trackingSystems: [
          {
            label: 'Apple HealthKit',
            value: 'apple',
          },
          {
            label: 'Google Fit',
            value: 'google',
          },
        ],
      }
    },

    validation: {
      method_step_tracking: {
        required: true,
      },
      tracking_system: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .step-tracking-settings {
    &__description {
      &:last-child {
        margin: 0 0 1.8rem;
      }
      & > p:not(:first-child) {
        margin: 1.6rem 0 0;
      }
    }
    &__radios {
    }
    &__radio-label {
      margin: 0 0 1.6rem;
      font-weight: 600;
    }
    &__actions {
      margin: 1.8rem 0 0;
      &:last-child {
        margin: 1.8rem 0 1.8rem;
      }
    }
    &__profile {
      margin: 1.6rem 0;
    }
    &__change {
      display: flex;
      justify-content: center;
      @include min-md {
        justify-content: flex-start;
      }
      .button {
        max-width: 22rem;
        @include min-md {
          max-width: none;
        }
      }
    }
    &__support {
      padding: 0;
    }
    .container {
      max-width: 63rem;
    }
  }
</style>
