<template>
  <base-main>
    <div class="shopping-list-switcher-items">
      <container
        first
        flex-column
        plugin-items
      >
        <div>
          <stepper-custom
            ref="stepper"
            v-bind="{ buttonLabels, isNextDisabled }"
            @cancel="goBack"
            @finish="goToFirstStep"
          >
            <stepper-custom-step
              always-show-progress
            >
              <h2 class="shopping-list-switcher-items__text">
                {{ $t($static.header) }}
              </h2>
              <grid class="shopping-list-switcher-items__container">
                <grid-column
                  v-for="(item, index) in $static.items"
                  :key="item.index"
                  xs-12
                  sm-6
                  class="shopping-list-switcher-items__box"
                >
                  <!-- eslint-disable-next-line -->
                  <plugin-item
                    data-testid="shopping-list-switcher-items-item"
                    :item="item"
                    :is-selected="isSelected(index)"
                    :is-svg="isSvg(item.icon)"
                    @click="toggleSelect(index)"
                    @keydown.prevent.enter.space="toggleSelect(index)"
                  />
                </grid-column>
              </grid>
            </stepper-custom-step>

            <stepper-custom-step
              v-for="(id, i) in selectedItems"
              :key="i"
            >
              <div class="shopping-list-switcher-items__product">
                <inline-svg
                  v-if="isSvg($static.items[id].icon)"
                  aria-hidden="true"
                  :src="$static.items[id].icon"
                />

                <img
                  v-else
                  alt=""
                  :src="require(`@/assets/img/${$static.items[id].icon}`)"
                >

                <h2 class="shopping-list-switcher-items__product-title">
                  {{ $t($static.items[id].title) }}
                </h2>

                <p class="shopping-list-switcher-items__product-description">
                  {{ $t($static.items[id].description) }}
                </p>
              </div>
            </stepper-custom-step>
          </stepper-custom>
        </div>
      </container>
    </div>
  </base-main>
</template>

<script>
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import PluginItem from '@/components/plugin/plugin-item/PluginItem'
  import StepperCustom from '@/components/stepper-custom/StepperCustom'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import shoppingListSwitcherData from '@/data/shopping-list-switcher'

  import goBack from '@/mixins/goBack'
  import requireImage from '@/mixins/requireImage'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.shopping-list-switcher.shopping-list-switcher-items.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      Grid,
      GridColumn,
      PluginItem,
      StepperCustom,
      StepperCustomStep,
    },

    mixins: [
      goBack,
      requireImage,
    ],

    data() {
      return {
        selectedItems: [],
      }
    },

    methods: {
      isSelected(i) {
        return this.selectedItems.includes(i)
      },
      toggleSelect(i) {
        this.isSelected(i)
          ? this.selectedItems = this.selectedItems.filter(j => j != i)
          : this.selectedItems.push(i)
      },
      isSvg(path) {
        return path.includes('svg')
      },
      goToFirstStep() {
        this.$refs.stepper.changeStep(0)
        this.selectedItems = []
      },
    },

    computed: {
      isNextDisabled() {
        return this.noSelectedItems
      },
      buttonLabels() {
        return this.noSelectedItems
          ? { finishLabel: 'Next' }
          : { finishLabel: 'Start again' }
      },
      noSelectedItems() {
        return this.selectedItems.length === 0
      },
    },

    static() {
      return {
        items: shoppingListSwitcherData.items,
        header: 'views.plugins.shopping-list-switcher.shopping-list-switcher-items.header',
      }
    },
  }
</script>

<style lang="scss">
  .shopping-list-switcher-items {
    &__box {
      padding: 1rem;
    }
    &__container {
      @include max-sm {
        width: 80%;
        margin: 0 auto;
      }
      @media(max-width: 480px) {
        width: 100%;
      }
    }
    &-column {
      display: flex;
    }
    &__product {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-title {
        font-weight: 600;
        margin-bottom: 2rem;
        text-align: center;
      }
      &-svg {
        margin: 0 auto;
      }
      span {
        text-align: center;
      }
      img,
      svg {
        height: 10rem;
        margin: 1.5rem auto;
        max-width: 130px;
        width: auto;
      }
    }
    &__text {
      margin: 0 0 1.5rem;
      text-align: center;
      @media all and (min-height: 600px) {
        margin: 2rem 0;
      }
    }
    .stepper-custom-step__progress-indicator {
      line-height: 1.2rem;
    }
  }
</style>
