<template>
  <base-main>
    <div
      class="shopping-list-switcher"
      data-testid="shopping-list-switcher"
    >
      <simple-tile>
        <plugin-article
          :img="'food/bread-mushroom.png'"
        >
          <template slot="title">
            {{ $t('Shopping List Switcher') }}
          </template>

          <template slot="content">
            <p>{{ $t($static.paragraphs[0]) }}</p>

            <p>{{ $t($static.paragraphs[1]) }}</p>

            <p>
              <b>{{ $t($static.paragraphs[2]) }}</b>
            </p>

            <div class="shopping-list-switcher__buttons">
              <base-button
                align-end
                data-testid="shopping-list-switcher-next"
                :link="{ name: 'ShoppingListSwitcherItems' }"
              >
                {{ $t('Next') }}
              </base-button>
            </div>
          </template>
        </plugin-article>
      </simple-tile>
    </div>
  </base-main>
</template>

<script>
  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import SimpleTile from '@/components/tiles/simple-tile'

  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.shopping-list-switcher.shopping-list-switcher.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      PluginArticle,
      SimpleTile,
    },

    mixins: [
      unblockPlugin,
    ],

    mounted() {
      this.unblockPlugin()
    },

    static() {
      return {
        paragraphs: [
          'views.plugins.shopping-list-switcher.shopping-list-switcher.paragraph-1',
          'views.plugins.shopping-list-switcher.shopping-list-switcher.paragraph-2',
          'views.plugins.shopping-list-switcher.shopping-list-switcher.paragraph-3',
        ],
      }
    },
  }
</script>

<style lang="scss">
  .shopping-list-switcher {
    padding: 2.4rem;
    max-width: 77.5rem;
    width: 100%;
    .plugin-article {
      width: 100%;
    }
    &__buttons {
      display: flex;
    }
  }
</style>
