<script>
  export default {
    functional: true,

    render(h, { data, children }) {
      const { attrs, staticClass, ...newData } = data
      const classes = Object.keys(attrs).map(key => `grid-col--${key}`)

      return h(
        'div', {
          staticClass: ['grid-col'].concat(classes, staticClass).join(' ').trim(),
          ...newData,
        }, children,
      )
    },
  }
</script>

<style lang="scss">
  .grid {
    &-col {
      @each $key, $value in $breakpoints {
        @media all and (min-width: $value) {
          @for $i from 1 through 12 {
            &--#{$key}-#{$i} {
              flex: 0 0 percentage($i / 12);
              max-width: percentage($i / 12);
              padding: 4px;
            }
          }
        }
      }
      @for $i from 1 through 12 {
        &--journey-#{$i} {
          flex: 0 0 ($journey_box * $i);
          max-width: ($journey_box * $i);
          margin-bottom: $journey_box;
        }
      }
    }
  }
</style>
