<script>
  export default {
    functional: true,

    props: {
      currentSlide: {
        type: Number,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },

    render(h, { children, props }) {
      const isCurrent = props.currentSlide == props.index

      return h('li', {
        staticClass: 'slider-slide',
        class: {
          'slider-slide--is-current': isCurrent,
        },
      }, children)
    },
  }
</script>

<style lang="scss">
  .slider {
    &-slide {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: translate3d(-100%, 0, 0);
      transition: transform 0.5s ease;
      &--is-current {
        transform: translate3d(0%, 0, 0);
        & ~ .slider-slide {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }
</style>
