<template>
  <base-main
    data-testid="food-tracking-log"
    class="food-tracking-log"
    :loading="$options.loading && foodCharged"
  >
    <container
      first
    >
     <stepper-custom-form
        v-if="foodCharged"
        ref="form"
        data-testid="food-tracking-log-form"
        :buttonLabels="{
          finishLabel: showWarning ? 'Yes, continue' : i18n('log-food'),
          previousLabel: showWarning ? 'No, cancel' : 'Previous'
        }"
        :class="{ 'stepper-form--overflow-visible': overflowVisible }"
        :isLoading="isSaving"
        @cancel="goBack"
        @submit="onSubmit"
        @previous="onPrevious"
        @next="onNext"
      >
        <template #default>
          <stepper-custom-step
            v-for="(formField, i) in dataFields"
            :key="i"
            data-testid="weight-tracking-log-stepper-custom-step"
            :title="titleFormField(formField)"
            :description="i === 0
              && isEdit()
              ? 'You are editing a log for the date below'
              : formField.description"

          >
            <div
              v-if="!showWarning && formField && formField.type !== 'ImageGroup'"
              class="log-weight-tracking__container"
              data-testid="log-weight-tracking-content"
            >
              <form-fields-generator-field
                :dataModel="form"
                v-bind="{ field: formField }"
              />
            </div>
            <div
              v-if="!showWarning && formField.type === 'ImageGroup'"
              class="food-log__image"
              :class="imageClass"
            >
              <image-group
                  ref="imageGroup"
                  :captureImageText="formField.props.captureImageText"
                  :uploadImageText="formField.props.uploadImageText"
                  :label="formField.label"
                  :name="'food_log_image'"
                  :secondDescription="formField.props.secondDescription"
                  :testid="'food-tracking-log'"
                  :validation="formField.props.validation"
                  :imageUrl="formField.props.imageUrl"
                  v-model="image"
              />
            </div>
            <div v-if="showWarning">
              <h2>
                This will replace all weight data previously recorded for this date.
              </h2>
              <h2>Do you wish to continue?</h2>
            </div>
          </stepper-custom-step>
        </template>
     </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'
  import FormFieldsGeneratorField from '@/components/forms/form-fields-generator/FormFieldsGeneratorField'
  import ImageGroup from '@/components/forms/image-group/ImageGroup'

  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'
  import { DATE_FORMATS } from '@/constants'
  import clickOutside from '@/directives/clickOutside'

  import capitalize from '@/filters/capitalize'

  import goBack from '@/mixins/goBack'
  import i18n from '@/mixins/i18n'
  import requireImage from '@/mixins/requireImage'
  import shouldFetch from '@/mixins/shouldFetch'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.food-tracking.food-tracking.log-or'),
      }
    },

    directives: {
      clickOutside,
    },

    components: {
      BaseMain,
      Container,
      StepperCustomForm,
      StepperCustomStep,
      ImageGroup,
      FormFieldsGeneratorField,
    },

    filters: {
      capitalize,
    },

    mixins: [
      requireImage,
      shouldFetch,
      i18n,
      goBack,
    ],

    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
      date: {
        type: String,
        required: false,
        default: '',
      },
    },

    mounted() {
      if (!this.id) return

      this.fetchFood(this.id).then(() => {
        this.fillForm()
      })
    },

    created() {
      if (!this.isEdit()) {
        this.form.date = moment(this.date).format(DATE_FORMATS.dateShort)
        this.form.hour = moment().format(DATE_FORMATS.time)
        this.foodCharged = true
      }
    },

    watch: {
      /* eslint-disable-next-line object-shorthand */
      async 'form.description'() {
        await this.$nextTick()
        this.$refs.imageGroup.$refs?.inputs.$refs.provider.validate()
      },
    },

    data() {
      return {
        form: {
          image_id: '',
          description: '',
          notes: '',
          label_foods: undefined,
          date: '',
          hour: '',
        },
        image: '',
        imageUrl: null,
        isValid: true,
        foodCharged: false,
        showWarning: false,
        currentStep: 0,
        overflowVisible: false,
        TIMEOUT_OVERFLOW_VISIBLE: 500,
      }
    },

    methods: {
      ...mapActions('foodTracking', [
        'addFood',
        'addImage',
        'editFood',
        'fetchFood',
        'fetchFoodsByDateTime',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      fillForm() {
        Object.keys(this.form).forEach(key => {
          if (this.food[key]) {
            this.form[key] = this.food[key]
          }
        })
        const [date, time] = this.food.date.split(' ')
        this.form.date = date
        this.form.hour = time

        if (this.food.files) this.imageUrl = this.food.files.url
        this.foodCharged = true
      },
      async onSubmit(isValid) {
        const dateTime = moment(`${this.form.date} ${this.form.hour}`).format('YYYY-MM-DDTHH:mm:ss')
        const updatedDateOrHour = this.food ? moment(this.food.date).format('YYYY-MM-DDTHH:mm:ss') !== dateTime : true
        if (!this.showWarning && updatedDateOrHour && !this.isEdit()) {
          const result = await this.fetchFoodsByDateTime(dateTime)
          if (result && result.error === undefined) {
            this.showWarning = true
            return
          }
        }
        this.isValid = isValid

        if (!this.isValid || this.isSaving) return

        const handleImage = () => (
          this.image
            ? this.uploadImage()
            : Promise.resolve()
        )

        handleImage()
          .then(this.addOrEditFood)
      },
      uploadImage() {
        return new Promise((resolve, reject) => {
          if (!this.image) {
            resolve()
            return
          }

          this.form.image_id = null
          const imageFormData = new FormData()
          imageFormData.append('file', this.image)

          this.addImage(imageFormData)
            .then(id => {
              this.form.image_id = id
              resolve()
            })
            .catch(error => {
              this.addSnackbar({ message: error })
              reject()
            })
        })
      },
      timestamp() {
        const date = moment(this.form.date)
        const hour = moment
          .utc(this.form.hour, 'HH:mm')
          .utcOffset(this.getTimezoneOffset, true)

        return date.set({
          hour: hour.hour(),
          minute: hour.minutes(),
        })
      },
      addOrEditFood() {
        const formData = new FormData()
        const form = {
          image_id: this.form.image_id,
          description: this.form.description,
          notes: this.form.notes,
          label_foods: this.form.label_foods,
          date: this.timestamp(),
        }

        Object.entries(form).forEach(([key, value]) => {
          if (value) {
            formData.append(key, value)
          }
        })

        this[`${this.action}Food`]({ form: formData, id: this.food?.id })
          .then(() => {
            if (typeof this.callback === 'function') this.callback()

            this.addSnackbar({ message: this.$t('Food Tracking log saved') })
            this.goBack()
          })
      },
      titleFormField(formField) {
        return formField?.props?.legend || formField?.props?.label || ''
      },
      isEdit() {
        return !!this.id
      },
      setOverflowVisible() {
        if (this.currentStep === 1) {
          setTimeout(() => {
            this.overflowVisible = true
          }, this.TIMEOUT_OVERFLOW_VISIBLE)
        } else {
          this.overflowVisible = false
        }
      },
      onNext() {
        this.currentStep += 1
        this.setOverflowVisible()
      },
      onPrevious() {
        this.currentStep -= 1
        if (this.showWarning) {
          this.goBack()
        }
        this.setOverflowVisible()
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('triggers', [
        'getTriggerBySlug',
        'getTriggerSettingsByName',
      ]),
      ...mapState('triggers', [
        'triggers',
      ]),
      ...mapState('foodTracking', [
        'food',
      ]),
      ...mapGetters('user', [
        'getTimezoneOffset',
      ]),
      // ...mapState('layout', [
      //   'isStepperContentVisible',
      // ]),
      dataFields() {
        return [
          {
            type: 'CalendarPickerGroup',
            class: 'food-tracking__form-field',
            name: 'date',
            props: {
              format: DATE_FORMATS.dateShort,
              formatted: DATE_FORMATS.date,
              hasHiddenAsterisk: true,
              label: 'Select the date of your tracking',
              maxDate: moment
                .utc()
                .utcOffset(this.getTimezoneOffset)
                .format('YYYY-MM-DD 23:59'),
              minDate: moment
                // eslint-disable-next-line camelcase
                .utc(this.settings?.created_at, 'YYYY-MM-DD HH:mm:ss')
                .utcOffset(this.getTimezoneOffset)
                .format('YYYY-MM-DD 00:00'),
              testid: 'food-tracking__date',
              outputFormat: DATE_FORMATS.dateShort,
              validation: { required: true },
              required: true,
              disabled: this.isEdit(),
            },
          },
          {
            type: 'HourPickerGroup',
            name: 'hour',
            class: 'food-tracking__form-field',
            props: {
              legend: 'Time you had this meal',
              label: 'Hour and minutes',
              format: 'HH:mm:ss',
              testid: 'food-tracking__time',
              validation: { required: true },
            },
          },
          {
            type: 'ImageGroup',
            name: 'image',
            class: 'food-tracking-log',
            description: "This step is optional: Select 'next' to continue without a photo",
            props: {
              hasHiddenAsterisk: true,
              label: this.i18n('add-image'),
              captureImageText: this.i18n('capture-food-image'),
              uploadImageText: this.i18n('upload-food-image'),
              secondDescription: this.errorId,
              imageUrl: this.imageUrl,
              value: this.image,
              validation: this.$options.validation.image,
              testid: 'food-tracking__image',
            },
          },
          {
            type: 'InputGroup',
            name: 'description',
            class: 'food-tracking-log',
            props: {
              hasHiddenAsterisk: true,
              label: this.i18n('describe'),
              placeholder: this.i18n('enter-food'),
              type: 'text',
              validation: this.$options.validation.description,
              testid: 'food-tracking__describe',
            },
          },
          {
            type: 'InputGroup',
            name: 'notes',
            class: 'food-tracking-log',
            description: "This step is optional: Select 'next' to continue without providing a reason",
            props: {
              required: false,
              hasHiddenAsterisk: true,
              label: this.i18n('notes-label'),
              placeholder: this.i18n('enter-reason'),
              type: 'text',
              validation: this.$options.validation.notes,
              testid: 'food-tracking__notes',
              maxLength: 300,
            },
          },
          {
            type: 'RadioMultiGroup',
            name: 'label_foods',
            class: 'food-tracking-log',
            description: "This step is optional: Select 'Log food' to continue without providing a label",
            props: {
              hasHiddenAsterisk: true,
              isLabelHidden: true,
              label: this.i18n('food-label'),
              placeholder: this.i18n('select-food'),
              showLabel: false,
              options: this.foodCategories,
              validation: this.$options.validation.label_foods,
              testid: 'food-tracking__foods',
              valueKey: 'key',
              labelKey: 'key',
            },
          },
        ]
      },
      action() {
        return this.isEdit()
          ? 'edit'
          : 'add'
      },
      foodCategories() {
        const settings = this.getTriggerSettingsByName('food')

        if (!settings?.optional) return

        const categories = settings.optional.allowedColors

        return Object.entries(categories)
                .reduce((acc, [key, value]) => {
                  if (value) {
                    if (key === 'green') {
                      acc.push({ key, description: 'Use a Green label for foods that you want to eat more of' })
                    } else if (key === 'red') {
                      acc.push({ key, description: 'Use a Red label for foods that you want to eat less of' })
                    } else {
                      acc.push({ key, description: '' })
                    }
                  }
                  return acc
                }, [])
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'foodTracking/addFood',
          'foodTracking/addImage',
          'foodTracking/editFood',
        ])
      },
      imageClass() {
        const imageClass = `food-log__image--${this.form.label_foods}`
        return {
          [imageClass]: this.form.label_foods,
        }
      },
      nameOrPhotoIsValid() {
        return this.isValid || (this.image || this.form.description)
      },
      errorId() {
        return this.nameOrPhotoIsValid ? null : `error-info-${this.uuid}`
      },
      foodLogLegend() {
        const { slug } = this.$static
        const requiredLabel = this.$options.validation.label_foods.required ? `(${this.$t('required')})` : ''
        const foodLabel = `${this.$t(`${slug}.food-label`)} ${requiredLabel}`
        const descriptionLabel = this.$t(`${slug}.log-description`)

        return `
          <span
            class="food-log__field-label"
            data-testid="food-tracking-log-label"
          >
            ${foodLabel}
          </span>
          <span
            class="food-log__description"
            data-testid="food-tracking-log-description"
          >
            ${descriptionLabel}
          </span>
        `
      },
      currentTrigger() {
        return this.getTriggerBySlug('food-tracking')
      },
      currentPlugin() {
        return this.currentTrigger?.plugin
      },
    },

    slug: 'component.dialog.food-tracking-log-dialog',

    validation: {
      description: {
        required: true,
      },
      image: {
        required: false,
      },
      notes: {
        max: 300,
      },
    },
  }
</script>

<style lang="scss">
  .food-tracking-log {
    .stepper-form--overflow-visible {
      .stepper-custom__content {
        overflow: visible;
      }
    }
  }
  .food-log {
    &__field-label {
      font-weight: 600;
    }
    &__image {
      margin: 1rem 0 0;
      &--red {
        .image-group__img,
        .image-placeholder {
          border: 2px solid color(food-red);
        }
      }
      &--green {
        .image-group__img,
        .image-placeholder {
          border: 2px solid color(food-green);
        }
      }
      .image-group {
        flex-direction: column;
        @include min-lg {
          flex-direction: row;
        }
        &__preview {
          margin: 0;
          @include min-lg {
            margin: 0 2.5rem 0 0;
          }
        }
        &__buttons {
          .button {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
    &__top-error {
      margin: 0 0 3rem;
    }
    &__label {
      margin: 2.2rem 0 0;
      .select-group {
        margin: 1rem 0 0;
        padding: 0;
        font-weight: 400;
        &__label {
          display: none;
        }
      }
    }
    &__description {
      margin: 0.5rem 0 0;
      color: color(dark-grey);
      font-weight: 400;
    }
    .modular-tile {
      max-width: 37rem;
      @include min-lg {
        max-width: 39rem;
      }
    }
    .input-group {
      margin: 2.2rem 0 0;
      &__label {
        &::after {
          display: none;
        }
      }
    }
    .form__end-row {
      margin: 3rem 0 2rem;
    }
    .input-group__input,
    .multiselect--active .multiselect__placeholder,
    .multiselect__placeholder {
      font-size: 1.4rem;
    }
  }
</style>
