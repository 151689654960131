<template>
  <base-main
    class="getting-confident-results"
    :loading="$options.loading"
    data-testid="getting-confident-results"
  >
    <container
      v-if="result"
      first
      flex-column
      plugin-article>
        <div>
          <plugin-article
            has-background
            :img="'quiz/getting-confident-quiz.png'">
              <template slot="title">
                {{ $t(result.title) }}
              </template>
              <template slot="content">
                <template
                  v-if="isUserSuccessful">
                    <ul>
                      <li
                        v-for="(item, i) in result.answerSituation"
                        :key="i">
                          {{ $t(item) }}
                      </li>
                    </ul>

                    <p>{{ $t($static.successParagraph) }}</p>
                </template>

                <template
                  v-else>
                    <p>{{ $t($static.firstAlternateParagraph) }}</p>
                    <p>{{ $t($static.secondAlternateParagraph) }}</p>
                    <p>{{ $t($static.successParagraph) }}</p>
                </template>
              </template>
          </plugin-article>
        </div>

        <base-button
          align-end
          data-testid="getting-confident-results-rerun-button"
          :link="{ name: capitalizedPluginName, params: { isRerun: true } }"
        >
          {{ $t('Rerun quiz') }}
        </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import results from '@/data/getting-confident-quiz/gettingConfidentQuizResults'

  import capitalize from '@/filters/capitalize'

  import unblockPlugin from '@/mixins/unblockPlugin'

  const pluginName = 'gettingConfidentQuiz'
  const minPointsAmountToSucceed = 3

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.gcq.getting-confident-results.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()

      if (this.answers) {
        return this.getResult()
      }

      this.fetchAnswers(pluginName)
        .then(() => {
          this.answers
            ? this.getResult()
            : this.$router.replace({ name: this.capitalizedPluginName })
        })
    },

    data() {
      return {
        result: null,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      getResult() {
        const { titles, answerSituations } = results

        this.result = {
          title: titles[this.isUserSuccessful],
          answerSituation: Object.values(answerSituations)
                            .filter((value, i) => this.answers[i + 1] >= minPointsAmountToSucceed),
        }
      },
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      capitalizedPluginName() {
        return capitalize(pluginName)
      },
      isUserSuccessful() {
        return Object.values(this.answers)
                .some(item => item >= minPointsAmountToSucceed)
      },
    },

    static() {
      return {
        successParagraph: 'views.plugins.gcq.getting-confident-results.success-paragraph',
        firstAlternateParagraph: 'views.plugins.gcq.getting-confident-results.first-alternate-paragraph',
        secondAlternateParagraph: 'views.plugins.gcq.getting-confident-results.second-alternate-paragraph',
      }
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],
  }
</script>

<style lang="scss">
  .getting-confident-results {
    ul {
      list-style-type: disc;
      padding: 0 0 0 1.8rem;
      margin: 0 0 2rem;
    }
  }
</style>
