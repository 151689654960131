export default [
  {
    id: 1,
    label: 'data.reflecting-quiz.label-1',
    question: 'data.reflecting-quiz.question-1',
  },
  {
    id: 2,
    label: 'data.reflecting-quiz.label-2',
    question: 'data.reflecting-quiz.question-2',
  },
  {
    id: 3,
    label: 'data.reflecting-quiz.label-3',
    question: 'data.reflecting-quiz.question-3',
  },
  {
    id: 4,
    label: 'data.reflecting-quiz.label-4',
    question: 'data.reflecting-quiz.question-4',
  },
]
