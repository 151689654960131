<template functional>
  <div class="goal-tile-container">
    <p class="goal-tile-container-plugin">
      {{ props.plugin }}
    </p>
    <h2>
      {{ props.title }}
    </h2>
    <p>
      {{ props.answer }}
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      plugin: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      answer: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .goal-tile-container {
    padding: 1.5rem 2rem;
    background-color: color(light-primary);
    border-radius: 2px;
    &-plugin {
      margin: 0 0 0.35rem 0;
      font-size: 1.3rem;
    }
  }
</style>
