<template>
  <grid-column v-bind="sizes">

    <tag
      :tag="tag"
      :to="link">

        <figure
          class="image-list-item"
          :class="{ 'image-list-item--no-background': !hasBackground }">

            <div class="image-list-background">
              <img
                v-if="image"
                :src="image"
                :alt="alt">
            </div>

            <figcaption
              v-if="title"
              class="image-list-caption">
                <span class="image-list-title">
                {{ title }}
              </span>
            </figcaption>

          <food-dot
            v-if="hasDot"
            :color="dotColor"
            :type="'absolute'"/>

        </figure>

    </tag>

  </grid-column>
</template>

<script>
  import FoodDot from '@/components/food-dot/FoodDot'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import Tag from '@/components/layout/tag/Tag'

  export default {
    components: {
      GridColumn,
      FoodDot,
      Tag,
    },

    props: {
      columnSizes: {
        type: Array,
        required: false,
        default: () => ['xs-4'],
      },
      dotColor: {
        type: String,
        required: false,
        default: '',
      },
      hasBackground: {
        type: Boolean,
        required: false,
        default: true,
      },
      hasDot: {
        type: Boolean,
        required: false,
        default: false,
      },
      image: {
        type: String,
        required: false,
        default: '',
      },
      link: {
        type: Object,
        required: false,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
    },

    computed: {
      alt() {
        return this.title
                ? this.title
                : 'Food'
      },
      tag() {
        return this.link
                ? 'router-link'
                : 'div'
      },
      sizes() {
        return this.columnSizes.reduce((acc, curr) => Object.assign(acc, { [curr]: '' }), {})
      },
    },
  }
</script>

<style lang="scss">
  .image-list {
    &-item {
      position: relative;
      padding-top: 100%;
      &--no-background {
        .image-list-background {
          background-color: transparent;
        }
      }
    }
    &-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: color(dark-primary);
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &-caption {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      left: 0;
      height: 3.5rem;
      width: 100%;
      padding: 1rem;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: color(dark-primary);
        opacity: 0.7;
        pointer-events: none;
      }
    }
    &-title {
      position: relative;
      color: color(_white);
      font-size: 1.4rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
