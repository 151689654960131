<template>
  <plugin-article
    :img="'weekly-review/weight.png'"
  >
    <template slot="title">
      {{ $t('Record your Weight') }}
    </template>

    <template slot="content">
      <div class="weight-log">
        <p>
          {{ i18n('content-1') }}
        </p>
        <p>
          {{ i18n('content-2') }}
        </p>

        <div
            v-if="weight && !reviewMode"
            class="weekly-review__logged"
          >
          <div class="weekly-review__logged-content">
              {{ i18n('logged') }}
              <div class="weekly-review__logged-weight">
                  <span class="weekly-review__last-weight">
                    {{ properWeightValue }} {{ weightUnit }}
                  </span>
                    {{ weightDate }}
              </div>
          </div>

          <base-button
            data-testid="weekly-review-fill-in-weight"
            @click="fillInWeight"
          >
              {{ i18n('use-this') }}
          </base-button>
        </div>

        <base-form
          ref="form"
          :hasSubmitButton="false"
          @submit="onSubmit"
        >
          <h2>{{ $t("Weight") }}</h2>
          <input-group
            class="weekly-review__input"
            :label=" $t('Weight') + ` (${weightUnit})`"
            :type="'number'"
            :name="'weight'"
            :validation="$options.validation.weight"
            v-model.number="defaultWeight"
          />
            <ul class="weight-log__info bullet">
              <li>
                {{ i18n('read') }}
                <weekly-review-dialog-link
                  v-bind="{ dialogLink }"
                />
              </li>

              <li>
                {{ i18n('covid') }}
              </li>
            </ul>
          <div
            v-if="reviewMode"
            class="weight-log__image-container"
          >
            <h2>
              {{ i18n('content-3') }}
            </h2>

            <p>
              {{ i18n('content-4') }}
            </p>

            <p class="weight-log__content-covid">
              {{ i18n('content-5') }}
            </p>

            <div class="weight-log__image-group">
              <image-group
                :alt="$t('Image preview')"
                :captureImageText="i18n('capture-weight-image')"
                :imageUrl="loggedWeightImage ? loggedWeightImage.url : null"
                :name="'weight_log_image'"
                :uploadImageText="i18n('upload-weight-image')"
                :validation="$options.validation.image"
                v-model="image"
              />
            </div>
          </div>
        </base-form>
      </div>
    </template>
  </plugin-article>
</template>

<script>
  import moment from 'moment'

  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseForm from '@/components/base/BaseForm'
  import ImageGroup from '@/components/forms/image-group/ImageGroup'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import WeeklyReviewDialogLink from '@/components/weekly-review/WeeklyReviewDialogLink'

  import {
    DATE_FORMATS,
    SPECIAL_WEIGHT_VALUE,
  } from '@/constants'

  import { links } from '@/data/weekly-review/weeklyReviewDialogs'

  import { kgToLbs, lbsToKg } from '@/helpers/weightConverter'

  import i18n from '@/mixins/i18n'
  import weeklyReviewSubmit from '@/mixins/weekly-review/weeklyReviewSubmit'

  import eventBus from '@/tools/event-bus'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review-weight.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseForm,
      ImageGroup,
      InputGroup,
      PluginArticle,
      WeeklyReviewDialogLink,
    },

    mixins: [
      i18n,
      weeklyReviewSubmit,
    ],

    mounted() {
      eventBus.$on('weeklyReview/onNext', this.savePhoto)
      this.setWeightValidation()
    },

    beforeDestroy() {
      eventBus.$off('weeklyReview/onNext')
    },

    watch: {
      defaultWeight() {
        this.parseWeight()
      },
    },

    data() {
      return {
        defaultWeight: null,
        dialogLink: links.weight,
        form: {
          weight: null,
          step_type: 'weight',
        },
        image: '',
      }
    },

    methods: {
      ...mapActions('weeklyReview', [
        'addImage',
      ]),
      fillInWeight() {
        this.defaultWeight = this.properWeightValue
      },
      savePhoto() {
        this.form.image_id
          ? this.onSubmit(this.skipFeedbackMessage)
          : this.uploadImage()
              .then(() => this.onSubmit(this.skipFeedbackMessage))
      },
      uploadImage() {
        return new Promise((resolve, reject) => {
          if (!this.image) {
            resolve()
            return
          }

          const imageFormData = new FormData()
          imageFormData.append('file', this.image)

          this.addImage(imageFormData)
            .then(id => {
              this.form.image_id = id
              resolve()
            })
            .catch(error => {
              this.error = error
              this.isSnackbarOpen = true
              reject()
            })
        })
      },
      parseWeight() {
        this.form.weight = this.isWeightInKg || this.defaultWeight === SPECIAL_WEIGHT_VALUE
          ? this.defaultWeight
          : lbsToKg(this.defaultWeight)
      },
      setWeightValidation() {
        this.$options.validation.weight.min_value = this.isWeightInKg ? 20 : kgToLbs(20)
        this.$options.validation.weight.max_value = SPECIAL_WEIGHT_VALUE
      },
    },

    computed: {
      ...mapGetters('user', [
        'getCurrentUserWeightUnit',
      ]),
      ...mapState('layout', [
        'isMobile',
      ]),
      ...mapState('weeklyReview', [
        'loggedWeightImage',
        'loggedWeight',
        'reviewMode',
      ]),
      isWeightInKg() {
        return this.weightUnit === 'kg'
      },
      weightUnit() {
        return this.getCurrentUserWeightUnit.abbr
      },
      properWeightValue() {
        return this.isWeightInKg
          ? this.weight
          : kgToLbs(this.weight)
      },
      weight() {
        return this.loggedWeight?.weight
      },
      weightDate() {
        return moment(this.loggedWeight?.at).format(DATE_FORMATS.dateTime)
      },
      skipFeedbackMessage() {
        return this.form.weight === SPECIAL_WEIGHT_VALUE
      },
    },

    slug: 'views.plugins.weekly-review.weekly-review-weight',

    validation: {
      weight: {
        required: true,
        decimal: 2,
        min_value: 20,
        max_value: SPECIAL_WEIGHT_VALUE,
      },
      image: {
        required: true,
        mimes: ['image/jpeg', 'image/png'],
        size: 10240,
      },
    },
  }
</script>

<style lang="scss">
  .weight-log {
    &__last-weight {
      margin: 0 0.5rem 0 0;
      font-size: 1.5rem;
      font-weight: 500;
      @media all and (min-width: map-get($breakpoints, md)) {
        font-size: 1.7rem;
      }
    }
    &__info {
      margin: 1.8rem 0 0;
    }
    &__image-group {
      margin: 2.2rem 0 0;
    }
    &__content-covid {
      margin-top: 1rem;
    }
  }
  .weekly-review {
    &__logged {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 3rem 0;
      @include min-sm {
        justify-content: flex-start;
      }
    }
    &__logged-content {
      font-weight: bold;
      @include min-sm {
        margin: 0 2rem 0 0;
      }
    }
    &__logged-weight {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      margin: 0.3rem 0 0;
      color: color(dark-primary);
      font-size: 1.3rem;
    }
    &__last-weight {
      margin: 0 0.5rem 0 0;
      font-size: 1.5rem;
      @include min-sm {
        font-size: 1.7rem;
      }
    }
  }
</style>
