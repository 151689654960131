var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-main',{staticClass:"reading-room-article",class:( _obj = {}, _obj[("reading-room-article--is-" + _vm.articleType)] = !!_vm.articleType, _obj ),attrs:{"background":"","data-testid":"reading-room-article"}},[_c('div',{staticClass:"reading-room-article__wrapper"},[_c('transition-article',[_c('base-article',_vm._b({key:_vm.transitionKey,attrs:{"allow-favourite":"","is-reading-room":"","item":_vm.currentItem,"testid":'reading-room-article'},on:{"clickFavourite":_vm.onClickFavourite}},'base-article',{
          article: _vm.article,
          id: _vm.id,
          isArticle: _vm.isArticle,
          isFavourite: _vm.isFavourite,
        },false))],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }