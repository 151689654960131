import { mapActions } from 'vuex'

export default (pluginName, answers = 'form') => ({
  methods: {
    ...mapActions('quizzesAnswers', [
      'addAnswers',
      'updateAnswers',
    ]),
    saveAnswers() {
      const action = this.answers ? 'update' : 'add'
      return this[`${action}Answers`]({ pluginName, form: { answers: this[answers] } })
    },
  },
})
