<template>
  <base-main
    data-test="capturing-bmi"
  >
    <container
      first
      class="capturing-bmi"
    >
      <h2
        class="capturing-bmi-heading"
        data-test="capturing-bmi-heading"
      >
        {{ $t(`${$static.slug}.heading`) }}
      </h2>

      <stepper-custom-form
        :buttonLabels="{ finishLabel: 'Finish & Save' }"
        :isLoading="isSaving"
        :testid="'capturing-bmi'"
        @cancel="goBack"
        @submit="onSubmit"
      >
        <stepper-custom-step
          data-testid="capturing-bmi-stepper-custom-step"
          :title="$t(`${$static.slug}.your-weight`)"
          v-slot="{ uuid }"
        >
          <input-group
            v-if="!isStonesAndPounds"
            :ariaLabel="$t(`${$static.slug}.your-weight`)"
            :describedBy="`step_header_${uuid}`"
            :name="'weight'"
            :label="weightLabel"
            :type="'number'"
            :max="maxWeightValue"
            :step="0.01"
            :validation="weightValidation"
            v-model="form.weight"
          />
          <div v-if="isStonesAndPounds">
            <label
              class="input-group__label"
              for="weight">{{ weightLabel }} (required)</label>
            <div class="log-weight-stones-pounds__container">
              <div class="log-weight-tracking__container">
                <input-group
                  :placeholder="'stones'"
                  :name="'stones'"
                  :step="0.01"
                  :max="maxWeightValue"
                  :type="'number'"
                  :validation="$options.validation.stones"
                  v-model="form.stones"
                />
                <span
                  class="log-weight-tracking__unit"
                  data-testid="weight-tracking-log-unit"
                >
                  {{ unit[0] }}
                </span>
              </div>
              <div class="log-weight-tracking__container">
                <input-group
                  :placeholder="'pounds'"
                  :name="'pounds'"
                  :step="0.01"
                  :type="'number'"
                  :validation="$options.validation.pounds"
                  v-model="form.pounds"
                />
                <span
                  class="log-weight-tracking__unit"
                  data-testid="weight-tracking-log-unit"
                >
                  {{ unit[1] }}
                </span>
              </div>
            </div>
          </div>
        </stepper-custom-step>

        <stepper-custom-step
          data-testid="capturing-bmi-stepper-custom-step"
          :title="$t(`${$static.slug}.your-height`)"
          v-slot="{ uuid }"
        >
          <input-group
            :ariaLabel="$t(`${$static.slug}.your-height`)"
            :describedBy="`step_header_${uuid}`"
            :name="'height'"
            :label="$t('Height') + ' (cm)'"
            :type="'number'"
            :validation="$options.validation.height"
            v-model="form.height"
          />
        </stepper-custom-step>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import goBack from '@/mixins/goBack'
  import numberizeProperties from '@/mixins/numberizeProperties'
  import unblockPlugin from '@/mixins/unblockPlugin'
  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.capturing-bmi.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      InputGroup,
      StepperCustomForm,
      StepperCustomStep,
    },

    mixins: [
      goBack,
      numberizeProperties,
      unblockPlugin,
      weightUnitConverter,
    ],

    created() {
      this.fillInForm()
    },

    data() {
      return {
        form: {
          stones: '',
          pounds: '',
          weight: null,
          height: null,
          bmi: null,
        },
      }
    },

    methods: {
      ...mapActions('optionalProfileData', [
        'updateOptionalData',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      fillInForm() {
        Object.values(this.getCategoryByKey('capturing_bmi').items)
          .forEach(({ key, value }) => this.form[key] = value)

        const weightConverted = this.convertToStonesOrPoundsIfNeeded(this.form.weight)
        if (this.isStonesAndPounds) {
          this.form.stones = weightConverted.stones
          this.form.pounds = weightConverted.pounds
        } else {
          this.form.weight = weightConverted.value
        }
      },
      onSubmit(isValid) {
        if (!isValid) return

        let weightGoal = this.form.weight
        if (this.isStonesAndPounds) {
          const pounds = parseInt(this.form.stones * 14, 10) + parseInt(this.form.pounds, 10)
          weightGoal = (Math.round((pounds / 2.204622621) * 100)) / 100
        } else if (this.isPounds) {
          weightGoal = (Math.round((this.form.weight / 2.204622621) * 100)) / 100
        }

        this.form.weight = weightGoal
        this.form.bmi = this.calculateBmi()
        this.numberizeProperties('form.height', 'form.weight')

        const allowedOptionalData = ['weight', 'height', 'bmi']
        const form = Object.keys(this.form)
                      .filter(key => allowedOptionalData.includes(key))
                      .map(key => ({
                        category: 'capturing-bmi',
                        key: key.replace(/_/g, '-'),
                        value: this.form[key],
                      }))

        this.updateOptionalData(form)
          .then(() => {
            const message = this.$t(`${this.$static.slug}.saved-answer`)
            this.addSnackbar({ message })
            this.unblockPlugin()
            this.$router.push({ name: 'Tools' })
          })
          .catch(() => this.addSnackbar({ message: this.$t('Something went wrong. Please try again.') }))
      },
      calculateBmi() {
        const height = this.form.height / 100
        return Math.floor(this.form.weight / (height * height)) || 0
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('optionalProfileData', [
        'getCategoryByKey',
      ]),
      ...mapGetters('user', [
        'getCurrentUserWeightUnit',
        'isMetric',
        'isPounds',
        'isStonesAndPounds',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'optionalProfileData/updateOptionalData',
        ])
      },
      weightLabel() {
        return `Weight (${this.getCurrentUserWeightUnit.abbr})`
      },
      unit() {
        return this.getCurrentUserWeightUnit.abbr
      },
      maxWeightValue() {
        return this.isMetric ? 399.16 : 880
      },
      weightValidation() {
        const { validation: { weight } } = this.$options
        weight.max_value = this.maxWeightValue
        return weight
      },
    },

    static() {
      return {
        slug: 'views.plugins.capturing-bmi',
      }
    },

    validation: {
      weight: {
        required: true,
        decimal: 2,
        min_value: 20,
        max_value: 880,
      },
      height: {
        required: true,
        numeric: true,
        min_value: 20,
        max_value: 300,
      },
      stones: {
        required: true,
      },
      pounds: {
        required: true,
        max_value: 14,
      },
    },
  }
</script>

<style lang="scss">
  .capturing-bmi {
    @include view-wrapper(60rem);
    &-heading {
      margin: 0 0 2.5rem 0;
      font-size: 2.2rem;
      @include min-lg {
        font-size: 2.8rem;
      }

    }
    .log-weight-stones-pounds__container {
      display: flex;
      .log-weight-tracking {
        &__container {
          width: 100%;
          display: flex;
          align-items: center;
          > div {
            width: 100%;
            .input-group {
              margin: 0;
            }
          }
        }
        &__unit {
          margin: 0;
          margin-left: 1rem;
          margin-right: 3.4rem;
        }

      }
    }
  }
</style>
