<template>
  <base-main
    class="getting-confident-quiz"
    :loading="$options.loading"
    data-testid="getting-confident-quiz"
  >
    <container
      class="getting-confident-quiz__container"
      v-if="form"
      first>
        <h2
          class="getting-confident-quiz-heading"
          data-testid="getting-confident-quiz-heading"
        >
          {{ $t('views.plugins.gcq.getting-confident-quiz.heading') }}
        </h2>

        <stepper-form
          @submit="onSubmit">
            <stepper-step
              v-for="(situation, i) in situations"
              :key="i"
              :fieldsNames="`situation_${situation.id}`"
              :isSaving="isSaving && i == max"
              :testid="'getting-confident-quiz-step'"
            >
              <template slot="label">
                {{ $t(situation.question) }}
              </template>

              <template slot="content">
                <range-group
                  :max="5"
                  :name="`situation_${situation.id}`"
                  :contextLabels="['Not confident', 'Very confident']"
                  :validation="$options.validation.situation"
                  v-model="form[situation.id]"/>
              </template>
            </stepper-step>
          </stepper-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import RangeGroup from '@/components/forms/range-group/RangeGroup'
  import StepperForm from '@/components/forms/stepper-form/StepperForm'
  import StepperStep from '@/components/stepper/stepper-step/StepperStep'

  import situations from '@/data/getting-confident-quiz/gettingConfidentQuizQuestions'

  import { saveAnswers } from '@/mixins/quizzes-answers'

  const pluginName = 'gettingConfidentQuiz'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.gcq.getting-confident-quiz.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      RangeGroup,
      StepperForm,
      StepperStep,
    },

    mixins: [
      saveAnswers(pluginName),
    ],

    props: {
      isRerun: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    created() {
      this.redirectIfNeccessary()

      this.answers
        ? this.fillInForm()
        : this.initializeQuiz()
    },

    data() {
      return {
        situations,
        form: null,
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      createForm() {
        this.form = this.situations
                      .map(situation => situation.id)
                      .reduce((acc, curr) => Object.assign(acc, { [curr]: 0 }), {})
      },
      fillInForm() {
        this.form = this.answers
      },
      initializeQuiz() {
        this.fetchAnswers(pluginName)
          .then(() => {
            this.redirectIfNeccessary()

            this.answers
              ? this.fillInForm()
              : this.createForm()
          })
      },
      onSubmit(isValid) {
        if (!isValid) return

        this.saveAnswers()
          .then(() => this.$router.push({ name: 'GettingConfidentResults' }))
      },
      redirectIfNeccessary() {
        if (this.answers && !this.isRerun) {
          this.$router.replace({ name: 'GettingConfidentResults' })
        }
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          `${pluginName}/addAnswers`,
          `${pluginName}/updateAnswers`,
        ])
      },
      max() {
        return Object.keys(this.situations).length - 1
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],

    validation: {
      situation: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .getting-confident-quiz {
    &__container {
      margin: 2rem 0;
    }
    &-heading {
      margin: 0 0 2.5rem 0;
    }
   .vue-slider-component .vue-slider-dot { background: none; }
  }
</style>
