<template>
  <base-main
    background
    class="reading-room-article"
    data-testid="reading-room-article"
    :class="{
      [`reading-room-article--is-${articleType}`]: !!articleType,
    }"
  >
    <div class="reading-room-article__wrapper">
      <transition-article>
        <base-article
          :key="transitionKey"
          allow-favourite
          is-reading-room
          :item="currentItem"
          :testid="'reading-room-article'"
          v-bind="{
            article,
            id,
            isArticle,
            isFavourite,
          }"
          @clickFavourite="onClickFavourite"
        />
      </transition-article>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseArticle,
    BaseMain,
  } from '@/components/base'

  import shouldFetch from '@/mixins/shouldFetch'

  import TransitionArticle from '@/transitions/transition-article/TransitionArticle'

  export default {
    metaInfo() {
      const { title } = this.currentItem || {}

      return {
        title,
      }
    },

    inheritAttrs: false,

    components: {
      BaseArticle,
      BaseMain,
      TransitionArticle,
    },

    mixins: [
      shouldFetch,
    ],

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    async created() {
      const triggerDataId = this.getTriggerDataIdByPluginName('reading-room')

      await this.shouldFetch('topics', 'fetchTopics', { triggerDataId })
      const topic = this.hasTopics ? { topicId: this.topicId } : {}

      this.shouldFetch('categories', 'fetchCategories', { triggerDataId, ...topic })
        .then(this.fetchFullCategory)

      this.updateCurrentItem()
    },

    beforeDestroy() {
      this.currentItem = {}
      this.setArticle(null)
    },

    destroyed() {
      this.resetBreadcrumbs()
    },

    watch: {
      id() {
        this.fetchFullCategory()
        this.updateCurrentItem()
      },
      articleId(newId) {
        if (newId) {
          this.transitionKey = newId
        }
      },
    },

    data() {
      return {
        currentItem: {},
        isArticle: true,
        transitionKey: null,
        article: null,
      }
    },

    methods: {
      ...mapMutations('route', [
        'setBreadcrumbCategory',
        'setBreadcrumbTopic',
        'resetBreadcrumbs',
      ]),
      ...mapMutations('article', [
        'setArticle',
      ]),
      ...mapActions('article', [
        'fetchArticle',
      ]),
      ...mapActions('readingRoom', [
        'fetchCategories',
        'fetchTopics',
        'fetchCategory',
        'fetchItem',
        'markItemAsRead',
        'setFavorite',
      ]),
      onClickFavourite() {
        if (this.isTogglingFavorite) return

        this.setFavorite({ favorite: !this.isFavourite, id: this.id })
      },
      updateCurrentItem() {
        this.currentItem = {}

        this.fetchItem(this.id)
          .then(value => {
            this.currentItem = this.getItemById(this.id)
            this.isArticle = this.currentItem?.type === 'article'

            if (this.isArticle) {
              return this.fetchArticle(value || this.currentItem.value)
            }
          })
          .then(article => {
            this.article = article
            /* eslint-disable-next-line camelcase */
            if (this.currentItem?.content_user) return

            this.markItemAsRead(this.id)
          })
      },
      fetchFullCategory() {
        const category = this.categories?.[this.$route.params.categoryId]
        const topic = this.getTopicById(this.topicId)

        if (!category) {
          return
        }

        const { display_name: displayName } = category
        this.setBreadcrumbCategory(displayName || '')
        if (topic) {
          this.setBreadcrumbTopic(topic.display_name)
        }

        const { allItemsCount, items } = category
        const itemsCount = items ? Object.values(items).length : 0

        if (itemsCount === allItemsCount) return

        this.fetchCategory(this.$route.params.categoryId)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('readingRoom', [
        'getItemById',
        'getTopicById',
      ]),
      ...mapGetters('triggers', [
        'getTriggerDataIdByPluginName',
      ]),
      ...mapState('readingRoom', [
        'categories',
        'topics',
        'category',
      ]),
      articleId() {
        return this.article?.id
      },
      isTogglingFavorite() {
        return this.getLoadingStatesForActions(['readingRoom/setFavorite'])
      },
      isFavourite() {
        if (!this.id) return false

        const currentItem = this.getItemById(this.id)
        const { content_user: contentUser } = currentItem || {}
        const { favorite } = contentUser || {}

        return favorite
      },
      articleType() {
        return this.article?.type
      },
      topicId() {
        return parseInt(this.$route.params.topicId, 10)
      },
      hasTopics() {
        return this.topics.length > 1
      },
    },

    loading: [
      'readingRoom/fetchTopics',
      'article/fetchArticle',
      'readingRoom/fetchItem',
      'readingRoom/fetchCategory',
      'readingRoom/fetchCategories',
    ],
  }
</script>

<style lang="scss">
  .reading-room-article {
    &__wrapper {
      @include reading-room-wrapper;
    }
    .article-video {
      padding: 0;
      overflow-y: auto;
    }
  }
</style>
