<template>
  <base-main
    class="tricky-situations-results"
    data-testid="tricky-situations-results"
    :loading="$options.loading">
      <container
        v-if="result"
        first
        flex-column
        plugin-article
      >
        <div>
          <plugin-article
            has-background
            :img="'tricky-situations/tricky-situations-results.png'"
            :testid="'tricky-situations-results'"
          >
            <template slot="title">
              {{ $t(result.title) }}
            </template>
            <template slot="content">
              <template
                v-if="isMax">
                  <p>{{ i18n('make-plan') }}</p>
                  <ul>
                    <li>{{ i18n('problem') }}</li>
                    <li>{{ i18n('solve-plan') }}</li>
                  </ul>
              </template>
              <template
                v-else>
                  <p>{{ i18n('not-confident') }}</p>
                  <ul>
                    <li
                      v-for="(situation, i) in result.lowScoringSituations"
                      :key="i"
                      v-html="$t(situation)"
                    />
                  </ul>
              </template>

              <template
                v-if="result.sessions.length">
                  <h3
                    class="tricky-situations-results-power"
                    data-testid="tricky-situations-results-power-heading"
                  >
                    {{ i18n('power-sessions-header') }}
                  </h3>
                  <div
                    v-for="(session, i) in result.sessions"
                    :key="i"
                    v-html="$t(session)"
                  />
              </template>

              <template
                v-if="!isMax"
              >
                <p v-html="i18n('make-a-plan')"/>
                <p v-html="i18n('write-a-plan')"/>
              </template>
            </template>
          </plugin-article>

          <base-form
            class="tricky-situations-results-form"
            v-bind="{ isSaving }"
            @submit="onSubmit"
          >
            <input-group
              :label="problemLabel"
              :name="'tricky_situations_problem'"
              :type="'text'"
              :validation="validation.tricky_situations_problem"
              v-model="form.tricky_situations_problem"
            />

            <input-group
              class="tricky-situations-results-form__plan"
              :label="i18n('my-plan')"
              :name="'tricky_situations_plan'"
              :type="'text'"
              :validation="validation.tricky_situations_plan"
              v-model="form.tricky_situations_plan"
            />
          </base-form>
        </div>

        <base-button
          align-end
          class="tricky-situations-results-rerun"
          data-testid="tricky-situations-results-rerun-button"
          :link="{ name: 'TrickySituationsQuiz', params: { isRerun: true } }"
        >
          {{ $t('Rerun quiz') }}
        </base-button>
      </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseForm from '@/components/base/BaseForm'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import { DATE_FORMATS } from '@/constants'

  import results from '@/data/tricky-situations/trickySituationsResults'

  import underscorize from '@/helpers/underscorize'

  import i18n from '@/mixins/i18n'
  import unblockPlugin from '@/mixins/unblockPlugin'

  const pluginName = 'trickySituations'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.tricky-situations.tricky-situations-results.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseForm,
      BaseMain,
      Container,
      InputGroup,
      PluginArticle,
    },

    mixins: [
      i18n,
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()
      this.fillInFormAndValidation()

      if (!this.problemCreatedDate) {
        this.fetchProblemCreatedDate(this.getCurrentUserId)
      }

      this.fetchAnswers(pluginName)
        .then(() => {
          this.answers
            ? this.getResult()
            : this.$router.replace({ name: 'TrickySituationsQuiz' })
        })
    },

    data() {
      return {
        form: {
          tricky_situations_plan: '',
          tricky_situations_problem: '',
        },
        validation: {
          tricky_situations_plan: null,
          tricky_situations_problem: null,
        },
        result: null,
      }
    },

    methods: {
      ...mapActions('optionalProfileData', [
        'updateOptionalData',
      ]),
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      ...mapActions('trickySituations', [
        'fetchProblemCreatedDate',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      fillInFormAndValidation() {
        Object.values(this.optionalData.quiz_tricky_situations.items)
          .filter(({ key }) => key == 'tricky-situations-problem' || key == 'tricky-situations-plan')
          .forEach(({ key, scheme, value }) => {
            const normalizedKey = underscorize(key)
            const validation = Object.keys(scheme.meta)
                                .reduce((acc, curr) => {
                                  const rule = { [curr.replace('length', '')]: scheme.meta[curr] }
                                  Object.assign(acc, rule)

                                  return acc
                                }, { required: true })

            this.form[normalizedKey] = value
            this.validation[normalizedKey] = validation
          })
      },
      getResult() {
        const {
          titles,
          titlesPoints,
          sessions,
          sessionsQuestions,
          lowScoringSituations,
        } = results

        const titleId = Object.keys(titlesPoints)
                        .find(key => (
                          titlesPoints[key].includes(this.sum)
                        ))

        const _sessions = Object.keys(sessionsQuestions)
                          .filter(key => (
                            sessionsQuestions[key]
                              .some(id => (
                                this.zeroPointsQuestionsIds.includes(id)
                              ))
                          ))
                          .map(id => sessions[id])

        this.result = {
          title: titles[titleId],
          sessions: _sessions,
          lowScoringSituations: this.lowScoringSituationsIds
                                  .map(id => lowScoringSituations[id]),
        }
      },
      onSubmit(isValid) {
        if (!isValid) return

        const form = Object.keys(this.form)
                      .map(key => ({
                        category: 'quiz-tricky-situations',
                        key: key.replace(/_/g, '-'),
                        value: this.form[key],
                      }))

        this.updateOptionalData(form)
          .then(() => this.fetchProblemCreatedDate(this.getCurrentUserId))
          .then(() => this.addSnackbar({ message: this.i18n('saved-answer') }))
          .catch(() => this.addSnackbar({ message: this.i18n('error') }))
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      ...mapState('optionalProfileData', [
        'optionalData',
      ]),
      ...mapState('trickySituations', [
        'answers',
        'problemCreatedDate',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      isMax() {
        return Object.keys(this.answers).length == this.sum
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'optionalProfileData/updateOptionalData',
        ])
      },
      lowScoringSituationsIds() {
        return Object.keys(this.answers)
                .filter(key => this.answers[key] <= 5)
      },
      problemLabel() {
        const { problemCreatedDate: date } = this

        return this.$t(this.$static.problem, { date: moment(date).format(DATE_FORMATS.dateShort) })
      },
      sum() {
        return Object.values(this.answers)
                .reduce((acc, curr) => (
                  curr >= 6
                    ? acc + 1
                    : acc
                ), 0)
      },
      zeroPointsQuestionsIds() {
        return Object.keys(this.answers)
                .filter(key => !this.answers[key])
                .map(Number)
      },
    },

    slug: 'views.plugins.tricky-situations.tricky-situations-results',

    loading: [
      `${pluginName}/fetchAnswers`,
      `${pluginName}/fetchProblemCreatedDate`,
    ],
  }
</script>

<style lang="scss">
  .tricky-situations-results {
    &-power {
      margin: 1.4rem 0 0;
    }
    &-form {
      margin: 1rem 0 0;
      &__plan {
        margin: 0 0 2rem;
      }
    }
    &-rerun {
      @include max-sm {
        align-self: flex-start;
        margin: 1rem 0 0;
      }
    }
    ul {
      list-style-type: disc;
      padding: 0 0 0 1.8rem;
    }
  }
</style>
