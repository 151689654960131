<script>
  export default {
    functional: true,

    render(h, { children, data }) {
      const join = arr => arr.join(' ').trim()
      const {
        attrs: { 'data-testid': dataTestid = '', ...newAttrs } = {},
        staticClass,
        ...newData
      } = data

      return h('ul', {
        attrs: {
          'data-testid': join([dataTestid, 'slider']),
          ...newAttrs,
        },
        staticClass: join([staticClass, 'slider']),
        ...newData,
      }, children)
    },
  }
</script>

<style lang="scss">
  .slider {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
</style>
