<template>
  <base-main
    :loading="$options.loading">
      <container
        v-if="!answers"
        first
        flex-column
        plugin-article>

          <plugin-article
            :img="'quiz/getting-confident-quiz.png'">
              <template slot="title">
                {{ $t('views.plugins.gcq.getting-confident.title') }}
              </template>

              <template slot="content">
                <p>
                  {{ $t('views.plugins.gcq.getting-confident.content-1') }}
                </p>
                <p>
                  {{ $t('views.plugins.gcq.getting-confident.content-2') }}
                </p>
              </template>
          </plugin-article>

          <base-button
            align-end
            :link="{ name: capitalizedPluginName }">
              {{ $t('Next') }}
          </base-button>
      </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import capitalize from '@/filters/capitalize'

  import shouldFetch from '@/mixins/shouldFetch'

  const pluginName = 'gettingConfidentQuiz'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.gcq.getting-confident.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      shouldFetch,
    ],

    created() {
      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(() => {
          if (!this.answers) return

          this.$router.replace({ name: 'GettingConfidentResults' })
        })
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      capitalizedPluginName() {
        return capitalize(pluginName)
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],
  }
</script>
