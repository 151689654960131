<template>
  <base-main
    data-testid="tricky-situations"
    :loading="$options.loading"
  >
    <container
      v-if="!answers"
      first
      flex-column
      plugin-article>
        <plugin-article
          :img="'tricky-situations/tricky-situations-start.png'"
          :testid="'tricky-situations'"
        >
          <template slot="title">
            {{ $t('Quiz - Tricky Situations') }}
          </template>
          <template slot="content">
            <p>{{ $t('views.plugins.tricky-situations.tricky-situations.paragraph-1') }}</p>
            <p>{{ $t('views.plugins.tricky-situations.tricky-situations.paragraph-2') }}</p>
            <p>{{ $t('views.plugins.tricky-situations.tricky-situations.paragraph-3') }}</p>
            <p>{{ $t('views.plugins.tricky-situations.tricky-situations.paragraph-4') }}</p>
          </template>
        </plugin-article>

        <base-button
          align-end
          data-testid="tricky-situations-next"
          :link="{ name: 'TrickySituationsQuiz' }">
            {{ $t('Next') }}
        </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import shouldFetch from '@/mixins/shouldFetch'

  const pluginName = 'trickySituations'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.tricky-situations.tricky-situations.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      shouldFetch,
    ],

    created() {
      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(() => {
          if (!this.answers) return

          this.$router.replace({ name: 'TrickySituationsResults' })
        })
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],
  }
</script>
