<template>
  <base-main
    class="eq-questions"
    data-testid="eq-5-d-questionnaire"
  >
    <container
      first
      flex-column
      plugin-article
    >

      <slider
        data-testid="eq-5-d-questionnaire-slider"
      >
        <slider-slide
          v-for="(question, i) in sliderQuestions"
          :key="i"
          :data-testid="`eq-5-d-questionnaire-slide-${question.name}`"
          :index="i"
          v-bind="{ currentSlide }"
        >
          <plugin-article
            :img="`eq5d/${question.name}.png`"
          >
            <template #title>
              {{ $t(question.title) }}
            </template>

            <template #content>
              <div
                v-if="question.description"
                class="eq-questions-description"
              >
                {{ $t(question.description) }}
              </div>

              <p>
                {{ i18n('questions-helper') }}
              </p>

              <base-form
                ref="form"
                :hasSubmitButton="false"
                v-bind="{ isSaving }"
                @submit="onSubmit"
              >
                <radio-multi-group
                  :legend="i18n('questions-legend')"
                  :name="question.name"
                  :options="question.options"
                  :validation="{ required: true }"
                  v-model="form[question.name]"
                />
              </base-form>
            </template>
          </plugin-article>
        </slider-slide>
      </slider>

      <base-button
        align-end
        data-testid="eq-5-d-questionnaire-submit"
        :disabled="isSaving"
        :isLoading="isSaving"
        @click="onSubmit"
      >
        {{ $t('Next') }}
      </base-button>

    </container>
  </base-main>
</template>

<script>
  import { last } from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import {
    BaseButton,
    BaseForm,
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'
  import { RadioMultiGroup } from '@/components/forms/radio'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import Slider from '@/components/slider/Slider'
  import SliderSlide from '@/components/slider/slider-slide/SliderSlide'

  import questions from '@/data/eq-5d'

  import i18n from '@/mixins/i18n'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.eq-5d.eq-5d-questionnaire.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseForm,
      BaseMain,
      Container,
      PluginArticle,
      RadioMultiGroup,
      Slider,
      SliderSlide,
    },

    mixins: [
      i18n,
    ],

    props: {
      data: {
        type: Object,
        required: true,
      },
    },

    created() {
      this.next = this.data.next
      this.token = this.data.token
      this.form = this.createForm()
    },

    beforeDestroy() {
      this.timeouts.forEach(clearTimeout)
    },

    watch: {
      next: {
        async handler(newValue, oldValue) {
          if (!this.next) return

          this.questionsKeys.push(newValue)

          if (oldValue) {
            await this.$nextTick()
            this.currentSlide += 1
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        currentSlide: 0,
        form: null,
        next: null,
        token: null,
        questionsKeys: [],
        timeouts: [],
      }
    },

    methods: {
      ...mapActions('eq5d', [
        'saveAnswer',
      ]),
      createForm() {
        return Object.values(questions).reduce((acc, { name }) => ({
          ...acc,
          [name]: null,
        }), {})
      },
      async onSubmit() {
        const isValid = await this.$refs.form[this.currentSlide].$refs.observer.validate()

        if (!isValid) {
          this.timeouts.push(setTimeout(() => {
            document.querySelector('.slider').scrollTo(0, 300)
          }, 200))
          return
        }
        const currentQuestionKey = last(this.questionsKeys)
        const answer = this.form[currentQuestionKey]

        this.saveAnswer({
          form: {
            score: answer + 1,
            value: questions[currentQuestionKey]?.options
                    .find(({ value }) => value == answer)
                    .label,
          },
          token: this.token,
        })
          .then(next => {
            this.scrollToTop()
            next == 'eq-vas'
              ? this.$router.replace({ name: 'Eq5dHealth', params: { token: this.token } })
              : this.next = next
          })
          .catch(() => this.$router.replace({ name: 'Eq5d' }))
      },
      scrollToTop() {
        document.querySelector('.slider').scrollTo(0, 0)
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'eq5d/saveAnswer',
        ])
      },
      sliderQuestions() {
        return this.questionsKeys.map(field => questions[field])
      },
      isQuestionSelected() {
        return question => this.form[question.field] !== null
      },
    },

    beforeRouteEnter(to, from, next) {
      to.params.data
        ? next()
        : next({ name: 'Eq5d', replace: true })
    },

    slug: 'views.plugins.eq-5d.eq-5d-questionnaire',
  }
</script>

<style lang="scss">
  .eq-questions {
    @include max-lg {
      position: absolute;
    }
    &-description {
      margin: -1.5rem 0 0;
      padding: 0 0 2rem;
      color: color(dark-primary);
      text-align: center;
    }
    &-legend {
      padding: 1rem 0 2rem;
      font-size: 1.7rem;
      font-weight: 600;
    }
    .slider {
      overflow-y: auto;
    }
    .plugin-article {
      padding: 0 1.4rem;
    }
  }
</style>
