<template>
  <div class="notification-header">
    <div class="notification-header__item">
      <inline-svg
        class="notification-header__logo notification-header__svg"
        role="presentation"
        src="logo.svg"
      />
      Changing Health
      <time
        class="notification-header__time"
        :datetime="time"
        v-time-ago
      >
        {{ time }}
      </time>
    </div>

    <div class="notification-header__icons">
      <snooze @snooze="onSnooze" />

      <base-button
        round
        class="notification-header__close-button"
        data-testid="notification-header-close"
        @click="closeNotification"
      >
        <inline-svg
          aria-label="Close"
          src="plus.svg"
        />
      </base-button>
    </div>
  </div>
</template>

<script>
  import BaseButton from '@/components/base/BaseButton'
  import Snooze from '@/components/notification/Snooze'

  import timeAgo from '@/directives/timeAgo'

  export default {
    components: {
      BaseButton,
      Snooze,
    },

    directives: {
      timeAgo,
    },

    props: {
      time: {
        type: String,
        required: false,
        default: `${new Date()}`,
      },
    },

    methods: {
      closeNotification() {
        this.$emit('close')
      },
      onSnooze(until) {
        this.$emit('snooze', until)
      },
    },
  }
</script>

<style lang="scss">
  .notification-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 1.5rem 0;
    @include min-sm {
      flex-direction: row;
      align-items: center;
    }
    &__icons {
      margin-left: -2rem;
      @include min-sm {
        margin-left: 1rem;
      }
    }
    &__item {
      display: flex;
    }
    &__logo {
      margin-right: 0.6rem;
      svg {
        display: flex;
        align-self: center;
        width: 1.5rem;
      }
    }
    &__time {
      &:before {
        content: '•';
        margin: 0 0.4rem;
      }
    }
    &__svg {
      display: inline-flex;
      align-items: flex-start;
    }
    &__close-button {
      svg {
        width: 1.4rem;
        transform: rotate(45deg);
        stroke: color(grey);
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
    }
    .snooze {
      margin-right: 1rem;
      &-clock {
        svg {
          width: 1.2rem;
          fill: color(grey);
        }
      }
      .snooze-button {
        min-width: 2rem;
      }
    }
    .snooze-button,
    &__close-button {
      &::before,
      &:active::before,
      &:hover::before {
        background: none;
        box-shadow: none;
      }
    }
  }
</style>
