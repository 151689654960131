/* eslint-disable */

const nameBase = 'InitialAssessment'
const descriptions = {
  1: 'data.initial-assessment.descriptions-1',
}
const helpers = {
  1: 'data.initial-assessment.helpers-1',
}
const options = {
  1: [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
    {
      label: 'Prefer not to say',
      value: 'Prefer not to say',
    },
  ],
  2: [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ],
}

export default [
  {
    name: `${nameBase}Address`,
    description: 'data.initial-assessment.address.description',
    questions: [
      {
        legend: 'data.initial-assessment.address.legend',
        label: 'data.initial-assessment.address.label',
        helper: 'data.initial-assessment.address.helper',
        name: 'address',
        type: 'input-group',
        validation: {
          alpha_num: true,
          max: 4,
          min: 3,
          postcode_UK: true,
        },
      },
    ],
    title: 'data.initial-assessment.address.title',
  },
  {
    name: `${nameBase}GP`,
    legend: 'data.initial-assessment.gp.legend',
    questions: [
      {
        label: 'data.initial-assessment.gp.questions-label-1',
        name: 'gp_practice_name',
        type: 'input-group',
        validation: {
          min: 5,
          max: 200,
        },
      },
      {
        label: 'data.initial-assessment.gp.questions-label-2',
        name: 'gp_name',
        type: 'input-group',
        validation: {
          min: 5,
          max: 150,
        },
      },
      {
        label: 'data.initial-assessment.gp.questions-label-3',
        name: 'gp_practice_address',
        type: 'input-group',
        validation: {
          min: 10,
          max: 200,
        },
      },
    ],
    title: 'data.initial-assessment.gp.title',
  },
  {
    name: `${nameBase}Sex`,
    questions: [
      {
        legend: 'data.initial-assessment.sex.legend',
        name: 'sex',
        isLegendHidden: true,
        fields: [
          {
            legend: 'data.initial-assessment.sex.legend',
            options: [
              { label: 'data.initial-assessment.sex.options-1', value: 'Male' },
              { label: 'data.initial-assessment.sex.options-2', value: 'Female' },
              { label: 'data.initial-assessment.sex.options-3', value: 'Indeterminate' },
              {
                label: 'Prefer not to say',
                value: 'Prefer not to say',
              },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'Sex',
  },
  {
    name: `${nameBase}Gender`,
    questions: [
      {
        legend: 'data.initial-assessment.gender.legend',
        helper: 'data.initial-assessment.gender.helper',
        name: 'gender_identity',
        fields: [
          {
            options: options[1],
          }
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.gender.title',
  },
  {
    name: `${nameBase}MaritalStatus`,
    questions: [
      {
        legend: 'data.initial-assessment.marital-status.legend',
        name: 'marital_status',
        fields: [
          {
            options: [
              { label: 'data.initial-assessment.marital-status.oprions-1', value: 'Single' },
              { label: 'data.initial-assessment.marital-status.oprions-2', value: 'Married / Civil Partner' },
              { label: 'data.initial-assessment.marital-status.oprions-3', value: 'Separated' },
              { label: 'data.initial-assessment.marital-status.oprions-4', value: 'Divorced / Person whose partnership has been dissolved' },
              { label: 'data.initial-assessment.marital-status.oprions-5', value: 'Widowed / Surviving Civil Partner' },
              { label: 'Prefer not to say', value: 'Prefer not to say' },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.marital-status.title',
  },
  {
    name: `${nameBase}Language`,
    questions: [
      {
        name: 'language_preferred',
        label: 'data.initial-assessment.language.questions-legend-1',
        type: 'input-group',
        validation: {
          alpha: true,
          min: 3,
          max: 50,
        },
      },
      {
        legend: 'data.initial-assessment.language.questions-legend-2',
        name: 'language_speak_and_spoken',
        fields: [
          {
            options: options[1],
          },
        ],
        type: 'radio-multi-group',
      },
      {
        legend: 'data.initial-assessment.language.questions-legend-3',
        name: 'language_written',
        fields: [
          {
            options: options[1],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.language.title',
  },
  {
    name: `${nameBase}DisabilityStatus`,
    questions: [
      {
        legend: 'data.initial-assessment.disability.legend',
        name: 'disability_status_first',
        fields: [
          {
            options: [
              { label: 'data.initial-assessment.disability.options-1', value: 'Disabled' },
              { label: 'data.initial-assessment.disability.options-2', value: 'Not disabled' },
              { label: 'Prefer not to say', value: 'Prefer not to say' },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.disability.title',
  },
  {
    name: `${nameBase}DisabilityCategories`,
    questions: [
      {
        helper: 'data.initial-assessment.disability-categories.helper',
        legend: 'data.initial-assessment.disability-categories.legend',
        name: 'disability_status_second_fixer',
        fields: [
          {
            options: [
              {
                label: 'data.initial-assessment.disability-categories.options-1',
                name: 'disability_status_behaviour_emotional',
                value: 'Behaviour and Emotional',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-2',
                name: 'disability_status_hearing',
                value: 'Hearing',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-3',
                name: 'disability_status_manual_dexterity',
                value: 'Manual Dexterity',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-4',
                name: 'disability_status_memory',
                value: 'Memory or ability to concentrate, learn or understand (Learning Disability)',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-5',
                name: 'disability_status_mobility',
                value: 'Mobility and Gross Motor',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-6',
                name: 'disability_status_perception',
                value: 'Perception of Physical Danger',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-7',
                name: 'disability_status_personal',
                value: 'Personal, Self-Care and Continence',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-8',
                name: 'disability_status_progressive_conditions',
                value: 'Progressive Conditions and Physical Health (HIV, cancer, multiple sclerosis, etc.)',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-9',
                name: 'disability_status_sight',
                value: 'Sight',
              },
              {
                label: 'data.initial-assessment.disability-categories.options-10',
                name: 'disability_status_speech',
                value: 'Speech',
              },
              {
                label: 'Other',
                value: 'Other',
                children: [
                  {
                    label: 'data.initial-assessment.disability-categories.other',
                    name: 'disability_status_other_input',
                    type: 'input-group',
                    validation: {
                      min: 2,
                      max: 200,
                    },
                  },
                ],
              },
              {
                label: 'Prefer not to say',
                value: 'Prefer not to say',
              },
            ],
          },
        ],
        type: 'checkbox-multi-group',
      },
    ],
    title: 'data.initial-assessment.disability-categories.title',
  },
  {
    name: `${nameBase}Religion`,
    description: descriptions[1],
    questions: [
      {
        legend: 'data.initial-assessment.religion.legend',
        name: 'religious_beliefs',
        fields: [
          {
            options: [
              {
                label: 'data.initial-assessment.religion.options-1',
                value: 'Baha\'i',
              },
              {
                label: 'data.initial-assessment.religion.options-2',
                value: 'Buddhist',
              },
              {
                label: 'data.initial-assessment.religion.options-3',
                value: 'Christian',
              },
              {
                label: 'data.initial-assessment.religion.options-4',
                value: 'Hindu',
              },
              {
                label: 'data.initial-assessment.religion.options-5',
                value: 'Jain',
              },
              {
                label: 'data.initial-assessment.religion.options-6',
                value: 'Jewish',
              },
              {
                label: 'data.initial-assessment.religion.options-7',
                value: 'Muslim',
              },
              {
                label: 'data.initial-assessment.religion.options-8',
                value: 'Pagan',
              },
              {
                label: 'data.initial-assessment.religion.options-9',
                value: 'Sikh',
              },
              {
                label: 'data.initial-assessment.religion.options-10',
                value: 'Zoroastrian',
              },
              {
                label: 'Other',
                value: 'Other',
              },
              {
                label: 'None',
                value: 'None',
              },
              {
                label: 'Prefer not to say',
                value: 'Prefer not to say',
                customValue: 'Declines to Disclose',
              },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.religion.title',
  },
  {
    name: `${nameBase}SexualOrientation`,
    description: descriptions[1],
    questions: [
      {
        legend: 'data.initial-assessment.sexual-orientation.legend',
        name: 'sexual_orientation',
        fields: [
          {
            options: [
              {
                label: 'data.initial-assessment.sexual-orientation.options-1',
                value: 'Heterosexual',
              },
              {
                label: 'data.initial-assessment.sexual-orientation.options-2',
                value: 'Gay / lesbian',
              },
              {
                label: 'data.initial-assessment.sexual-orientation.options-3',
                value: 'Bisexual',
              },
              {
                label: 'data.initial-assessment.sexual-orientation.options-4',
                value: 'Unsure',
              },
              {
                label: 'Prefer not to say',
                value: 'Prefer not to say',
              },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.sexual-orientation.title',
  },
  {
    name: `${nameBase}HousingTenure`,
    description: descriptions[1],
    questions: [
      {
        legend: 'data.initial-assessment.housing-tenure.legend',
        name: 'housing_tenure',
        fields: [
          {
            options: [
              {
                label: 'data.initial-assessment.housing-tenure.options-1',
                value: 'Owner occupier',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-2',
                value: 'Settled mainstream housing with family/friends',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-3',
                value: 'Shared ownership scheme e.g. Social Homebuy Scheme',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-4',
                value: 'Tenant - Local Authority/Arms Length Management Organisation/Registered Landlord',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-5',
                value: 'Tenant - housing association',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-6',
                value: 'Tenant - private landlord',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-7',
                value: 'Other mainstream housing',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-8',
                value: 'Homeless',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-9',
                value: 'Accommodation with mental health care support',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-10',
                value: 'Acute/long stay healthcare residential facility/hospital',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-11',
                value: 'Accommodation with other (not specialist mental health) care support',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-12',
                value: 'Accommodation with criminal justice support',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-13',
                value: 'Sheltered Housing (accommodation with a scheme manager or warden living on the premises or nearby, contactable by an alarm system if necessary)',
              },
              {
                label: 'data.initial-assessment.housing-tenure.options-14',
                value: 'Mobile accommodation',
              },
              {
                label: 'Other',
                value: 'Other',
                children: [
                  {
                    label: 'data.initial-assessment.housing-tenure.other',
                    name: 'housing_tenure_input',
                    validation: {
                      min: 2,
                      max: 200,
                    },
                  },
                ],
              },
              {
                label: 'Prefer not to say',
                value: 'Prefer not to say',
              },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.housing-tenure.title',
  },
  {
    name: `${nameBase}Employment`,
    description: descriptions[1],
    questions: [
      {
        legend: 'data.initial-assessment.employment.legend',
        name: 'employment_status',
        fields: [
          {
            options: [
              {
                label: 'data.initial-assessment.employment.options-1',
                value: 'Employed',
              },
              {
                label: 'data.initial-assessment.employment.options-2',
                value: 'Unemployed and seeking work',
              },
              {
                label: 'data.initial-assessment.employment.options-3',
                value: 'Student undertaking full (at least 16 hours per week) or part-time (less than 16 hours per week) education or training and not working or actively seeking work',
              },
              {
                label: 'data.initial-assessment.employment.options-4',
                value: 'Long-term sick or disabled, receiving Incapacity Benefit, Income Support or both; or Employment and Support Allowance',
              },
              {
                label: 'data.initial-assessment.employment.options-5',
                value: 'Homemaker looking after the family or home and not working or actively seeking work',
              },
              {
                label: 'data.initial-assessment.employment.options-6',
                value: 'Not receiving benefits and not working or actively seeking work',
              },
              {
                label: 'data.initial-assessment.employment.options-7',
                value: 'Unpaid voluntary work, not working or actively seeking work',
              },
              {
                label: 'data.initial-assessment.employment.options-8',
                value: 'Retired',
              },
              {
                label: 'Other',
                value: 'Other',
                children: [
                  {
                    label: 'data.initial-assessment.employment.other',
                    name: 'employment_status_input',
                    validation: {
                      min: 5,
                      max: 120,
                    },
                  },
                ],
              },
              {
                label: 'Prefer not to say',
                value: 'Prefer not to say',
              },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.employment.title',
  },
  {
    name: `${nameBase}Ethnicity`,
    description: descriptions[1],
    questions: [
      {
        legend: 'data.initial-assessment.ethnicity.legend',
        name: 'ethnicity',
        fields: [
          {
            legend: 'data.initial-assessment.ethnicity.options-1',
            options: [
              {
                label: 'data.initial-assessment.ethnicity.options-2',
                value: 'British or Mixed British',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-3',
                value: 'Irish',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-4',
                value: 'Any other white background',
              },
            ],
          },
          {
            legend: 'data.initial-assessment.ethnicity.options-5',
            options: [
              {
                label: 'data.initial-assessment.ethnicity.options-6',
                value: 'White and Black Caribbean',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-7',
                value: 'White and Black African',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-8',
                value: 'White and Asian',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-9',
                value: 'Any other mixed background',
              },
            ],
          },
          {
            legend: 'data.initial-assessment.ethnicity.options-10',
            options: [
              {
                label: 'data.initial-assessment.ethnicity.options-11',
                value: 'Indian',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-12',
                value: 'Pakistani',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-13',
                value: 'Bangladeshi',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-14',
                value: 'Any other Asian background',
              },
            ],
          },
          {
            legend: 'data.initial-assessment.ethnicity.options-15',
            options: [
              {
                label: 'data.initial-assessment.ethnicity.options-16',
                value: 'Caribbean',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-17',
                value: 'African',
              },
              {
                label: 'data.initial-assessment.ethnicity.options-18',
                value: 'Any other black background',
              },
            ],
          },
          {
            legend: 'data.initial-assessment.ethnicity.legend',
            options: [
              {
                label: 'data.initial-assessment.ethnicity.other',
                value: 'Other',
                children: [
                  {
                    label: 'data.initial-assessment.ethnicity.other',
                    name: 'ethnicity_input',
                    validation: {
                      min: 5,
                      max: 50,
                    },
                  },
                ],
              },
              {
                label: 'Prefer not to say',
                value: 'Prefer not to say',
              },
            ],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.ethnicity.title',
  },
  {
    name: `${nameBase}Consent`,
    questions: [
      {
        legend: 'data.initial-assessment.share.legend',
        helper: 'data.initial-assessment.share.helper',
        helperBottom: 'data.initial-assessment.share.helper-bottom',
        name: 'consent_to_share',
        fields: [
          {
            options: options[2],
          },
        ],
        type: 'radio-multi-group',
      },
    ],
    title: 'data.initial-assessment.share.title',
  },
]
