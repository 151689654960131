<template>
  <div
    class="chip-radio-group"
    role="radio"
    tabindex="0"
    :aria-checked="isSelected.toString()"
    :data-testid="`${dataTestid} chip-radio-group`"
    @keydown.prevent.enter.space="onChange"
  >
    <label class="chip-radio-group-label">
      <chip
        v-bind="{ hasBorder, isDark, isSelected }"
      >
        <input
          class="chip-radio-group-input"
          data-testid="chip-radio-group-input"
          type="radio"
          :checked="isSelected"
          :id="`chip-input-${uuid}`"
          :name="name"
          @click.prevent="onClick"
          v-model="model"
        >
        <slot/>
        {{ label }}
      </chip>
    </label>
  </div>
</template>

<script>
  import Chip from '@/components/chips/chip/Chip'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      Chip,
    },

    mixins: [
      testid(),
    ],

    props: {
      hasBorder: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
      inputValue: {
        type: [String, Boolean, Number],
        required: true,
      },
      isDark: {
        type: Boolean,
        required: false,
        default: false,
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: [String, Boolean, Number],
        default: '',
      },
    },

    methods: {
      onChange() {
        this.$emit('input', this.inputValue)
      },
      onClick() {
        this.model = this.inputValue
      },
    },

    computed: {
      isSelected() {
        return (this.value == this.inputValue)
      },
      model: {
        get() {
          return this.value
        },
        set() {
          this.onChange()
        },
      },
    },
  }
</script>

<style lang="scss">
  .chip-radio-group {
    &-input {
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      overflow: hidden;
      z-index: z-index(under-base);
    }
  }
</style>
