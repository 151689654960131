<template functional>
  <div
    class="food-dot"
    :class="[
      { [`food-dot--${props.type}`]: props.type },
      `food-dot--${props.color}`
    ]"/>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: false,
        default: '',
      },
    },
  }
</script>

<style lang="scss">
  .food {
    &-dot {
      position: relative;
      border-radius: 50%;
      background-color: color(_white);
      width: 1.4rem;
      height: 1.4rem;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        transform: translate(-50%, -50%)
      }
      &--absolute {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 1.6rem;
        height: 1.6rem;
        &:before {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
      &--inline {
        margin-right: 0.5rem;
      }
      &--amber {
        &:before {
          background-color: color(food-amber);
        }
      }
      &--green {
        &:before {
          background-color: color(food-green);
        }
      }
      &--red {
        &:before {
          background-color: color(food-red);
        }
      }
    }
  }
</style>
