export default [
  {
    id: 1,
    question: 'data.tricky-situations.tricky-situations-quiz.question-1',
  },
  {
    id: 2,
    question: 'data.tricky-situations.tricky-situations-quiz.question-2',
  },
  {
    id: 3,
    question: 'data.tricky-situations.tricky-situations-quiz.question-3',
  },
  {
    id: 4,
    question: 'data.tricky-situations.tricky-situations-quiz.question-4',
  },
  {
    id: 5,
    question: 'data.tricky-situations.tricky-situations-quiz.question-5',
  },
  {
    id: 6,
    question: 'data.tricky-situations.tricky-situations-quiz.question-6',
  },
  {
    id: 7,
    question: 'data.tricky-situations.tricky-situations-quiz.question-7',
  },
  {
    id: 8,
    question: 'data.tricky-situations.tricky-situations-quiz.question-8',
  },
  {
    id: 9,
    question: 'data.tricky-situations.tricky-situations-quiz.question-9',
  },
  {
    id: 10,
    question: 'data.tricky-situations.tricky-situations-quiz.question-10',
  },
  {
    id: 11,
    question: 'data.tricky-situations.tricky-situations-quiz.question-11',
  },
  {
    id: 12,
    question: 'data.tricky-situations.tricky-situations-quiz.question-12',
  },
  {
    id: 13,
    question: 'data.tricky-situations.tricky-situations-quiz.question-13',
  },
  {
    id: 14,
    question: 'data.tricky-situations.tricky-situations-quiz.question-14',
  },
  {
    id: 15,
    question: 'data.tricky-situations.tricky-situations-quiz.question-15',
  },
  {
    id: 16,
    question: 'data.tricky-situations.tricky-situations-quiz.question-16',
  },
]
