<template>
  <base-main
    class="activity-quiz"
  >
    <container
      stepper
      class="activity-quiz__wrapper"
    >
      <stepper-custom
        @cancel="goBack"
        @finish="goBack"
        @next="onNext"
        @previous="onPrevious"
        @last-step-accessed="unblockPlugin"
        :testid="'activity-quiz'"
        v-bind="{ isNextDisabled }"
      >
        <template>
          <stepper-custom-step
            data-testid="activity-quiz-stepper-custom-step"
            :key="'activity-start'"
            class="activity-quiz-start"
          >
            <plugin-article
              :img="'activity/running.png'"
              :testid="'activity-quiz'"
            >
              <template slot="title">
                {{ $t('views.plugins.activity-quiz.active') }}
              </template>
              <template slot="content">
                <div class="activity-quiz__intro">
                  <p>
                    {{ $t('views.plugins.activity-quiz.content-1') }}
                  </p>
                  <p>
                    <b>{{ $t('views.plugins.activity-quiz.cta') }}</b>
                  </p>
                </div>
              </template>
            </plugin-article>
          </stepper-custom-step>

          <stepper-custom-step
            v-for="(item, i) in items"
            :key="`activity-${i}`"
            data-testid="activity-quiz-stepper-custom-step"
            class="activity-quiz__questions"
          >
            <plugin-article
              :img="item.image"
              :testid="'activity-quiz'"
            >

              <template slot="content">
                <div
                  class="activity-quiz__question"
                  data-testid="activity-quiz-question"
                >
                  {{ $t(item.question) }}
                </div>

                <div
                  class="activity-quiz__answers"
                  :data-testid="'activity-quiz-answers'"
                >
                  <grid>
                    <grid-column
                      v-for="(answer, j) in item.answers"
                      :key="j"
                      xs-12
                    >
                      <plugin-item
                        data-testid="activity-quiz-answers"
                        :isError="isSelected(answer.val) && !answer.correct"
                        :isSelected="isSelected(answer.val) && answer.correct"
                        :isSvg="false"
                        :isWrong="!answer.correct"
                        :item="answer"
                        :type="'radio'"
                        @click="setSelected(answer.val)"
                        @keydown.prevent.enter.space="setSelected(answer.val)"
                      />
                    </grid-column>
                  </grid>
                </div>

                <div
                  class="activity-quiz__outcome"
                  data-testid="activity-quiz-outcome"
                >
                  <div
                    v-if="isSelectedAnswerCorrect"
                    ref="successInfo"
                    :key="0"
                    aria-live="assertive"
                    class="activity-quiz__outcome-content"
                    data-testid="activity-quiz-outcome-success"
                  >
                      <p>{{ $t('Correct!') }}</p>
                      <p>{{ $t(item.explanation) }}</p>
                  </div>

                  <div
                    v-else-if="selectedAnswer && !isSelectedAnswerCorrect"
                    ref="errorInfo"
                    :key="1"
                    class="activity-quiz__outcome-content"
                    data-testid="activity-quiz-outcome-error"
                  >
                    <p>{{ $t('Try again') }}</p>
                  </div>
                </div>
              </template>

            </plugin-article>
          </stepper-custom-step>

          <stepper-custom-step
            data-testid="activity-quiz-stepper-custom-step"
            :key="'activity-end'"
            class="activity-quiz-end"
          >
            <plugin-article
              :img="'activity/tennis2.png'"
              :testid="'activity-quiz'"
            >
              <template slot="title">
                {{ $t('views.plugins.activity-quiz.end-title-1') }}<br>
                {{ $t('views.plugins.activity-quiz.end-title-2') }}
              </template>
              <template slot="content">
                <div
                  class="activity-quiz__end"
                  data-testid="activity-quiz-end"
                >
                  <p>
                    {{ $t('views.plugins.activity-quiz.content-2') }}
                  </p>
                  <p>
                    {{ $t('views.plugins.activity-quiz.content-3') }}
                  </p>
                </div>
              </template>
            </plugin-article>
          </stepper-custom-step>

        </template>
      </stepper-custom>

    </container>
  </base-main>
</template>

<script>
  import { mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import PluginItem from '@/components/plugin/plugin-item/PluginItem'
  import StepperCustom from '@/components/stepper-custom/StepperCustom'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import forceAnnounce from '@/mixins/forceAnnounce'
  import goBack from '@/mixins/goBack'
  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.activity-quiz.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      Grid,
      GridColumn,
      PluginArticle,
      PluginItem,
      StepperCustom,
      StepperCustomStep,
    },

    mixins: [
      forceAnnounce,
      goBack,
      unblockPlugin,
    ],

    data() {
      return {
        currentSlide: 0,
        currentStep: 0,
        selectedAnswer: null,
      }
    },

    methods: {
      isSelected(answer) {
        return this.selectedAnswer == answer
      },
      setSelected(answer) {
        this.selectedAnswer = answer
        if (!this.isSelectedAnswerCorrect) {
          this.forceAnnounce(this.$t('Try again'))
        }
      },
      onNext() {
        if (this.isNotExtreme) {
          this.currentSlide += 1
        }
        this.selectedAnswer = null
        this.currentStep += 1
      },
      onPrevious() {
        this.currentStep -= 1
        if (this.isNotExtreme) {
          this.currentSlide -= 1
        }
        this.selectedAnswer = null
      },
    },
    computed: {
      ...mapState('activityQuiz', [
        'items',
      ]),
      slidesAmount() {
        return this.items.length - 1
      },
      isNotExtreme() {
        const { currentStep, items } = this

        return currentStep != 0
          && currentStep != items.length + 1
      },
      isNextDisabled() {
        const { currentStep, isSelectedAnswerCorrect, items } = this

        return currentStep > 0
          && !isSelectedAnswerCorrect
          && currentStep < items.length + 1
      },
      isSelectedAnswerCorrect() {
        const { currentSlide, selectedAnswer, items } = this

        if (!selectedAnswer) return

        return items[currentSlide].answers
          .find(({ val }) => val == selectedAnswer)
          .correct
      },
    },
  }
</script>

<style lang="scss">
  .activity-quiz {
    &__wrapper {
      @include view-wrapper(77.5rem);
    }
    &__intro,
    &__question,
    &__outcome,
    &__end {
      max-width: 50rem;
      margin: 0 auto 2rem;
      text-align: center;
    }
    &__outcome {
      color: color(dark-primary);
      font-size: 1.4rem;
      text-align: center;
      > div > p:first-of-type {
        margin-top: 1em;
      }
    }
    &__outcome-content {
      animation: fadeIn;
      animation-duration: 0.2s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }
    &__answers {
      margin: 3rem 0;
    }
    .container {
      h2 {
        font-size: 2.2rem;
      }
      .plugin {
        &-title {
          padding: 0;
          @media all and (max-height: 600px) {
            margin: 1.5rem 0;
          }
          @media all and (max-height: 500px) {
            margin: 0;
            font-size: 1.5rem;
          }
        }
        &-content {
          text-align: center;
        }
        &-item {
          justify-content: center;
          min-width: 30rem;
          height: 6.45rem;
          margin: 0 1rem;
          cursor: pointer;
          @include max-sm {
            min-width: 15rem;
          }
          @media all and (max-height: 600px) {
            height: 4.45rem;
          }
          @media all and (max-height: 500px) {
            height: 4rem;
          }
          &::before {
            border-radius: 3rem;
          }
          &__title {
            margin-left: 0;
            text-align: left;
          }
        }
      }
    }
    &__buttons {
      display: flex;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
</style>
