export default {
  items: [
    {
      id: 1,
      icon: 'switcher/bread.svg',
      image: 'food/bread-mushroom.png',
      name: 'bread',
      title: 'data.shopping-list-switcher.title-1',
      description: 'data.shopping-list-switcher.description-1',
    },
    {
      id: 2,
      icon: 'switcher/cornflakes.svg',
      image: 'food/eggs-bacon.png',
      name: 'cornflakes',
      title: 'data.shopping-list-switcher.title-2',
      description: 'data.shopping-list-switcher.description-2',
    },
    {
      id: 3,
      icon: 'switcher/potato.svg',
      image: 'food/bread-mushroom.png',
      name: 'potatoes',
      title: 'data.shopping-list-switcher.title-3',
      description: 'data.shopping-list-switcher.description-3',
    },
    {
      id: 4,
      icon: 'switcher/apple.svg',
      image: 'food/apples-blueberries.png',
      name: 'apples',
      title: 'data.shopping-list-switcher.title-4',
      description: 'data.shopping-list-switcher.description-4',
    },
    {
      id: 5,
      icon: 'switcher/chips.svg',
      image: 'food/chips-carrots.png',
      name: 'chips',
      title: 'data.shopping-list-switcher.title-5',
      description: 'data.shopping-list-switcher.description-5',
    },
    {
      id: 6,
      icon: 'switcher/pasta.svg',
      image: 'food/courgette.png',
      name: 'pasta',
      title: 'data.shopping-list-switcher.title-6',
      description: 'data.shopping-list-switcher.description-6',
    },
    {
      id: 7,
      icon: 'food/rice.png',
      image: 'food/rice-greenveg.png',
      name: 'rice',
      title: 'data.shopping-list-switcher.title-7',
      description: 'data.shopping-list-switcher.description-7',
    },
    {
      id: 8,
      icon: 'food/biscuits.png',
      image: 'food/biscuits-nuts.png',
      name: 'biscuits',
      title: 'data.shopping-list-switcher.title-8',
      description: 'data.shopping-list-switcher.description-8',
    },
  ],
}
