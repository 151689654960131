<script>
  import Chart from 'chart.js'
  import { Doughnut, mixins } from 'vue-chartjs'

  const { reactiveProp } = mixins

  Chart.pluginService.register({
    afterUpdate(chart) {
      if (!chart.config.options.elements.arc.roundedCorners) return

      const { chartArea, innerRadius, outerRadius } = chart

      chart.getDatasetMeta(0).data.forEach(arc => {
        arc.round = {
          x: (chartArea.left + chartArea.right) / 2,
          y: (chartArea.top + chartArea.bottom) / 2,
          radius: (outerRadius + innerRadius) / 2,
          thickness: (outerRadius - innerRadius) / 2,
          backgroundColor: arc._model.backgroundColor,
        }
      })
    },

    afterDraw(chart) {
      if (!chart.config.options.elements.arc.roundedCorners) return

      const { ctx } = chart.chart

      chart.getDatasetMeta(0).data.forEach(arc => {
        const endAngle = Math.PI / 2 - arc._view.endAngle

        ctx.save()
        ctx.translate(arc.round.x, arc.round.y)
        ctx.fillStyle = arc.round.backgroundColor
        ctx.beginPath()
        ctx.arc(arc.round.radius * Math.sin(endAngle),
          arc.round.radius * Math.cos(endAngle),
          arc.round.thickness, 0, 2 * Math.PI)
        ctx.closePath()
        ctx.fill()
        ctx.restore()
      })
    },
  })

  export default {
    extends: Doughnut,

    mixins: [
      reactiveProp,
    ],

    props: {
      chartData: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    mounted() {
      this.renderChart(this.chartData, this.options)
      this.generateLegend()
      this.$refs.canvas.classList.add('doughnut-chart')
    },
  }
</script>

<style lang="scss">
  .doughnut-chart {
    position: relative;
    z-index: z-index(base);
  }
</style>
