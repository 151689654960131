<template>
  <dropdown-menu
    ref="dropdown"
    class="menu"
    data-testid="menu"
    :ariaLabel="$t('System')"
    :isOpen="menu.isOpen"
    :triggerButtonSelector="'.header__menu--is-visible'"
    v-bind="{ id }"
    @keydown.esc.native="closeMenu"
    @focus-out="onFocusOut"
    @clickOutside="closeMenu"
  >
    <template
      v-if="!isAppKioskMode"
    >
      <dropdown-menu-item
        data-testid="menu-profile"
        :link="{ name: 'Profile' }"
        @click="closeMenu"
      >
        {{ $t('My profile') }}
      </dropdown-menu-item>

      <dropdown-menu-item
        data-testid="menu-support"
        :link="{ name: 'Support' }"
        @click="closeMenu"
      >
        {{ $t('Support') }}
      </dropdown-menu-item>

      <language-switcher
        class="dropdown-menu-item"
        @languageChanged="closeMenu"
      />

      <dropdown-menu-item
        data-testid="menu-logout"
        @click="handleLogout"
      >
        {{ $t('Logout') }}
      </dropdown-menu-item>

      <hr v-if="hasKioskMode">
    </template>

    <template
      v-if="hasKioskMode"
    >
      <dropdown-menu-item
        data-testid="menu-kiosk-mode"
        @click="openPinKioskModeDialog"
      >
        {{
          isAppKioskMode
            ? $t(`${$options.slug}.disable-kiosk-mode`)
            : $t(`${$options.slug}.enable-kiosk-mode`)
        }}
      </dropdown-menu-item>

      <dropdown-menu-item
        v-if="!isAppKioskMode"
        data-testid="menu-plugin-kiosk-mode"
        @click="openPluginKioskModeDialog"
      >
        {{ $t(`${$options.slug}.enable-plugin-kiosk-mode`) }}
      </dropdown-menu-item>
    </template>
  </dropdown-menu>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import DropdownMenu from '@/components/dropdown-menu/DropdownMenu'
  import DropdownMenuItem from '@/components/dropdown-menu/dropdown-menu-item/DropdownMenuItem'

  import eventBus from '@/tools/event-bus'

  import LanguageSwitcher from './LanguageSwitcher'

  export default {
    components: {
      DropdownMenu,
      DropdownMenuItem,
      LanguageSwitcher,
    },

    props: {
      id: {
        type: String,
        required: true,
      },
    },

    created() {
      eventBus.$on('pinValidation', this.onPinValidation)
    },

    beforeDestroy() {
      eventBus.$off('pinValidation', this.onPinValidation)
    },

    watch: {
      hasKioskMode() {
        if (!this.isCurrentUserAuthorized) return

        this.recreateMenuTl()
      },
      isPackageOrProgramLoading() {
        if (this.isPackageOrProgramLoading) return

        this.fetchLanguages()
      },
    },

    methods: {
      ...mapActions('auth', [
        'logout',
      ]),
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapActions('kioskMode', [
        'enableAppKioskMode',
        'disableAppKioskMode',
      ]),
      ...mapActions('languages', [
        'fetchLanguages',
      ]),
      ...mapMutations('layout', [
        'set',
      ]),
      closeMenu() {
        this.set({
          state: 'menu',
          value: {
            isOpen: false,
            trigger: '',
          },
        })
      },
      onFocusOut() {
        this.closeMenu()
      },
      handleLogout() {
        this.closeMenu()

        this.logout()
          .then(this.$nextTick)
          .then(() => this.$router.push({ name: 'AuthLoggedOut' }))
      },
      onPinValidation(isValid) {
        if (!isValid) return

        this.recreateMenuTl()
      },
      openPluginKioskModeDialog() {
        this.closeMenu()
        this.openDialog({ component: 'plugin-kiosk-mode' })
      },
      openPinKioskModeDialog() {
        this.closeMenu()
        this.openDialog({ component: 'pin-kiosk-mode', props: { eventBus, selectedMode: 'App' } })
      },
      recreateMenuTl() {
        const { dropdown } = this.$refs

        if (!dropdown) return

        setTimeout(() => {
          dropdown.createMenuTl()
        }, 0)
      },
    },

    computed: {
      ...mapGetters('program', [
        'hasKioskMode',
      ]),
      ...mapState('auth', [
        'isCurrentUserAuthorized',
      ]),
      ...mapState('kioskMode', [
        'isAppKioskMode',
      ]),
      ...mapState('languages', [
        'languages',
      ]),
      ...mapState('layout', [
        'menu',
      ]),
      ...mapGetters('loading', [
        'isPackageOrProgramLoading',
      ]),
    },

    slug: 'component.layout.menu',
  }
</script>

<style lang="scss">
  .menu {
    top: 3rem;
    min-width: 13rem;
    max-width: 20.5rem;
    font-weight: 600;
  }
</style>
