<template>
  <base-main
    data-testid="temptations"
    :loading="$options.loading"
  >
    <container
      v-if="!answers"
      first
      flex-column
      plugin-article>

        <plugin-article
          :img="'quiz/winning-against-temptations.png'"
          :testid="'temptations'"
        >
          <template slot="title">
            {{ $t('Winning Against Temptations') }}
          </template>
          <template slot="content">
            <p>
              {{ $t(`${$options.slug}.content-1`) }}
            </p>
          </template>
        </plugin-article>

        <base-button
          align-end
          data-testid="temptations-next-button"
          :link="{ name: 'TemptationsQuiz' }"
        >
          {{ $t('Next') }}
        </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import shouldFetch from '@/mixins/shouldFetch'

  const pluginName = 'winningAgainstTemptations'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.temptations-quiz.temptations.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    mixins: [
      shouldFetch,
    ],

    created() {
      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(() => {
          if (!this.answers) return

          this.$router.replace({ name: 'TemptationsResults' })
        })
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
    },

    computed: {
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
    },

    slug: 'views.plugins.temptations-quiz.temptations',

    loading: [
      `${pluginName}/fetchAnswers`,
    ],
  }
</script>
