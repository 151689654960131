<template>
  <base-main
    class="duk-risk-form"
    data-testid="duk-risk-calculator-form"
  >
    <container
      stepper
    >
      <stepper-form
        @submit="onSubmit"
      >
        <stepper-step
          :fieldsNames="'gender'"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.gender-label`) }}
          </template>
          <template slot="content">
            <form-group
              :name="'gender'"
              :validation="$options.validation.gender"
            >
              <chips>
                <chip-radio-group
                  v-for="(option, i) in $static.genderOptions"
                  :key="i"
                  :name="'gender'"
                  :label="$t(option.label)"
                  :inputValue="option.value"
                  v-model="form.gender"
                />
              </chips>
            </form-group>
          </template>
        </stepper-step>

        <stepper-step
          :fieldsNames="'age'"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.age-label`) }}
          </template>
          <template slot="content">
            {{ form.age }}
            <input-group
              :validation="$options.validation.age"
              :label="$t('Age')"
              :name="'age'"
              :type="'number'"
              :min="18"
              :max="200"
              :step="1"
              v-model="form.age"
            />
          </template>
        </stepper-step>

        <stepper-step
          :fieldsNames="'ethnicity'"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.ethnicity-label`) }}
          </template>
          <template slot="content">
            <form-group
              :name="'ethnicity'"
              :validation="$options.validation.ethnicity"
            >
              <chips>
                <chip-radio-group
                  v-for="(option, i) in $static.ethnicityOptions"
                  :key="i"
                  :name="'ethnicity'"
                  :label="$t(option)"
                  :inputValue="option"
                  v-model="form.ethnicity"
                />
              </chips>
            </form-group>
          </template>
        </stepper-step>

        <stepper-step
          :fieldsNames="'family_history_diabetes'"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.history-diabetes-label`) }}
          </template>
          <template slot="content">
            <form-group
              :name="'family_history_diabetes'"
              :validation="$options.validation.family_history_diabetes"
            >
              <chips>
                <chip-radio-group
                  v-for="(option, i) in $static.booleanOptions"
                  :key="i"
                  :name="'family_history_diabetes'"
                  :label="$t(option.label)"
                  :inputValue="option.value"
                  v-model="form.family_history_diabetes"
                />
              </chips>
            </form-group>
          </template>
        </stepper-step>

        <stepper-step
          :fieldsNames="'waist_circumference'"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.waist-circumference-label`) }}
          </template>
          <template slot="content">
            <form-group
              :name="'waist_circumference'"
              :validation="$options.validation.waist_circumference"
            >
              <chips>
                <chip-radio-group
                  v-for="(option, i) in $static.waistOptions"
                  :key="i"
                  :name="'waist_circumference'"
                  :label="$t(option.label)"
                  :inputValue="option.value"
                  v-model="form.waist_circumference"
                />
              </chips>
            </form-group>
          </template>
        </stepper-step>

        <stepper-step
          :fieldsNames="['height', 'weight']"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.bmi-label`) }}
          </template>
          <template slot="content">
            <grid>
              <grid-column
                xs-4
                sm-3
                xl-2
                class="duk-risk-form-col"
              >
                <input-group
                  :label="'Height'"
                  :name="'height'"
                  :type="'number'"
                  :min="50"
                  :max="250"
                  :validation="$options.validation.height"
                  v-model="form.height"
                />
                cm
              </grid-column>
              <grid-column xs-1/>
              <grid-column
                xs-4
                sm-3
                xl-2
                class="duk-risk-form-col"
              >
                <input-group
                  :label="'Weight'"
                  :name="'weight'"
                  :type="'number'"
                  :min="20"
                  :max="400"
                  :step="0.1"
                  :validation="$options.validation.weight"
                  v-model="form.weight"
                />
                kg
              </grid-column>
            </grid>
          </template>
        </stepper-step>

        <stepper-step
          :fieldsNames="'contactTime'"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.your-bmi`) }}
          </template>
          <template slot="content">
            <p class="stepper-additional stepper-additional--dark">
              {{ $t(`${$static.slug}.content-1`) }}
            </p>
            <p class="duk-risk-form-bmi">
              <span
                class="duk-risk-form-bmi-number"
                data-testid="duk-risk-calculator-form-bmi-result"
              >
                {{ bmiResult }}
              </span>
              ({{ $t(bmiDescription) }})
            </p>
            <a
              class="duk-risk-form-link"
              data-testid="duk-risk-calculator-form-bmi-learn-button"
              href="https://www.nhs.uk/live-well/healthy-weight/bmi-calculator/"
              target="_blank"
            >
              {{ $t(`${$static.slug}.learn-bmi`) }}
            </a>
          </template>
        </stepper-step>

        <stepper-step
          :fieldsNames="'blood_pressure_medication'"
          v-bind="{ isSaving }"
        >
          <template slot="label">
            {{ $t(`${$static.slug}.label`) }}
          </template>
          <template slot="content">
            <form-group
              :name="'blood_pressure_medication'"
              :validation="$options.validation.blood_pressure_medication"
            >
              <chips>
                <chip-radio-group
                  v-for="(option, i) in $static.booleanOptions"
                  :key="i"
                  :name="'blood_pressure_medication'"
                  :label="$t(option.label)"
                  :inputValue="option.value"
                  v-model="form.blood_pressure_medication"
                />
              </chips>
            </form-group>
          </template>
        </stepper-step>
      </stepper-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Chips from '@/components/chips/Chips'
  import Container from '@/components/container/Container'
  import ChipRadioGroup from '@/components/forms/chip-radio-group/ChipRadioGroup'
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import StepperForm from '@/components/forms/stepper-form/StepperForm'
  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import StepperStep from '@/components/stepper/stepper-step/StepperStep'

  import numberizeProperties from '@/mixins/numberizeProperties'

  const slug = 'views.plugins.duk-risk-calculator.duk-risk-calculator-form'

  export default {
    metaInfo() {
      return {
        title: this.$t(`${slug}.meta.title`),
      }
    },

    components: {
      BaseMain,
      Chips,
      ChipRadioGroup,
      Container,
      Grid,
      GridColumn,
      InputGroup,
      StepperForm,
      StepperStep,
      FormGroup,
    },

    mixins: [
      numberizeProperties,
    ],

    data() {
      return {
        form: {
          gender: '',
          age: this.getCurrentUserAge,
          ethnicity: '',
          family_history_diabetes: null,
          waist_circumference: '',
          height: '',
          weight: '',
          blood_pressure_medication: null,
        },

        optionalData: {},
      }
    },

    methods: {
      ...mapActions('optionalProfileData', [
        'updateOptionalData',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        this.numberizeProperties('form.age', 'form.height', 'form.weight')
        this.optionalData.result_score_duk = this.calculateDuk([
          this.genderFactor,
          this.ageFactor,
          this.ethnicityFactor,
          this.familyDiabetesFactor,
          this.waistCircumferenceFactor,
          this.bmiFactor,
          this.bloodPressureMedicationFactor,
        ])

        this.optionalData.result_duk = this.calculateResult(this.optionalData.result_score_duk)
        this.optionalData.weight = this.form.weight
        this.optionalData.height = this.form.height
        this.optionalData.bmi = Math.min(this.bmiResult, 300)
        this.optionalData.ethnic_background_duk = this.form.ethnicity
        this.optionalData.family_diabetes_duk = this.form.family_history_diabetes
        this.optionalData.gender_duk = this.form.gender

        const optionalDataPayload = Object.keys(this.optionalData)
          .map(key => ({
              category: this.$static.keyCategoryMap[key],
              key: key.replace(/_/g, '-'),
              value: key === 'result_duk' ? this.optionalData[key].name : this.optionalData[key],
            }))

        this.updateOptionalData(optionalDataPayload)
          .then(() => {
            this.$mam.event('DUK Risk Calculator completed', {
              dukrisk_gender: this.form.gender,
              dukrisk_age: this.form.age,
              dukrisk_ethnic_background: this.form.ethnicity,
              dukrisk_family_with_diabetes: this.form.family_history_diabetes ? 'Yes' : 'No',
              dukrisk_waist_measurement: this.form.waist_circumference,
              dukrisk_height: this.form.height,
              dukrisk_weight: this.form.weight,
              dukrisk_blood_pressure_medication: this.form.blood_pressure_medication ? 'Yes' : 'No',
            })
            this.$router.push({
              name: 'DukRiskCalculatorResults',
              params: {
                score: this.optionalData.result_score_duk,
                result: this.optionalData.result_duk,
              },
            })
          })
      },
      calculateDuk(factors) {
        return factors.reduce((a, b) => a + b)
      },
      calculateResult(score) {
        return score < 7
          ? this.$static.results.low
          : score < 16
            ? this.$static.results.increased
            : score < 25
              ? this.$static.results.moderate
              : this.$static.results.high
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('user', [
        'getCurrentUserAge',
      ]),
      bmiResult() {
        const height = this.form.height / 100
        return Math.floor(this.form.weight / (height ** 2)) || 0
      },
      bmiDescription() {
        const bmi = this.bmiResult

        return bmi <= 18.5
          ? 'Underweight'
          : bmi > 18.5 && bmi <= 24
            ? 'Healthy'
            : bmi > 24 && bmi <= 29
              ? 'Overweight'
              : bmi > 29 && bmi <= 39
                ? 'Obese'
                : 'Extremely obese'
      },
      genderFactor() {
        return this.form.gender === this.$static.genderOptions[0]?.value ? 1 : 0
      },
      ageFactor() {
        return this.form.age < 50
            ? 0
            : this.form.age < 60
              ? 5
              : this.form.age < 70
                ? 9
                : 13
      },
      ethnicityFactor() {
        return this.form.ethnicity === this.$static.ethnicityOptions[4]
          || this.form.ethnicity === this.$static.ethnicityOptions[5] ? 0 : 6
      },
      familyDiabetesFactor() {
        return this.form.family_history_diabetes ? 5 : 0
      },
      waistCircumferenceFactor() {
        return this.form.waist_circumference === this.$static.waistOptions[0].value
          ? 0
          : this.form.waist_circumference === this.$static.waistOptions[1].value
            ? 4
            : this.form.waist_circumference === this.$static.waistOptions[2].value
              ? 6
              : 9
      },
      bmiFactor() {
        return this.bmiResult < 25
            ? 0
            : this.bmiResult < 30
              ? 3
              : this.bmiResult < 35
                ? 5
                : 8
      },
      bloodPressureMedicationFactor() {
        return this.form.blood_pressure_medication ? 5 : 0
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'optionalProfileData/updateOptionalData',
        ])
      },
    },

    static() {
      return {
        slug,
        genderOptions: [{ label: `${slug}.gender-1`, value: 'Male' }, { label: `${slug}.gender-2`, value: 'Female' }],
        ethnicityOptions: ['South Asian', 'Black', 'Chinese', 'Mixed ethnicity', 'White', 'None of these'],
        waistOptions: [{ label: `${slug}.waist-options-1`, value: 'Less than 90cm' }, { label: '90 - 99.9cm', value: '90 - 99.9cm' }, { label: '100 - 109.9cm', value: '100 - 109.9cm' }, { label: `${slug}.waist-options-2`, value: '110cm or over' }],
        booleanOptions: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        results: {
          low: {
            name: 'Low',
            chances: `${slug}.chance-20`,
          },
          increased: {
            name: 'Increased',
            chances: `${slug}.chance-10`,
          },
          moderate: {
            name: 'Moderate',
            chances: `${slug}.chance-7`,
          },
          high: {
            name: 'High',
            chances: `${slug}.chance-3`,
          },
        },
        keyCategoryMap: {
          bmi: 'capturing-bmi',
          result_duk: 'duk-risk-calculator',
          result_score_duk: 'duk-risk-calculator',
          ethnic_background_duk: 'duk-risk-calculator',
          family_diabetes_duk: 'duk-risk-calculator',
          gender_duk: 'duk-risk-calculator',
          weight: 'capturing-bmi',
          height: 'capturing-bmi',
        },
      }
    },

    validation: {
      gender: {
        required: true,
      },
      age: {
        required: true,
        min_value: 18,
        max_value: 200,
      },
      ethnicity: {
        required: true,
      },
      waist_circumference: {
        required: true,
      },
      height: {
        required: true,
        min_value: 50,
        max_value: 250,
      },
      weight: {
        required: true,
        min_value: 20,
        max_value: 400,
      },
      blood_pressure_medication: {
        required: true,
      },
      family_history_diabetes: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .duk-risk-form {
    &-col {
      display: flex;
      align-items: baseline;
    }
    &-bmi {
      color: color(dark-primary);
    }
    &-bmi-number {
      font-size: 2rem;
    }
    &-link {
      margin: 0 0 1rem 0;
      color: color(primary);
      text-decoration: underline;
    }
  }
</style>
