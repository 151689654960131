<template>
  <base-main
    class="temptations-quiz"
    data-testid="temptations-quiz"
    :loading="$options.loading"
  >
    <container>
      <base-form
        class="temptations-quiz-form"
        ref="form"
        :hasSubmitButton="false"
        v-bind="{ isSaving }"
        @submit="onSubmit">

          <plugin-article
            no-margin-bottom
            :testid="'temptations-quiz'"
          >
            <template slot="title">
              {{ i18n('header') }}
            </template>

            <template slot="content">
              <fieldset
                class="temptations-quiz-field"
                v-for="(item, i) in form"
                :key="i">
                  <input-group
                    :label="i18n('input-label')"
                    :name="`temptation-input-${i}`"
                    :tag="'div'"
                    :type="'text'"
                    :validation="getInputValidation(i)"
                    :testid="'temptations-quiz'"
                    v-model="item.value"
                  />
                  <div
                    class="temptations-quiz-field-text"
                  >
                    {{ i18n('content') }}
                  </div>
              </fieldset>

              <button-add-another
                data-testid="temptations-quiz-add-extra-field"
                @click="addExtraField"
              />

            </template>
          </plugin-article>

          <base-button
            class="temptations-quiz__submit"
            align-end
            data-testid="temptations-quiz-next-button"
            :isLoading="isSaving"
            :type="'submit'"
          >
            {{ $t('Next') }}
          </base-button>
        </base-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import {
    BaseButton,
    BaseForm,
    BaseMain,
  } from '@/components/base'
  import ButtonAddAnother from '@/components/buttons/button-add-another/ButtonAddAnother'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import i18n from '@/mixins/i18n'
  import { saveAnswers } from '@/mixins/quizzes-answers'

  const pluginName = 'winningAgainstTemptations'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.temptations-quiz.temptations-quiz.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseForm,
      BaseMain,
      ButtonAddAnother,
      Container,
      InputGroup,
      PluginArticle,
    },

    mixins: [
      i18n,
      saveAnswers(pluginName),
    ],

    props: {
      isEdit: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRerun: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    created() {
      this.fillInForm()
      this.redirectIfNeccessary()
    },

    data() {
      return {
        form: [{ value: '' }],
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        this.cleanUpForm()

        this.saveAnswers()
          .then(() => this.$router.push({ name: 'TemptationsResults' }))
      },
      redirectIfNeccessary() {
        if (this.answers && !this.isRerun && !this.isEdit) {
          this.$router.replace({ name: 'TemptationsResults' })
        }
      },
      async addExtraField() {
        const isValid = await this.$refs.form.$refs.observer.validate()

        if (isValid && this.form[this.lastFieldIndex].value.length) {
          this.form.push({ value: '' })
        }
      },
      fillInForm() {
        if (!this.isEdit) return

        this.form = Object.values(this.answers)
      },
      cleanUpForm() {
        this.form = this.form.filter(item => item.value)
      },
      getInputValidation(i) {
        const max = this.form.length

        return i == this.lastFieldIndex && max > 1
                ? {}
                : this.$options.validation.temptation
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          `${pluginName}/addAnswers`,
          `${pluginName}/updateAnswers`,
        ])
      },
      lastFieldIndex() {
        return this.form.length - 1
      },
    },

    slug: 'views.plugins.temptations-quiz.temptations-quiz',

    loading: [
      `${pluginName}/fetchAnswers`,
    ],

    validation: {
      temptation: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .temptations-quiz {
    -webkit-overflow-scrolling: auto;
    &-form {
      justify-content: space-between;
      height: 100%;
    }
    &-field {
      margin: 2rem 0 0;
    }
    &-field-text {
      color: color(dark-primary);
      font-size: 1.5rem;
      font-weight: 600;
    }
    &__submit {
      @include max-sm {
        align-self: flex-start;
        margin: 1rem 0 0;
        overflow: visible;
      }
    }
  }
</style>
