<template>
  <base-main
    class="risk-results"
    data-testid="duk-risk-calculator-results"
  >
    <container
      first
      flex-column
      plugin-article>
        <plugin-article
          has-background
          data-testid="duk-risk-calculator-results"
          :img="'risk-calculator.png'"
        >
          <template slot="title">
            {{ $t(`${$static.slug}.your-result`) }}
          </template>
          <template slot="content">
            <stacked-bar-chart
              :ranges="chart.ranges"
              :labels="chart.labels"
              :colors="chart.colors"
              :position="score"
            />
            <p
              class="plugin-article-text plugin-article-text--center duk-risk-results-risk"
            >
              {{ $t(`${$static.slug}.your-risk`) }}
              <span
                class="duk-risk-results-number"
                data-testid="duk-risk-calculator-results-risk-name"
              >
                {{ $t(result.name) }}
              </span>
            </p>
            <p class="plugin-article-text plugin-article-text--center duk-risk-results-score">
              {{ $t(`${$static.slug}.your-answer`) }}
              <span
                class="duk-risk-results-number"
                data-testid="duk-risk-calculator-results-risk-score"
              >
                {{ score }}
              </span>
            </p>
            <p
              data-testid="duk-risk-calculator-results-chances"
            >
              <span class="duk-risk-results-chances">
                {{ $t(result.chances) }}
              </span>
              {{ $t(`${$static.slug}.risk-result-chances`) }}
            </p>
            <p v-if="resultIsLow">
              {{ $t(`${$static.slug}.result-1`) }}
            </p>
            <p v-else>
              {{ $t(`${$static.slug}.result-2`) }}
            </p>
          </template>
        </plugin-article>
    </container>
  </base-main>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { BaseMain } from '@/components/base'
  import StackedBarChart from '@/components/charts/stacked-bar-chart/StackedBarChart'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.duk-risk-calculator.duk-risk-calculator-results.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      PluginArticle,
      StackedBarChart,
    },

    mixins: [
      unblockPlugin,
    ],

    props: {
      score: {
        type: Number,
        required: true,
      },
      result: {
        type: Object,
        required: true,
      },
    },

    mounted() {
      this.unblockPlugin()
      this.chart.colors = [
        this.colors['color-chart1'],
        this.colors['color-chart3'],
        this.colors['color-chart4'],
        this.colors['color-chart5'],
      ]
    },

    data() {
      return {
        chart: {
          ranges: [[0, 6], [7, 15], [16, 24], [25, 47]],
          labels: ['Low', 'Increased', 'Moderate', 'High'],
          colors: [],
        },
      }
    },

    computed: {
      ...mapGetters('colors', [
        'colors',
      ]),
      annotationPosition() {
        return this.score > 47
          ? 100
          : (this.score / 47) * 100
      },
      resultIsLow() {
        return this.result.name === 'Low'
      },
    },

    beforeRouteEnter(to, from, next) {
      to.params.score !== undefined
        ? next()
        : next({ name: 'DukRiskCalculatorForm', replace: true })
    },

    static() {
      return {
        slug: 'views.plugins.duk-risk-calculator.duk-risk-calculator-results',
      }
    },
  }
</script>

<style lang="scss">
  .duk-risk-results {
    &-risk {
      font-size: 1.7rem;
      margin-bottom: 0;
    }
    &-score {
      font-size: 1.7rem;
      margin-top: 0;
      margin-bottom: 1em;
    }
    &-number {
      color: color(dark-primary);
      font-weight: 600;
    }
    &-chances {
      font-size: 1.7rem;
      color: color(dark-primary);
      font-weight: 600;
    }
    .plugin {
      &-title {
        padding: 2rem 0 1rem 0;
      }
      &-content {
        overflow-x: hidden;
      }
    }
  }
</style>
