<template>
  <focus-lock :disabled="!navigationDrawer.isOpen">
    <div
      ref="drawer"
      id="navigation-drawer"
      class="navigation-drawer"
      :class="{
        'navigation-drawer--is-long': !isFooterVisible,
        'navigation-drawer--is-open': navigationDrawer.isOpen,
      }"
      data-testid="navigation-drawer"
      :aria-expanded="navigationDrawer.isOpen"
      v-click-outside="closeNavigationDrawer"
      @keydown.esc="closeNavigationDrawer"
    >
      <div class="navigation-drawer__close">
        <button
          aria-controls="navigation-drawer"
          data-testid="navigation-drawer-close"
          :aria-label="i18n('close-drawer')"
          :aria-expanded="navigationDrawer.isOpen"
          :title="i18n('close-drawer')"
          @click="closeNavigationDrawer"
        >
          <inline-svg src="icons/icon-plus.svg"/>
        </button>
      </div>

      <p
        v-if="isPackageOrProgramLoading"
        class="navigation-drawer__empty"
      >
        {{ i18n('loading') }}
      </p>

      <nav
        v-else-if="currentProgramId"
        ref="programmes"
        class="navigation-drawer__container"
        :aria-label="i18n('programmes')"
      >
        <h2 class="navigation-drawer__heading">
          {{ i18n('programmes') }}
        </h2>

        <h3
          id="navigation-drawer-current"
          class="navigation-drawer__programme"
        >
          {{ i18n('current-programme') }}
        </h3>

        <navigation-drawer-item
          v-if="!isPackageOrProgramLoading && currentProgram"
          is-current
          aria-describedby="navigation-drawer-current"
          data-testid="navigation-drawer-current-program"
          :hasOtherPrograms="!!hasOtherPrograms"
          :item="currentProgram"
          :tag="'div'"
        />

        <template
          v-if="hasOtherPrograms"
        >
          <h3
            id="navigation-drawer-also"
            class="navigation-drawer__also"
          >
            {{ i18n('also-available') }}
          </h3>
          <ul
            v-if="!isPackageOrProgramLoading"
            class="navigation-drawer__items"
            aria-labelledby="navigation-drawer-also"
            data-testid="navigation-drawer-programs"
          >
            <navigation-drawer-item
              v-for="(item, i) in programsWithoutCurrent"
              :key="i"
              data-testid="navigation-drawer-item"
              :tag="'li'"
              v-bind="{ item }"
              @click.prevent="onClick(item.id)"
            />
          </ul>
        </template>

        <p
          v-else
          class="navigation-drawer__empty"
        >
          {{ i18n('not-available') }}
        </p>
      </nav>
    </div>
  </focus-lock>
</template>

<script>

  import { gsap } from 'gsap'
  import TinyGesture from 'tinygesture'
  import FocusLock from 'vue-focus-lock'

  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import NavigationDrawerItem from '@/components/layout/navigation-drawer/navigation-drawer-item/NavigationDrawerItem'

  import clickOutside from '@/directives/clickOutside'

  import capitalize from '@/filters/capitalize'

  import { clamp } from '@/helpers'

  import i18n from '@/mixins/i18n'

  export default {
    components: {
      NavigationDrawerItem,
      FocusLock,
    },

    directives: {
      clickOutside,
    },

    filters: {
      capitalize,
    },

    mixins: [
      i18n,
    ],

    mounted() {
      this.set({
          state: 'navigationDrawer',
          value: {
            isOpen: false,
            trigger: '',
            isValid: this.programsWithoutCurrent.length > 0,
          },
        })
      this.initGesture()
      this.setScrollToClose()
      this.toggleTouchEventListeners('add')
      window.addEventListener('resize', this.setScrollToClose)
    },

    beforeDestroy() {
      if (this.gesture) this.gesture.destroy()
      this.toggleTouchEventListeners('remove')
      window.removeEventListener('resize', this.setScrollToClose)
    },

    watch: {
      /* eslint-disable-next-line object-shorthand */
      'navigationDrawer.isOpen'(newValue) {
        this.clearPositionData()
        gsap.set('.overlay', { clearProps: 'all' })

        newValue ? this.moveFocus() : this.returnFocus()
      },
    },

    data() {
      return {
        start: 0,
        position: 0,
        newPosition: 0,
        movingPosition: 0,
        negativeScroll: 20,
        scrollToClose: 75,
        killableTween: null,
        isHorizontal: false,
        gesture: null,
      }
    },

    methods: {
      ...mapActions('bootstrap', [
        'bootstrap',
      ]),
      ...mapActions('notifications', [
        'fetchNotifications',
      ]),
      ...mapActions('program', [
        'changeCurrentProgram',
      ]),
      ...mapMutations('languages', [
        'setCurrentUserLanguage',
      ]),
      ...mapMutations('layout', [
        'set',
      ]),
      onClick(id) {
        this.toggleNavigationDrawer()
        this.setLanguage(id)
        this.changeCurrentProgram({ program_id: id })
          .then(this.bootstrap)
      },
      setLanguage(id) {
        const {
          locale,
          default_language: defaultLanguage,
          languages_used: languagesUsed,
        } = this.programsWithoutCurrent.find(program => program.id == id)

        const language = locale || defaultLanguage || languagesUsed?.[0]

        this.setCurrentUserLanguage({ language, priority: 2 })
      },
      initGesture() {
        if (!this.$refs.drawer) return

        this.gesture = new TinyGesture(this.$refs.drawer)

        this.gesture.on('panmove', e => {
          this.onPanMove(e, this.gesture.swipingHorizontal)
        })
      },
      toggleTouchEventListeners(action) {
        const events = ['touchstart', 'touchend']

        events.forEach(event => {
          const callback = `onTouch${this.$options.filters.capitalize(event.replace('touch', ''))}`
          this.$refs.drawer[`${action}EventListener`](event, this[callback])
        })
      },
      onTouchStart(e) {
        this.start = e.touches[0].pageX
      },
      onPanMove(e, isHorizontal) {
        if (!isHorizontal) return

        this.isHorizontal = true

        const movingPosition = e.touches[0].pageX
        const delta = movingPosition - this.start
        const newPosition = this.position - delta
        const atEnd = newPosition < this.negativeScroll

        this.movingPosition = movingPosition
        this.newPosition = newPosition

        if (!atEnd) {
          this.start = movingPosition
          this.position = newPosition
        }

        this.moveNavigationDrawer(e)
      },
      onTouchEnd(e) {
        if (!this.isHorizontal) return

        if (e.target.classList.contains('navigation-drawer-nav') || e.target.classList.contains('nav-icon')) return

        if (this.shouldCloseNavigationDrawer()) {
          gsap.to('.overlay', 0.2, { backgroundColor: 'rgba(0, 0, 0, 0)', clearProps: 'all' })

          gsap.to('.navigation-drawer', 0.1, {
                                                    x: '-100%',
                                                    clearProps: 'all',
                                                    onComplete: this.setNavigationDrawerState,
                                                  })
          this.position = 0
          this.start = 0
        } else {
          // we gotta kill this tween if there's touchend and
          // click at the same time where navigation drawer item is a target
          this.killableTween = gsap.to('.navigation-drawer', 0.2, { x: '0%' })
          gsap.to('.overlay', 0.2, { backgroundColor: 'rgba(0, 0, 0, 0.4)' })
        }
        this.isHorizontal = false
      },
      shouldCloseNavigationDrawer() {
        if (this.movingPosition == 0) return

        return this.newPosition >= 0 && this.movingPosition < this.scrollToClose
      },
      closeNavigationDrawer(e) {
        if (e.target.classList.contains('app-bar-nav') || e.target.classList.contains('nav-icon')) return
        if (!this.navigationDrawer.isOpen) return

        this.toggleNavigationDrawer()
      },
      toggleNavigationDrawer() {
        if (this.killableTween) {
          this.killableTween.kill()
        }

        gsap.set('.navigation-drawer', { clearProps: 'all' })
        this.setNavigationDrawerState()
      },
      moveNavigationDrawer(e) {
        const drawerWidth = this.$refs.drawer.clientWidth
        const { pageX } = e.touches[0]

        if (pageX > drawerWidth) return

        gsap.to('.navigation-drawer', 0.01, { x: -(drawerWidth - pageX) })

        const opacity = clamp((pageX / drawerWidth), 0, 0.4)
        gsap.to('.overlay', 0.01, { opacity })
      },
      setScrollToClose() {
        const drawerWidth = this.$refs.drawer.clientWidth
        this.scrollToClose = drawerWidth / 2
      },
      clearPositionData() {
        this.start = 0
        this.position = 0
        this.newPosition = 0
        this.movingPosition = 0
      },
      setNavigationDrawerState() {
        this.set({
          state: 'navigationDrawer',
          value: {
            isOpen: false,
            trigger: '',
          },
        })
      },
      moveFocus() {
        if (this.navigationDrawer.trigger === 'click') return

        setTimeout(() => {
          this.$refs.drawer
            .querySelector('.navigation-drawer__close button')
            .focus()
        }, 0)
      },
      async returnFocus() {
        if (this.navigationDrawer.trigger === 'click') return

        await this.$awaitTicks(1)
        document.querySelector('.header__drawer--is-visible')?.focus()
      },
    },

    computed: {
      ...mapGetters('loading', [
        'isPackageOrProgramLoading',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapState('layout', [
        'isFooterVisible',
        'navigationDrawer',
      ]),
      ...mapState('_package', [
        'package',
      ]),
      allPrograms() {
        return this.package?.programs || []
      },
      programsWithoutCurrent() {
        return this.allPrograms.filter(item => item.id !== this.currentProgramId)
      },
      currentProgram() {
        return this.allPrograms.find(item => item.id === this.currentProgramId)
      },
      hasOtherPrograms() {
        return this.programsWithoutCurrent.length
      },
    },

    slug: 'component.layout.navigation-drawer',
  }
</script>

<style lang="scss">
  $close_height: 2.3rem;
  $close_height--lg: 4.3rem;
  $top: 0;
  $top--lg: $header-items_height--lg + $header-stripe_height--lg;

  .navigation-drawer {
    position: fixed;
    top: $top;
    left: 0;
    height: calc(100% - #{$top} - #{$footer-height});
    width: 75%;
    background-color: color(dark-primary);
    transform: translateX(-101%);
    z-index: z-index(header) + 1;
    transition: transform 0.3s cubic-bezier(.25,.8,.5,1), visibility 0s 0.3s;
    overflow-y: hidden;
    visibility: hidden;
    @media all and (min-width: map-get($breakpoints, xs)) and (orientation: landscape) {
      max-width: 285px;
    }
    @include min-md {
      max-width: 252px;
    }
    @include min-lg {
      position: absolute;
      top: $top--lg;
      height: calc(100% - #{$top--lg} - #{$footer-height--lg});
    }
    @include min-xl {
      max-width: 315px;
    }
    &--is-long {
      height: 100%;
      @include min-lg {
        height: calc(100% - #{$top--lg});
      }
    }
    &--is-open {
      transform: translateX(0%);
      transition: transform 0.3s cubic-bezier(.25,.8,.5,1);
      visibility: visible;
    }
    &__close {
      display: flex;
      justify-content: flex-end;
      height: $close_height;
      margin: 1rem 0 0 0;
      @include min-lg {
        margin: 1rem 1rem 0 0;
        height: $close_height--lg;
      }
      svg {
        width: 2rem;
        height: 2rem;
        fill: color(_white);
        transform: rotate(45deg);
        @include min-lg {
          width: 4rem;
          height: 4rem;
        }
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      height: calc(100% - #{$close_height});
      padding: 0 2rem;
      @include min-lg {
        height: calc(100% - #{$close_height--lg});
        padding: 0 3rem;
      }
    }
    &__heading {
      margin: 0 0 2rem;
      color: color(primary);
      font-size: 1.7rem;
      @include min-lg {
        margin: 0 0 3.25rem;
        font-size: 2.2rem;
      }
    }
    &__programme {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin: 0 0 1rem;
      color: color(_white);
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      @include min-lg {
        letter-spacing: 0;
        font-size: initial;
      }
    }
    &__also {
      margin: 0 0 1rem;
      color: color(_white);
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      @include min-lg {
        margin: 0.8rem 0 1rem;
        letter-spacing: 0;
        font-size: initial;
      }
    }
    &__empty {
      padding: 3rem 1rem;
      color: color(_white);
      line-height: 1.5;
      text-align: center;
    }
    &__items {
      overflow-y: auto;
    }
  }
</style>
