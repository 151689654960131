<template>
  <div class="view">
    <button-skip-to-main-content/>

    <layout-resolver
      v-if="shouldRenderLayout"
      :key="$route.meta.layout"
      class="view__layout"
      :aria-hidden="ariaHidden"
    />

    <router-view
      v-else
      class="view__layout"
      :aria-hidden="ariaHidden"
    />

    <base-footer
      :aria-hidden="ariaHidden"
    />
    <notifications />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import { BaseFooter } from '@/components/base'
  import ButtonSkipToMainContent from '@/components/buttons/ButtonSkipToMainContent'
  import Notifications from '@/components/layout/notifications/Notifications'

  import LayoutResolver from '@/layouts/LayoutResolver'

  export default {
    components: {
      BaseFooter,
      ButtonSkipToMainContent,
      Notifications,
      LayoutResolver,
    },

    computed: {
      ...mapState('layout', [
        'isGlobalAriaHidden',
      ]),

      shouldRenderLayout() {
        const { $route } = this

        return !!($route.meta?.layout || $route.matched.some(r => r.meta?.layout))
      },

      ariaHidden() {
        return this.isGlobalAriaHidden
      },
    },
  }
</script>

<style lang="scss">
  .view {
    display: flex;
    flex-direction: column;
    height: 100%;
    &__layout {
      flex-grow: 1;
    }
  }
</style>
