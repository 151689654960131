<template functional>
  <div class="weekly-review-no-goals">
    <h2>
      {{ parent.$t($options.statics.header, { typeOfGoals: props.type }) }}
    </h2>
    <p>
      {{ parent.$t($options.statics.paragraph) }}
    </p>
  </div>
</template>

<script>
  const slug = 'component.weekly-review.weekly-review-no-goals'

  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
    },

    slug,

    statics: {
      header: `${slug}.header`,
      paragraph: `${slug}.paragraph`,
    },
  }
</script>

<style lang="scss">
  .weekly-review {
    &-no-goals {
      margin: 4rem 0 0;
    }
  }
</style>
