<template>
  <plugin-article
    has-background
    :img="`weekly-review/${$options.images[step]}.png`">
      <slot/>
      <template slot="title">
        {{ message.title }}
      </template>
      <template slot="content">
        <p>
          {{ message.content }}
        </p>
      </template>
  </plugin-article>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  export default {
    metaInfo() {
      const { step } = this

      return {
        title: this.$t(`views.plugins.weekly-review.weekly-review-feedback.${step}.meta.title`),
      }
    },

    components: {
      PluginArticle,
    },

    computed: {
      ...mapGetters('weeklyReview', [
        'getFeedbackMessage',
      ]),
      ...mapState('weeklyReview', [
        'stepsArray',
      ]),
      message() {
        return this.getFeedbackMessage(this.step)
      },
      step() {
        const { steps } = this.$route.meta
        if (Array.isArray(steps)) {
          return this.stepsArray.find(item => this.$route.meta.steps.includes(item))
        }

        return steps
      },
    },

    images: {
      'activity-goals': 'activity',
      walking: 'activity',
      bmi: 'weight',
      eating: 'eating',
      weight: 'weight',
    },
  }
</script>
