<template>
  <base-main
    :loading="$options.loading"
    :data-testid="'coaching-plugin-content'"
  >
    <div
      v-if="content"
      class="coaching-content"
    >
      <portal-target :name="'sub-tabs'"/>
      <h2
        class="coaching-content__heading"
        :data-testid="'coaching-plugin-content-heading'"
      >
        {{ $t(`${$options.slug}.heading`) }}
      </h2>
      <p
        class="coaching-content__description"
        :data-testid="'coaching-plugin-content-description'"
      >
        {{ $t(`${$options.slug}.description`) }}
      </p>
      <div class="coaching-content__layout">
        <modular-tile
          v-for="({ item: { title, description }, id }, i) in content"
          :key="i"
          :data-testid="'coaching-plugin-content-item'"
          :headerLevel="3"
          :headerText="title"
          :testid="'coaching-plugin-content-item'"
        >
          <p
            :data-testid="'coaching-plugin-content-item-description'"
          >
            {{ description }}
          </p>
          <template #action>
            <router-link
              class="anchor"
              :data-testid="'coaching-plugin-content-item-see-button'"
              :to="{ name: 'CoachingPluginShowContent', params: { id } }"
            >
              {{ $t(`${$options.slug}.view`, { title: title }) }}
            </router-link>
          </template>
        </modular-tile>
      </div>
    </div>

    <empty-view v-else>
      {{ $t(`${$options.slug}.no-content`) }}
    </empty-view>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import EmptyView from '@/components/empty-view/EmptyView'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  import shouldFetch from '@/mixins/shouldFetch'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.coaching-plugin.coaching-plugin-content.meta.title'),
      }
    },

    components: {
      BaseMain,
      EmptyView,
      ModularTile,
    },

    mixins: [
      shouldFetch,
    ],

    created() {
      this.shouldFetch('preferences', 'fetchPreferences', this.getCurrentUserId)
        .then(({ shouldRedirectToStart } = {}) => {
          if (shouldRedirectToStart) {
            return this.$router.replace({
              name: 'CoachingPluginStart',
              params: { fromRedirect: true },
            })
          }

          this.fetchContent({
            userId: this.getCurrentUserId,
            programId: this.currentProgramId,
          })
        })
    },

    methods: {
      ...mapActions('coaching', [
        'fetchContent',
        'fetchPreferences',
      ]),
    },

    computed: {
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapState('coaching', [
        'content',
        'preferences',
      ]),
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin-content',

    loading: [
      'coaching/fetchContent',
      'coaching/fetchPreferences',
    ],
  }
</script>

<style lang="scss">
  .coaching-content {
    width: 100%;
    a.sub-tab:visited:not(:hover) {
      color: color(primary);
    }
    a.sub-tab--active:visited:not(:hover) {
      color: color(_white);
    }
    a.sub-tab--active:hover {
      color: color(button-hover-text);
    }
    &__heading {
      margin: 0;
      font-size: 2.2rem;
      font-weight: 800;
    }
    &__description {
      margin: 2rem 0 0;
      font-size: 1.4rem;
    }
    &__layout {
      display: grid;
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
      grid-template-columns: 1fr;
      margin: 3rem 0 3rem;
      @include min-lg {
        grid-template-columns: repeat(2, 1fr);
      }
      .modular-tile {
        margin: 0;
      }
    }
  }
</style>
