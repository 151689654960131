<template>
  <div class="card-goal">

    <goal-tile
      v-bind="{ plugin, title, answer }"/>

    <fieldset
      v-if="hasQuestion"
      class="card-goal-fieldset">
        <legend class="card-goal-label">
          How often have you met this goal over the past week?
        </legend>

        <form-group
          :name="`goal_answer_${uuid}`"
          v-bind="{ validation }">
            <chips>
              <chip-radio-group
                v-for="(option, i) in $options.options"
                :key="i"
                has-border
                :inputValue="option.value"
                :label="option.label"
                :name="`goal_answer_${uuid}`"
                v-model="model"/>
            </chips>
        </form-group>
    </fieldset>

  </div>
</template>

<script>
  import Chips from '@/components/chips/Chips'
  import ChipRadioGroup from '@/components/forms/chip-radio-group/ChipRadioGroup'
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import GoalTile from '@/components/tiles/goal-tile/GoalTile'

  export default {
    components: {
      ChipRadioGroup,
      Chips,
      GoalTile,
      FormGroup,
    },

    props: {
      hasQuestion: {
        type: Boolean,
        required: false,
        default: true,
      },
      plugin: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      answer: {
        type: String,
        required: false,
        default: '',
      },
      validation: {
        type: Object,
        required: false,
        default: null,
      },
      value: {
        type: [String, Number],
        required: false,
        default: '',
      },
    },

    computed: {
      model: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },

    options: [
      {
        label: 'Not at all',
        value: 1,
      },
      {
        label: 'Partly met',
        value: 2,
      },
      {
        label: 'Mostly or all met',
        value: 3,
      },
    ],
  }
</script>

<style lang="scss">
  .card-goal {
    padding: 1rem;
    background-color: color(_white);
    border-radius: 2px;
    box-shadow: box-shadow(default);
    &-header {
      padding: 1.5rem 2rem;
      background-color: color(light-primary);
      border-radius: 2px;
    }
    &-plugin {
      margin: 0 0 0.35rem 0;
      font-size: 1.3rem;
    }
    &-fieldset {
      padding: 1.25rem 1.5rem 0;
    }
    &-label {
      display: block;
      margin: 0 0 1rem;
    }
  }
</style>
