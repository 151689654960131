<template>
  <base-main
    class="maintenance-plan"
    data-testid="maintenance-plan-quiz"
    :loading="$options.loading"
  >
    <container
      first
      plugin-article
    >
      <base-form
        ref="form"
        class="maintenance-plan__form"
        :hasSubmitButton="false"
        :testid="'maintenance-plan-quiz'"
        v-bind="{ isSaving }"
        @submit="onSubmit"
      >
        <plugin-article
          no-margin-bottom
          :testid="'maintenance-plan-quiz'"
        >
          <template slot="content">
            <p>
              {{ i18n('header') }}
            </p>
            <checkbox-multi-group
              :legend="i18n('ideas-label')"
              :name="'maintenance_plans'"
              :options="plans"
              :testid="'maintenance-plan-quiz'"
              :validation="$options.validation.plans"
              v-model="form"
            />

            <div>
              <input-group
                ref="idea"
                :label="i18n('own-ideas')"
                :name="'idea'"
                :required="false"
                :testid="'maintenance-plan-quiz'"
                :type="'text'"
                :validation="$options.validation.idea"
                v-model="idea"
              />

              <button-add-another
                data-testid="maintenance-plan-add-idea"
                @click="addIdea"
              />
            </div>
          </template>
        </plugin-article>
        <base-button
          class="maintenance-plan__submit"
          align-end
          :disabled="isSaving"
          :isLoading="isSaving"
          :type="'submit'"
        >
          {{ $t('Next') }}
        </base-button>
      </base-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import {
    BaseButton,
    BaseForm,
    BaseMain,
  } from '@/components/base'
  import ButtonAddAnother from '@/components/buttons/button-add-another/ButtonAddAnother'
  import Container from '@/components/container/Container'
  import { CheckboxMultiGroup } from '@/components/forms/checkbox-group'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import maintenancePlanPlans from '@/data/maintenance-plan/maintenancePlanPlans'

  import i18n from '@/mixins/i18n'
  import { saveAnswers } from '@/mixins/quizzes-answers'
  import shouldFetch from '@/mixins/shouldFetch'

  const pluginName = 'maintenancePlan'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.maintenance-plan.maintenance-plan-quiz.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseForm,
      BaseMain,
      ButtonAddAnother,
      CheckboxMultiGroup,
      Container,
      InputGroup,
      PluginArticle,
    },

    mixins: [
      i18n,
      saveAnswers(pluginName),
      shouldFetch,
    ],

    props: {
      isRerun: {
        type: Boolean,
        required: false,
        default: false,
      },
      isEdit: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    created() {
      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(this.redirectIfNeccessary)
    },

    data() {
      return {
        form: [],
        idea: '',
        plans: [...maintenancePlanPlans],
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      async onSubmit() {
        if (this.idea) {
          await this.addIdea()
          await this.$nextTick()
        }

        const isValid = await this.$refs.form.$refs.observer.validate()

        if (isValid) {
          this.saveAnswers()
            .then(() => this.$router.push({ name: 'MaintenancePlanResults' }))
        }
      },
      redirectIfNeccessary() {
        return new Promise(resolve => {
          const condition = this.answers && !this.isRerun

          if (condition) {
            this.$router.replace({ name: 'MaintenancePlanResults' })
          }

          resolve(condition)
        })
      },
      addIdea() {
        if (!this.idea) return

        return this.$refs.idea.validate()
                .then(isValid => {
                  if (!isValid) return

                  this.plans.push(this.idea)
                  this.form.push(this.idea)
                  this.idea = ''
                })
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          `${pluginName}/addAnswers`,
          `${pluginName}/updateAnswers`,
        ])
      },
    },

    loading: [
      `${pluginName}/fetchAnswers`,
    ],

    validation: {
      idea: {
        min: 5,
        max: 120,
        regex: '^[^-\\s]',
      },
      plans: {
        required: true,
      },
    },

    slug: 'views.plugins.maintenance-plan.maintenance-plan-quiz',
  }
</script>

<style lang="scss">
  .maintenance-plan {
    .base-checkbox__check {
      flex: none;
    }
    &__form {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
    &__checkbox:last-child {
      margin: 1.8rem 0 1rem
    }
    &__submit {
      @include max-sm {
        margin: 0;
        align-self: flex-start;
      }
    }
  }
</style>
