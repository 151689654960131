<template>
  <base-main
    data-testid="weight-tracking-start"
    :loading="$options.loading"
  >
    <container
      first
    >
      <stepper-custom-form
        data-testid="weight-tracking-start-form"
        :finishButtonText="'Finish & Save'"
        :isLoading="isSaving"
        @cancel="onCancel"
        @submit="onSubmit"
      >
        <template #default>
          <stepper-custom-step
            data-testid="weight-tracking-start-stepper-custom-step"
            :title="$t(`${$options.slug}.title-1`)"
          >
            <div class="weight-tracking-start__step">
              <div class="weight-tracking-start__description">
                <p>
                  {{ $t(`${$options.slug}.description-1`) }}
                </p>
              </div>
              <div class="weight-tracking-start__radios">
                <radio-multi-group
                  :legend="$t(`${$options.slug}.title-1`)"
                  :name="'measurement_type'"
                  :options="$static.unitOptions"
                  :validation="$options.validation.measurement_type"
                  v-model="form.measurement_type"
                />
              </div>
            </div>
          </stepper-custom-step>

          <stepper-custom-step
            data-testid="weight-tracking-start-stepper-custom-step"
            :title="$t(`${$options.slug}.title-2`)"
          >
            <div class="weight-tracking-start__step">
              <div class="weight-tracking-start__description">
                <p>
                  {{ $t(`${$options.slug}.description-2`) }}
                </p>
              </div>
              <div class="weight-tracking-start__radios">
                <radio-multi-group
                  :legend="$t(`${$options.slug}.title-2`)"
                  :name="'reminders'"
                  :options="$static.remindersOptions"
                  :validation="$options.validation.reminders"
                  v-model="form.reminders"
                />
              </div>
            </div>
          </stepper-custom-step>
        </template>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import { RadioMultiGroup } from '@/components/forms/radio'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import getRange from '@/mixins/getRange'
  import { trackingSettingsBase } from '@/mixins/tracking-settings'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weight-tracking.weight-tracking-start.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      RadioMultiGroup,
      StepperCustomForm,
      StepperCustomStep,
    },

    mixins: [
      getRange,
      trackingSettingsBase,
    ],

    data() {
      return {
        form: {
          measurement_type: 'Kilograms',
          weight_goal: '0',
          reminders: null,
        },
      }
    },

    methods: {
      ...mapActions('user', [
        'updateUser',
      ]),
      ...mapActions('weightTracking', [
        'saveSettings',
      ]),
      // override
      fillForm() {
        const [kilograms, pounds, stonesAndPounds] = this.$options.unitOptions
        if (this.isMetric) {
          this.form.measurement_type = kilograms
        } else if (this.isPounds) {
          this.form.measurement_type = pounds
        } else {
          this.form.measurement_type = stonesAndPounds
        }
      },
      onSubmit(isValid) {
        if (!isValid) return

        const formData = new FormData()
        const userFormData = new FormData()

        Object.entries(this.form).forEach(([key, value]) => {
          formData.append(key, value)
        })

        userFormData.append('distance_unit', this.distanceUnit)
        userFormData.append('_method', 'PATCH')

        Promise.all([
          this.saveSettings(formData),
          this.getCurrentUserDistanceUnit != this.distanceUnit
            ? this.updateUser({ userId: this.getCurrentUserId, form: userFormData })
            : false,
        ])
          .then(() => this.$router.push({ name: 'WeightTracking' }))
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('user', [
        'getCurrentUserDistanceUnit',
        'getCurrentUserId',
        'isMetric',
        'isPounds',
      ]),
      ...mapState('weightTracking', [
        'settings',
      ]),
      distanceUnit() {
        if (this.form.measurement_type == 'Kilograms') {
          return 0
        }
        if (this.form.measurement_type == 'Pounds') {
          return 1
        }
        // this.form.measurement_type == 'StonesAndPounds'
        return 2
      },
      weightOptions() {
        const range = this.unitAbbr == 'kg'
          ? [20, 400]
          : [40, 880]

        return this.getRange(range).map(item => `${item} ${this.unitAbbr}`)
      },
      unitAbbr() {
        // unitAbbr
        if (this.form.measurement_type == 'Kilograms') {
          return 'kg'
        }
        if (this.form.measurement_type == 'Pounds') {
          return 'lbs'
        }
        // this.form.measurement_type == 'StonesAndPounds'
        return 'st, lbs'
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'weightTracking/saveSettings',
        ])
      },
    },

    loading: [
      'weightTracking/getSettings',
    ],

    static() {
      return {
        remindersOptions: ['Daily', 'Weekly', 'No reminders'],
        unitOptions: ['Kilograms', 'Pounds', 'Stones And Pounds'],
      }
    },

    slug: 'views.plugins.weight-tracking.weight-tracking-start',

    validation: {
      measurement_type: {
        required: true,
      },
      reminders: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .weight-tracking-start {
    &__description {
      &:last-child {
        margin: 0 0 1.8rem;
      }
      & > p:not(:first-child) {
        margin: 1.6rem 0 0;
      }
    }
    &__radios {
      margin: 3.2rem 0 1.8rem;
    }
    &__radio-label {
      margin: 0 0 1.6rem;
      font-weight: 600;
    }
    &__actions {
      margin: 1.8rem 0 0;
      &:last-child {
        margin: 1.8rem 0 1.8rem;
      }
    }
    &__profile {
      margin: 1.6rem 0;
    }
    &__change {
      display: flex;
      justify-content: center;
      @include min-md {
        justify-content: flex-start;
      }
      .button {
        max-width: 22rem;
        @include min-md {
          max-width: none;
        }
      }
    }
    .container {
      max-width: 63rem;
    }
    .stepper-custom-step__progress-indicator {
      display: none;
    }
  }
</style>
