export default [
  'data.maintenance-plan.plan-1',
  'data.maintenance-plan.plan-2',
  'data.maintenance-plan.plan-3',
  'data.maintenance-plan.plan-4',
  'data.maintenance-plan.plan-5',
  'data.maintenance-plan.plan-6',
  'data.maintenance-plan.plan-7',
  'data.maintenance-plan.plan-8',
  'data.maintenance-plan.plan-9',
  'data.maintenance-plan.plan-10',
  'data.maintenance-plan.plan-11',
  'data.maintenance-plan.plan-12',
  'data.maintenance-plan.plan-13',
  'data.maintenance-plan.plan-14',
  'data.maintenance-plan.plan-15',
]
