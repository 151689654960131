<template>
  <base-main
    class="reasons-weight"
    data-testid="reasons-weight"
    :loading="$options.loading"
  >
    <container
      first
      flex-column
      plugin-article
    >
      <base-form
        class="reasons-weight-form"
        ref="form"
        :testid="'reasons-weight'"
        v-bind="{ isSaving }"
        @submit="onSubmit"
      >
        <plugin-article
          :img="'quiz/reasons-to-lose-weight.png'"
          :testid="'reasons-weight'"
        >
          <template slot="title">
            {{ $t('Reasons to lose weight') }}
          </template>

          <template slot="content">
            <p>
              {{ i18n('content-1') }}
            </p>

            <h2>{{ i18n('content-2') }}</h2>

            <card-ordered-removable
              v-for="(item, i) in form"
              :key="i"
              data-testid="reasons-weight-card-ordered"
              :index="i"
              :content="item"
              @remove="removeReason(i)"/>

            <input-group
              :label="i18n('input-label')"
              :name="'reason'"
              :type="'text'"
              :validation="$options.validation.reason"
              v-model="reason"
            />

            <button-add-another
              data-testid="reasons-weight-add-extra-field"
              @click="addExtraField"
            />

          </template>
        </plugin-article>
      </base-form>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseForm from '@/components/base/BaseForm'
  import BaseMain from '@/components/base/BaseMain'
  import ButtonAddAnother from '@/components/buttons/button-add-another/ButtonAddAnother'
  import CardOrderedRemovable from '@/components/cards/card-ordered-removable/CardOrderedRemovable'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import i18n from '@/mixins/i18n'
  import { saveAnswers } from '@/mixins/quizzes-answers'
  import shouldFetch from '@/mixins/shouldFetch'
  import unblockPlugin from '@/mixins/unblockPlugin'

  const pluginName = 'reasonsToLoseWeight'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.reasons-to-lose-weight.meta.title'),
      }
    },

    components: {
      BaseForm,
      BaseMain,
      ButtonAddAnother,
      CardOrderedRemovable,
      Container,
      InputGroup,
      PluginArticle,
    },

    mixins: [
      i18n,
      saveAnswers(pluginName),
      shouldFetch,
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()

      this.shouldFetch('answers', 'fetchAnswers', pluginName)
        .then(() => {
          if (!this.answers) return

          this.form = this.answers
        })
    },

    data() {
      return {
        reason: '',
        form: [],
      }
    },

    methods: {
      ...mapActions('quizzesAnswers', [
        'fetchAnswers',
      ]),
      async addExtraField() {
        const isValid = await this.validate()

        if (!isValid || !this.reason.length) return

        this.form.push(this.reason)
        this.reason = ''
      },
      removeReason(i) {
        this.form.splice(i, 1)
      },
      onSubmit(isValid) {
        if (!isValid) return

        if (this.reason) {
          this.form.push(this.reason)
          this.reason = ''
        }

        this.saveAnswers()
      },
      validate() {
        return this.$refs.form.$refs.observer.validate()
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('quizzesAnswers', [
        'getAnswers',
      ]),
      answers() {
        return this.getAnswers(pluginName)
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          `${pluginName}/addAnswers`,
          `${pluginName}/updateAnswers`,
        ])
      },
    },

    slug: 'views.plugins.reasons-to-lose-weight',

    loading: [
      `${pluginName}/fetchAnswers`,
    ],

    validation: {
      reason: {
        min: 5,
        max: 120,
      },
    },
  }
</script>

<style lang="scss">
  .reasons-weight {
    -webkit-overflow-scrolling: auto;
    &-form {
      justify-content: space-between;
      height: 100%;
    }
    .plugin-article {
      margin: 0 0 1rem;
    }
  }
</style>
