<template>
  <base-main
    data-testid="risk-calculator"
  >
    <container
      first
      flex-column
      plugin-article>

        <plugin-article
          :img="'risk-calculator.png'"
          :testid="'risk-calculator'"
        >
          <template slot="content">
            <p
              class="plugin-article-text plugin-article-text--center"
              data-testid="risk-calculator-text"
            >
              {{ $t(`${$options.slug}.text`) }}
            </p>
          </template>
        </plugin-article>

        <base-button
          align-end
          data-testid="risk-calculator-button-next"
          :link="{ name: 'RiskCalculatorForm' }"
        >
          {{ $t('Next') }}
        </base-button>
    </container>
  </base-main>
</template>

<script>
  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.risk-calculator.risk-calculator.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      PluginArticle,
    },

    slug: 'views.plugins.risk-calculator.risk-calculator',
  }
</script>
