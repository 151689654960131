<template>
  <base-main
    class="risk-results"
    data-testid="risk-calculator-results"
  >
    <container
      first
      flex-column
      plugin-article>

        <plugin-article
          has-background
          :img="'risk-calculator.png'"
          :testid="'risk-calculator-results'"
        >
            <template slot="title">
              {{ $t(`${$options.slug}.your-result`) }}
            </template>
            <template slot="content">
              <div class="risk-results__chart">
                <stacked-bar-chart
                  :ranges="chart.ranges"
                  :labels="chart.labels"
                  :colors="chart.colors"
                  :position="points"
                />
              </div>

              <p
                 class="plugin-article-text plugin-article-text--center risk-results-result"
                 v-html="resultText"/>

              <p>
                {{ $t(`${$options.slug}.content`) }}
              </p>
            </template>
        </plugin-article>

    </container>
  </base-main>
</template>

<script>
  import { mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import StackedBarChart from '@/components/charts/stacked-bar-chart/StackedBarChart'
  import Container from '@/components/container/Container'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'

  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.risk-calculator.risk-calculator-results.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      PluginArticle,
      StackedBarChart,
    },

    mixins: [
      unblockPlugin,
    ],

    props: {
      gender: {
        type: String,
        required: true,
      },
      points: {
        type: Number,
        required: true,
      },
    },

    mounted() {
      this.unblockPlugin()
      this.chart.colors = [
        this.colors['color-chart1'],
        this.colors['color-chart2'],
        this.colors['color-chart3'],
        this.colors['color-chart4'],
        this.colors['color-chart5'],
      ]
    },

    data() {
      return {
        chart: {
          ranges: [[0, 3], [4, 8], [9, 12], [13, 20], [21, 23]],
          labels: ['Very low', 'Low', 'Moderate', 'High', 'Very high'],
          colors: [],
        },
      }
    },

    computed: {
      ...mapGetters('colors', [
        'colors',
      ]),
      result() {
        const isMale = this.gender === 'Male'

        if (this.points <= 3) {
          return isMale ? 0.3 : 0.1
        } else if (this.points >= 4 && this.points <= 8) {
          return isMale ? 0.8 : 0.4
        } else if (this.points >= 9 && this.points <= 12) {
          return isMale ? 2.6 : 2.2
        } else if (this.points >= 13 && this.points <= 20) {
          return isMale ? 23.1 : 14.1
        } else if (this.points >= 20) {
          return 50
        }

        return 0
      },
      resultText() {
        return this.$t(`${this.$options.slug}.risk-result`, {
          points: this.points,
          result: this.result,
        })
      },
    },
    beforeRouteEnter(to, from, next) {
      to.params.gender && to.params.points !== undefined
        ? next()
        : next({ name: 'RiskCalculatorForm', replace: true })
    },

    slug: 'views.plugins.risk-calculator.risk-calculator-results',
  }
</script>

<style lang="scss">
  .risk-results {
    &-result {
      font-size: 1.7rem;
    }
    &-number {
      color: color(dark-primary);
      font-weight: 600;
    }
    &__chart {
      padding: 2rem;
    }
    .plugin {
      &-title {
        padding: 2rem 0 1rem 0;
      }
      &-content {
        overflow-x: hidden;
      }
    }
  }
</style>
