<template>
  <base-main
    class="initial-assessment"
    data-testid="initial-assessment"
  >
    <container
      first
      narrow
    >
      <stepper-custom-form
        ref="form"
        data-testid="initial-assessment-stepper-form"
        :eventCallbacks="{ onNext }"
        :isLoading="isSaving"
        @cancel="redirectToTools"
        @last-step-accessed="unblockPlugin"
        @submit="redirectToTools"
      >
        <stepper-custom-step
          data-testid="initial-assessment-intro-step"
          :title="'Initial Assessment questionnaire'"
          :useTitleNumeration="false"
        >
          <div class="initial-assessment__intro">
            <img
              alt=""
              :src="requireImage('initial-assessment/intro.png')"
            >
            <p>{{ i18n('intro') }}</p>
          </div>
        </stepper-custom-step>

        <stepper-custom-step
          data-testid="initial-assessment-weight-step"
          :title="$t('Record your weight')"
          :useTitleNumeration="false"
        >
          <div class="initial-assessment__weight">
            <input-group
              class="initial-assessment__weight-input"
              :label="$t('Weight')"
              :min="inputMinMax.min"
              :max="inputMinMax.max"
              :name="'weight_value'"
              :step="0.01"
              :type="'number'"
              :validation="weightValueValidation"
              v-model.number="form.weight_value"
            />

            <span
              class="initial-assessment__unit"
              data-testid="initial-assessment-weight-step-unit"
            >
              {{ getCurrentUserWeightUnit.abbr }}
            </span>
          </div>

          <ul
            class="initial-assessment__info bullet"
            data-testid="initial-assessment-weight-step-info"
          >
            <li
              data-testid="initial-assessment-weight-step-read"
            >
              {{ i18n('read') }}
              <weekly-review-dialog-link
                data-testid="initial-assessment-weight-step-link"
                v-bind="{ dialogLink }"
              />
            </li>
            <li
              data-testid="initial-assessment-weight-step-covid"
            >
              {{ i18n('covid') }}
            </li>
          </ul>

          <h2
            data-testid="initial-assessment-weight-step-image-heading"
          >
            {{ i18n('photo-header') }}
          </h2>

          <p
            id="ia-photo-desc"
            class="initial-assessment__photo-description"
            data-testid="initial-assessment-weight-step-image-description"
          >
            {{ i18n('photo-description') }}
          </p>
          <p
            class="initial-assessment__photo-description-covid"
            data-testid="initial-assessment-weight-step-image-covid"
          >
            {{ i18n('photo-description-covid') }}
          </p>

          <image-group
            :describedBy="'ia-photo-desc'"
            :label="i18n('photo-label')"
            :name="'weight_image'"
            :validation="$options.validation.weight_image"
            v-model="form.weight_image"
          />
        </stepper-custom-step>

        <stepper-custom-step
          v-for="(question, i) in questions"
          :key="question.name"
          :data-testid="createStepTestid(question.name)"
          :forceStepIndex="i + 2"
          :title="question.title"
          :useTitleNumeration="false"
        >
          <initial-assessment-generic-step
            :data="question"
            v-bind="{ form }"
          />
        </stepper-custom-step>

        <stepper-custom-step
          data-testid="initial-assessment-thank-you-step"
          :title="'views.plugins.initial-assessment.thank-you-title'"
          :useTitleNumeration="false"
        >
          <p>{{ i18n('thank-you') }}</p>
        </stepper-custom-step>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { kebabCase } from 'lodash'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import { InitialAssessmentGenericStep } from '@/components/plugins/initial-assessment'
  import {
    StepperCustomForm,
    StepperCustomStep,
  } from '@/components/stepper-custom'
  import WeeklyReviewDialogLink from '@/components/weekly-review/WeeklyReviewDialogLink'

  import { SPECIAL_WEIGHT_VALUE } from '@/constants'

  import questions from '@/data/initial-assessment'
  import { links } from '@/data/weekly-review/weeklyReviewDialogs'

  import deleteProperty from '@/helpers/deleteProperty'
  import { lbsToKg } from '@/helpers/weightConverter'

  import i18n from '@/mixins/i18n'
  import requireImage from '@/mixins/requireImage'
  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.initial-assessment.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      InputGroup,
      InitialAssessmentGenericStep,
      StepperCustomForm,
      StepperCustomStep,
      WeeklyReviewDialogLink,
    },

    mixins: [
      i18n,
      requireImage,
      unblockPlugin,
    ],

    data() {
      return {
        form: {
          address: '',
          consent_to_share: '',
          disability_status_first: '',
          disability_status_second_fixer: [],
          disability_status_other_input: '',
          employment_status: '',
          employment_status_input: '',
          ethnicity: '',
          ethnicity_input: '',
          gender_identity: '',
          gp_name: '',
          gp_practice_address: '',
          gp_practice_name: '',
          housing_tenure: '',
          housing_tenure_input: '',
          language_preferred: '',
          language_speak_and_spoken: '',
          language_written: '',
          marital_status: '',
          religious_beliefs: '',
          religious_beliefs_input: '',
          weight_value: '',
          sex: '',
          sexual_orientation: '',
        },
        dialogLink: links.weight,
      }
    },

    methods: {
      ...mapActions('initialAssessment', [
        'saveAnswers',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      convertWeightValueIfNecessary() {
        if (!this.isMetric) {
          this.form.weight_value = lbsToKg(this.form.weight_value)
        }
      },
      onNext(next, step, maxStep) {
        const nextMiddleware = step === maxStep - 1
          ? this.saveAnswers(this.createFormData())
          : Promise.resolve()

        nextMiddleware
          .then(next)
          .catch(() => {
            this.addSnackbar({ message: 'Something went wrong, please try again.' })
          })
      },
      redirectToTools() {
        this.$router.push({ name: 'Tools' })
      },
      createForm() {
        const suffixInput = '_input'
        const suffixFixer = '_fixer'

        const inputReduce = Object.keys(this.form)
          .filter(key => key.includes(suffixInput))
          .reduce((acc, curr) => {
            if (acc[curr]) {
              const baseKey = curr.replace(suffixInput, '')

              Array.isArray(acc[baseKey])
                ? acc[baseKey].push(acc[curr])
                : acc[baseKey] = acc[curr]
            }

            return deleteProperty(curr, acc)
          }, { ...this.form })

        const fixedReduce = Object.keys(this.form)
          .filter(key => key.includes(suffixFixer))
          .reduce((acc, curr) => {
            if (acc[curr]) {
              if (Array.isArray(acc[curr])) {
                this.$static.disability_status_second_keys.forEach(item => {
                  acc[item] = acc[curr].includes(item)
                })
              } else {
                const baseKey = curr.replace(suffixFixer, '')
                acc[baseKey] = acc[curr]
              }
            }

            return deleteProperty(curr, acc)
          }, { ...inputReduce })

        return fixedReduce
      },
      createFormData() {
        const form = this.createForm()
        const formData = new FormData()

        Object.entries(form)
          .forEach(([key, value]) => {
            Array.isArray(value)
              ? value.forEach(item => formData.append(`${key}[]`, item))
              : formData.append(key, value)
          })

        return formData
      },
      createStepTestid(name) {
        return `${kebabCase(name)}-step`
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('user', [
        'getCurrentUserWeightUnit',
        'isMetric',
      ]),
      inputMinMax() {
        return this.isMetric
          ? { min: 20, max: SPECIAL_WEIGHT_VALUE }
          : { min: 40, max: SPECIAL_WEIGHT_VALUE }
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'initialAssessment/saveAnswers',
        ])
      },
      maxStep() {
        return this.questions.length + 1
      },
      weightValueValidation() {
        return {
          required: true,
          decimal: 2,
          min_value: this.inputMinMax.min,
          max_value: this.inputMinMax.max,
        }
      },
      questions() {
        return this.form.disability_status_first == 'Disabled'
                ? questions
                : questions.filter(({ name }) => name !== 'InitialAssessmentDisabilityCategories')
      },
    },

    slug: 'views.plugins.initial-assessment',

    validation: {
      weight_image: {
        required: true,
      },
    },

    static() {
      return {
        disability_status_second_keys: [
          'disability_status_behaviour_emotional',
          'disability_status_hearing',
          'disability_status_manual_dexterity',
          'disability_status_memory',
          'disability_status_mobility',
          'disability_status_perception',
          'disability_status_personal',
          'disability_status_progressive_conditions',
          'disability_status_sight',
          'disability_status_speech',
        ],
      }
    },
  }
</script>

<style lang="scss">
  .initial-assessment {
    &__intro {
      display: grid;
      grid-template-columns: 23.2rem 1fr;
      grid-gap: 3rem;
      @include max-sm {
        grid-template-columns: repeat(auto-fit, minmax(23.2rem, 1fr));
      }
    }
    &__weight {
      display: flex;
      align-items: flex-end;
      position: relative;
    }
    &__weight-input {
      width: 100%;
      margin: 0 2.1rem 0 0;
    }
    &__unit {
      position: absolute;
      top: 6rem;
      right: 0;
    }
    &__accurate {
      margin: 1rem 0 5rem;
    }
    .image-group {
      margin: 1.5rem 0 0;
    }
    &__info {
      margin: 1.8rem 0 0;
    }
    &__photo-description-covid {
      margin: 1.8rem 0 0;
    }
  }
</style>
